import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
// import { useNavigate  } from 'react-router-dom'; // Cambiar a 'react-router-dom'
import { Link, useNavigate, useParams } from "react-router-dom";
import medicGeekApi from "../../config/axiosConfig";
import { API_BASE_URL } from "../../config/constants";
import {
  showConfirmationAlert,
  showErrorAlert,
  showSuccessAlert,
} from "./../../components/alerts";

const initialFormData = {
  // pcteId: '',
  pcteFechaReg: "",
  pcteNom: "",
  pcteNom2: "",
  pcteApe1: "",
  pcteApe2: "",
  pcteNumDoc: "",
  pcteEd: "",
  pcteSx: "",
  pcteCel: "",
  pcteTel: "",
  pcteDir: "",
  pcteProv: "",
  pcteCiud: "",
  pcteNac: "REPUBLICA DOMINICANA",
  pcteEc: "",
  pcteEmail: "",
  pcteOcup: "",
  seguroId: "",
  seguroNm: "",
  seguroNoAfiliado: "",
  seguroNss: "",
  // isEditing: false, // Agregamos la bandera para controlar si estás editando
};

const PacienteForm = ({ onCloseForm, updatePacientesList, pacienteToEdit }) => {
  const [isEditing, setIsEditing] = useState(false); // Establecer isEditing en true si hay un pacienteToEdit
  const [formData, setFormData] = useState(initialFormData);
  const [seguros, setSeguros] = useState([]);
  const { id } = useParams();
  const [pacienteData, setPacienteData] = useState(null); // Agregar la declaración de pacienteData
  const history = useNavigate();
  const { newPcteId } = useParams();
  const [paises, setPaises] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [paisesResponse, patientResponse] = await Promise.all([
          fetch("https://restcountries.com/v3.1/all").then((response) =>
            response.json()
          ),
          id ? medicGeekApi.get(`/patients/${id}`) : null,
        ]);

        const paisesData = paisesResponse;
        const patientData = patientResponse ? patientResponse.data : null;

        console.log(paisesData);

        setPaises(paisesData);

        if (patientData) {
          setPacienteData(patientData);
          setFormData((prevData) => ({
            ...prevData,
            ...patientData,
          }));
          setIsEditing(true);
        }
      } catch (error) {
        console.error(
          "Error al obtener los datos del paciente o de los países",
          error
        );
      }
    };

    const loadSeguros = async () => {
      try {
        const segurosResponse = await medicGeekApi.get("/seguros");
        const segurosData = segurosResponse.data.filter(
          (seguro) => seguro.estatus === "Activo"
        );
        setSeguros(segurosData);
      } catch (error) {
        console.error("Error al obtener los datos del seguro", error);
      }
    };

    const loadMunicipios = async () => {
      try {
        const municipiosResponse = await medicGeekApi.get("/municipios");
        const municipiosData = municipiosResponse.data;
        setMunicipios(municipiosData);
      } catch (error) {
        console.error("Error al obtener los datos de municipios", error);
      }
    };

    const loadCountries = async () => {
      try {
        const countriesResponse = await medicGeekApi.get("/countries");
        const countriesData = countriesResponse.data;
        setCountries(countriesData);
      } catch (error) {
        console.error("Error al obtener los datos de countries", error);
      }
    };

    const loadData = async () => {
      await fetchData();
      loadSeguros();
      loadMunicipios();
      loadCountries();
    };

    loadData();
  }, [id]);

  const formatBirthDate = (dateStr) => {
    // Formatear la fecha aquí según tu necesidad
    return dateStr;
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const guardarPaciente = async () => {
    const fechaActual = new Date();
    const dia = fechaActual.getDate();
    const mes = fechaActual.getMonth() + 1; // Los meses comienzan en 0, así que sumamos 1
    const año = fechaActual.getFullYear();
    const ampm = fechaActual.getHours() >= 12 ? "PM" : "AM";
    let horas = fechaActual.getHours() % 12;
    horas = horas === 0 ? 12 : horas; // Si es 0, establece 12 en lugar de 0
    const minutos = fechaActual.getMinutes();
    const fechaHoraFormateada = `${mes}/${dia}/${año} ${horas}:${minutos} ${ampm}`;

    try {
      // Validación de fecha de nacimiento
      const birthDate = new Date(formData.pcteEd);

      if (isNaN(birthDate.getTime())) {
        console.error("Fecha de nacimiento inválida:", formData.pcteEd);
        showErrorAlert(
          "La fecha de nacimiento es inválida. Por favor, verifique el formato."
        );
        return;
      }

      const formattedBirthDate = formatBirthDate(formData.pcteEd);

      const createData = {
        ...formData,
        pcteFechaReg: fechaHoraFormateada,
        pcteEd: formattedBirthDate,
      };

      const response = await fetch(`${API_BASE_URL}/patients`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(createData),
      });

      if (response.ok) {
        showSuccessAlert("El Paciente fue Guardado Correctamente");
        history("/patients");
        // onCloseForm();
        resetForm();
      } else {
        console.error("Error al guardar el paciente:", response.status);
        showErrorAlert("Hubo un error al guardar el paciente");
      }
    } catch (error) {
      console.error("Error al guardar el paciente:", error);
      showErrorAlert("Hubo un error al guardar el paciente");
    }
  };

  const editarPaciente = async () => {
    try {
      const originalData = pacienteData;
      const updateData = {};

      const excludedProperties = ["showErrorAlert", "otraPropiedad"]; // Agrega aquí cualquier propiedad que deba excluirse

      for (const field in formData) {
        if (
          !excludedProperties.includes(field) &&
          formData[field] !== originalData[field]
        ) {
          updateData[field] = formData[field];
        }
      }
      // console.log('Datos a actualizar:', updateData);

      if (Object.keys(updateData).length === 0) {
        onCloseForm();
        return;
      }

      const confirmResult = await showConfirmationAlert(
        "Confirmación",
        "¿Está seguro que desea actualizar el Paciente?"
      );

      if (confirmResult.isConfirmed) {
        // Filtrar las propiedades no deseadas en updateData
        const filteredUpdateData = Object.keys(updateData).reduce(
          (acc, key) => {
            if (!excludedProperties.includes(key)) {
              acc[key] = updateData[key];
            }
            return acc;
          },
          {}
        );

        await medicGeekApi.patch(
          `/patients/${originalData.pcteId}`,
          filteredUpdateData
        );
        showSuccessAlert("El registro fue Actualizado Correctamente");
        history("/patients");
        onCloseForm();
      }
    } catch (error) {
      console.error("Error al editar el paciente:", error);
      console.error("Detalles del error:", error.response); // Agrega esta línea para obtener detalles adicionales
      showErrorAlert("Hubo un error al editar el paciente");
    }
  };

  const handleSubmit = async () => {
    if (isEditing) {
      await editarPaciente();
    } else {
      await guardarPaciente();
    }
  };

  return (
    <div className="" style={{ width: "99%" }}>
      <div className="bg-secundary d-flex justify-content-between mt-4 mx-2">
        <p className="fs-5 fw-bold">
          {isEditing ? "Mantenimiento de Paciente" : "Crear Paciente"}
        </p>
        <Row>
          <Form d-flex justify-content-end>
            <Col md={6}>
              <FormGroup>
                <div className="input-group input-group-sm mb-3">
                  <span class="input-group-text" id="inputGroup-sizing-sm">
                    Exp-
                  </span>
                  <Input
                    d-flex
                    justify-content-end
                    type="text"
                    className="form-control"
                    id="pcteId"
                    value={formData.pcteId}
                    onChange={(e) =>
                      handleInputChange("pcteId", e.target.value)
                    }
                    placeholder="id"
                    disabled
                  />
                </div>
              </FormGroup>
            </Col>
          </Form>
        </Row>
      </div>
      <div className="container mt-2">
        <Row>
          <Form onSubmit={handleSubmit} className="row ">
            <div>
              <p className="fs-5 fw-bold d-flex justify-content-firt">
                Información de Personal
              </p>
            </div>
            <div>
              <div className="row ">
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteNom"
                      value={formData.pcteNom}
                      onChange={(e) =>
                        handleInputChange("pcteNom", e.target.value)
                      }
                      placeholder="Primer Nombre"
                    />
                    <Label htmlFor="pcteNom">Primer Nombre</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteNom2"
                      value={formData.pcteNom2}
                      onChange={(e) =>
                        handleInputChange("pcteNom2", e.target.value)
                      }
                      placeholder="Segundo Nombre"
                    />
                    <Label For="pcteNom2">Segundo Nombre (Opcional)</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteApe1"
                      value={formData.pcteApe1}
                      onChange={(e) =>
                        handleInputChange("pcteApe1", e.target.value)
                      }
                      placeholder="Primer Apellido"
                    />
                    <Label For="pcteApe1">Primer Apellido (Opcional)</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteApe2"
                      value={formData.pcteApe2}
                      onChange={(e) =>
                        handleInputChange("pcteApe2", e.target.value)
                      }
                      placeholder="Segundo Apellido"
                    />
                    <Label For="pcteApe2">Segundo Apellido (Opcional)</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteNumDoc"
                      value={formData.pcteNumDoc}
                      onChange={(e) =>
                        handleInputChange("pcteNumDoc", e.target.value)
                      }
                      placeholder="Numero de Documento"
                    />
                    <Label For="pcteNumDoc">Número de Documento</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="date"
                      className="form-control"
                      id="pcteEd"
                      value={formData.pcteEd}
                      onChange={(e) =>
                        handleInputChange("pcteEd", e.target.value)
                      }
                      placeholder="Edad"
                    />
                    <Label For="pcteEd">Edad</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      className="form-control"
                      id="pcteSx"
                      value={formData.pcteSx}
                      onChange={(e) =>
                        handleInputChange("pcteSx", e.target.value)
                      }
                      name="select"
                      type="select"
                    >
                      <option value="" disabled>
                        Seleccione el Sexo
                      </option>
                      <option value="Masculino">Masculino</option>
                      <option value="Femenino">Femenino</option>
                      <option value="Otro">Indefinido</option>
                    </Input>
                    <Label For="pcteEd">Sexo</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="select"
                      className="form-control"
                      id="seguroNm"
                      value={formData.seguroNm}
                      onChange={(e) =>
                        handleInputChange("seguroNm", e.target.value)
                      }
                    >
                      <option value="1" disabled>
                        Selecciona un seguro
                      </option>
                      {seguros.map((seguro) => (
                        <option key={seguro.id} value={seguro.seguroNm}>
                          {seguro.seguroNm}
                        </option>
                      ))}
                    </Input>
                    <Label For="seguroNm">ARS (Opcional)</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="seguroNoAfiliado"
                      value={formData.seguroNoAfiliado}
                      onChange={(e) =>
                        handleInputChange("seguroNoAfiliado", e.target.value)
                      }
                      placeholder="No Afiliado"
                    />
                    <Label For="seguroNoAfiliado">No. Afiliado</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="seguroNss"
                      value={formData.seguroNss}
                      onChange={(e) =>
                        handleInputChange("seguroNss", e.target.value)
                      }
                      placeholder="NSS"
                    />
                    <Label For="seguroNss">NSS</Label>
                  </FormGroup>
                </Col>
              </div>

              <div>
                <p className="fs-5 fw-bold pt-4 d-flex justify-content-firt">
                  Información de Contacto
                </p>
              </div>
              <div className="row">
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteCel"
                      value={formData.pcteCel}
                      onChange={(e) =>
                        handleInputChange("pcteCel", e.target.value)
                      }
                      placeholder="Número de Celular"
                    />
                    <Label For="pcteCel">Número de Celular (Optional)</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteTel"
                      value={formData.pcteTel}
                      onChange={(e) =>
                        handleInputChange("pcteTel", e.target.value)
                      }
                      placeholder="Número de Teléfono"
                    />
                    <Label For="pcteTel">
                      Número de Teléfono fijo (Optional)
                    </Label>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      className="form-control"
                      id="pcteNac"
                      value={formData.pcteNac}
                      onChange={(e) =>
                        handleInputChange("pcteNac", e.target.value)
                      }
                      name="select"
                      type="select"
                    >
                      {countries.map((countries) => (
                        <option key={countries.code} value={countries.pais}>
                          {countries.pais}
                        </option>
                      ))}
                    </Input>
                    <Label For="pcteNac">País de Residencia</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteDir"
                      value={formData.pcteDir}
                      onChange={(e) =>
                        handleInputChange("pcteDir", e.target.value)
                      }
                      placeholder="Dirección"
                    />
                    <Label For="pcteDir">Dirección (Opcional)</Label>
                  </FormGroup>
                </Col>
                {/* <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteProv"
                      value={formData.pcteProv}
                      onChange={(e) => handleInputChange('pcteProv', e.target.value)}
                      placeholder="Provincia"
                      disabled/>
                    <Label For="pcteProv">
                      Provincia
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="select"
                      className="form-control"
                      id="pcteCiud"
                      value={formData.pcteCiud}
                      onChange={(e) => handleInputChange('pcteCiud', e.target.value)}
                    >
                      <option value="" disabled>Selecciona un Municipio</option>
                      {municipios.map((municipio) => (
                        <option key={municipio.idMunicipio} value={municipio.Description}>
                          {municipio.Description}
                        </option>
                      ))}
                    </Input>
                    <Label For="pcteCiud">
                      Municipio
                    </Label>
                  </FormGroup>
                </Col> */}

                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      className="form-control"
                      id="pcteEc"
                      value={formData.pcteEc}
                      onChange={(e) =>
                        handleInputChange("pcteEc", e.target.value)
                      }
                      name="select"
                      type="select"
                    >
                      <option value="" disabled>
                        Seleccione el Sexo
                      </option>
                      <option value="Soltero/a">Soltero/a</option>
                      <option value="Casado/a">Casado/a</option>
                      <option value="Divorciado/a">Divorciado/a</option>
                      <option value="Union Libre">Unión Libre</option>
                      <option value="Viudo/a">Viudo/a</option>
                    </Input>
                    <Label For="pcteEc">Estado Civil</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteOcup"
                      value={formData.pcteOcup}
                      onChange={(e) =>
                        handleInputChange("pcteOcup", e.target.value)
                      }
                      placeholder="Ocupación"
                    />
                    <Label For="pcteOcup">Ocupación (Opcional)</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      className="form-control"
                      id="pcteEmail"
                      value={formData.pcteEmail}
                      onChange={(e) =>
                        handleInputChange("pcteEmail", e.target.value)
                      }
                      placeholder="Correo Electrónico"
                    />
                    <Label For="pcteEmail">Correo Electrónico (Opcional)</Label>
                  </FormGroup>
                </Col>
              </div>
            </div>
            <div className="gap-2 d-flex justify-content-end">
              <Link to="/patients">
                <Button
                  className="button-cancel text-secondary"
                  style={{
                    height: "3rem",
                  }}
                >
                  Cancelar
                </Button>
              </Link>
              <Button
                className="button-save text-primary"
                style={{
                  height: "3rem",
                }}
                onClick={isEditing ? editarPaciente : guardarPaciente}
              >
                {isEditing ? "Guardar Edición" : "Guardar"}
              </Button>
            </div>
          </Form>
        </Row>
      </div>
    </div>
  );
};

export default PacienteForm;
