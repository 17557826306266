import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  showErrorAlert,
  showSuccessAlert,
  showSuccessEditAlert,
} from "../../../components/alerts";
import medicGeekApi from "../../../config/axiosConfig";

const MedicamentosList = ({
  setCurrentForm,
  currentForm,
  setSelectedMedicamentos,
}) => {
  const [medicamentos, setMedicamentos] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const medicamentosPerPage = 10;
  const [selectedMedicamentoLocal, setSelectedMedicamentosLocal] =
    useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [formulario, setFormulario] = useState({
    nombreMedicamento: "",
    nombreComercial: "",
    dosisMedicamento: "",
    estatusMedicamento: "activo",
  });

  const openModal = (medicamento) => {
    setSelectedMedicamentosLocal(medicamento); // Configura el medicamento seleccionado para la edición

    if (medicamento) {
      setIsEditing(true); // Establecer como modo edición cuando se abre el modal
      setFormulario({
        nombreMedicamento: medicamento.producNm,
        nombreComercial: medicamento.producNc,
        dosisMedicamento: medicamento.producDs,
        estatusMedicamento: medicamento.estatus ? "activo" : "inactivo",
      });
    } else {
      setIsEditing(false); // Establecer como modo creación cuando se abre el modal para crear uno nuevo
      // Limpia los campos si es necesario
      setFormulario({
        nombreMedicamento: "",
        nombreComercial: "",
        dosisMedicamento: "",
        estatusMedicamento: "activo",
      });
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedMedicamentosLocal(null); // Limpia el Medicamento seleccionado
    setIsEditing(false); // Resetear el modo edición cuando se cierra el modal
    setModalOpen(false);
  };

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await medicGeekApi.get(
          `/medicamentos?search=${searchTerm}`,
          {
            params: {
              $or: [
                { producNm: { $regex: searchTerm, $options: "i" } },
                { producNc: { $regex: searchTerm, $options: "i" } },
              ],
            },
          }
        );
        const sortedData = response.data.sort(
          (a, b) => a.producId - b.producId
        );
        setMedicamentos(sortedData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [searchTerm]);

  const pageCount = Math.ceil(medicamentos.length / medicamentosPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedmedicamentos = medicamentos
    .filter((medicamento) => {
      // Filtrar seguros basados en el término de búsqueda
      return (
        medicamento.producNm.toLowerCase().includes(searchTerm.toLowerCase()) ||
        medicamento.producNc.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .slice(
      pageNumber * medicamentosPerPage,
      (pageNumber + 1) * medicamentosPerPage
    );

  const updateMedicamentosList = () => {
    medicGeekApi
      .get("/medicamentos")
      .then((response) => {
        setMedicamentos(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const guardarMedicamento = () => {
    const nuevoMedicamento = {
      producNm: document.getElementById("nombreMedicamento").value,
      producNc: document.getElementById("nombreComercial").value,
      producDs: document.getElementById("dosisMedicamento").value,
      estatus:
        document.getElementById("estatusMedicamento").value === "activo"
          ? "Activo"
          : "Inactivo",
    };
    medicGeekApi
      .post("/medicamentos", nuevoMedicamento)
      .then((response) => {
        showSuccessAlert();
        closeModal();
        updateMedicamentosList();
      })
      .catch((error) => {
        console.error("Error al crear el Medicamento", error);
      });
  };

  const editarMedicamento = () => {
    if (selectedMedicamentoLocal && selectedMedicamentoLocal.producId) {
      const { producId } = selectedMedicamentoLocal;
      const nombreMedicamento =
        document.getElementById("nombreMedicamento").value;
      const nombreComercial = document.getElementById("nombreComercial").value;
      const dosisMedicamento =
        document.getElementById("dosisMedicamento").value;
      const estatusMedicamento =
        document.getElementById("estatusMedicamento").value === "activo"
          ? "Activo"
          : "Inactivo";

      const updatedMedicamento = {
        producId, // Asegurarse de enviar el ID
        producNm: nombreMedicamento,
        producNc: nombreComercial,
        producDs: dosisMedicamento,
        estatus: estatusMedicamento,
      };

      medicGeekApi
        .patch(`/medicamentos/${producId}`, updatedMedicamento)
        .then((response) => {
          showSuccessEditAlert();
          closeModal();
          updateMedicamentosList();
        })
        .catch((error) => {
          console.error("Error al actualizar el seguro", error);
        });
    } else {
      // Manejo de error o mensaje de que no se puede actualizar
      showErrorAlert();
      console.error("No se puede actualizar: seguro no válido.");
    }
  };

  return (
    <div>
     
      <div
        className="d-flex flex-column flex-md-row justify-content-between miDivConGradiente px-3 rounded-bottom height-responsive"
      >
        <div className="d-flex align-items-center text-white mb-2 mb-md-0">
          <h2>Vademecum</h2>
        </div>

        <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0">
          <form className="d-flex" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Buscar"
              aria-label="Buscar"
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </form>
          <button
            type="button"
            className="btn btn-danger"
            title="Crear Nuevo Medicamento"
            onClick={() => openModal(null)}
          >
            <i className="bi bi-file-plus"></i> Nuevo Medicamento
          </button>
        </div>
      </div>
  
      {/* Mostrar tabla en pantallas grandes */}
      <div className="d-none d-md-block mb-2" style={{ height: "45vh", padding: "0rem 1rem" }}>
        <table className="table table-striped table-hover mt-5">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>ID</th>
              <th style={{ width: "25%" }}>Nombre Generico</th>
              <th style={{ width: "25%" }}>Nombre Comercial</th>
              <th style={{ width: "30%" }}>Dosis</th>
              <th style={{ width: "10%" }}>Estatus</th>
              <th style={{ width: "5%" }}>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {displayedmedicamentos.map((medicamento) => (
              <tr key={medicamento.producId}>
                <td>{medicamento.producId}</td>
                <td>{medicamento.producNm}</td>
                <td
                  style={{ width: "30%"}}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={medicamento.producNc}
                >
                  {medicamento.producNc?.length > 20
                    ? `${medicamento.producNc.slice(0, 30)}...`
                    : medicamento.producNc}
                </td>
                <td
                  style={{ width: "30%"}}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={medicamento.producDs}
                >
                  {medicamento.producDs?.length > 20
                    ? `${medicamento.producDs.slice(0, 30)}...`
                    : medicamento.producDs}
                </td>
                <td>{medicamento.estatus}</td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="p-1 border-0"
                      title="Editar"
                      id="hoverIcon"
                      onClick={() => openModal(medicamento)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                    <button
                      type="button"
                      className="p-1 border-0"
                      title="Eliminar"
                      id="hoverIcon"
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
      {/* Mostrar tarjetas en vista móvil */}
      <div className="d-md-none mb-2" style={{ padding: "0rem 1rem" }}>
        <div className="row">
          {displayedmedicamentos.map((medicamento) => (
            <div className="col-12 mb-3" key={medicamento.producId}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">ID-{medicamento.producId}</h5>
                  <p className="card-text">
                    <strong>Nombre Generico:</strong> {medicamento.producNm}
                  </p>
                  <p className="card-text">
                    <strong>Nombre Comercial:</strong> {medicamento.producNc}
                  </p>
                  <p className="card-text">
                    <strong>Dosis:</strong> {medicamento.producDs}
                  </p>
                  <p className="card-text">
                    <strong>Estatus:</strong> {medicamento.estatus}
                  </p>
                  <div className="d-flex justify-content-around">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      title="Editar"
                      onClick={() => openModal(medicamento)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      title="Eliminar"
                      // onClick={() => handleDelete(medicamento.producId)}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
  
     {/* Paginación */}
      {!showForm && (
        <div className="d-flex justify-content-end" style={{ padding: "0rem 1rem" }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              
              {/* Botón de página anterior */}
              <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                <span
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange({ selected: pageNumber - 1 })}
                  style={{ cursor: pageNumber === 0 ? "not-allowed" : "pointer" }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </span>
              </li>

              {Array.from(
                { length: Math.min(pageCount, 10) },
                (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      pageNumber === index ? " active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="page-link"
                      onClick={() => handlePageChange({ selected: index })}
                    >
                      {index + 1}
                    </span>
                  </li>
                )
              )}

              {/* Botón de siguiente página */}
              <li className={`page-item ${pageNumber === pageCount - 1 ? "disabled" : ""}`}>
                <span
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange({ selected: pageNumber + 1 })}
                  style={{ cursor: pageNumber === pageCount - 1 ? "not-allowed" : "pointer" }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </span>
              </li>
              
            </ul>
          </nav>
        </div>
      )}

  
      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          {isEditing ? "Editar Medicamento" : "Crear Nuevo Medicamento"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="nombreMedicamento">Nombre Generico</Label>
              <Input
                type="text"
                id="nombreMedicamento"
                value={formulario.nombreMedicamento}
                onChange={(e) => setFormulario({ ...formulario, nombreMedicamento: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="nombreComercial">Nombre Comercial</Label>
              <Input
                type="text"
                id="nombreComercial"
                value={formulario.nombreComercial}
                onChange={(e) => setFormulario({ ...formulario, nombreComercial: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="dosisMedicamento">Dosis</Label>
              <Input
                type="text"
                id="dosisMedicamento"
                value={formulario.dosisMedicamento}
                onChange={(e) => setFormulario({ ...formulario, dosisMedicamento: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="estatusMedicamento">Estado</Label>
              <Input
                type="select"
                id="estatusMedicamento"
                value={formulario.estatusMedicamento}
                onChange={(e) => setFormulario({ ...formulario, estatusMedicamento: e.target.value })}
              >
                <option value="activo">Activo</option>
                <option value="inactivo">Inactivo</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="button-cancel text-secondary" onClick={closeModal} style={{ height: "3rem" }}>
            Cancelar
          </Button>
          <Button className="button-save text-primary" onClick={isEditing ? editarMedicamento : guardarMedicamento} style={{ height: "3rem" }}>
            {isEditing ? "Guardar Edición" : "Guardar"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
  

};
export default MedicamentosList;
