import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  showConfirmationAlert,
  showDeleteAlert,
  showErrorAlert,
  showSuccessAlert,
  showSuccessEditAlert,
} from "../../../../components/alerts";
import medicGeekApi from "./../../../../config/axiosConfig";

const UsersForm = ({ currentForm }) => {
  const [users, setUsers] = useState([]);
  const [showForm] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const [selectedUserLocal, setSelectedUserLocal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [formulario, setFormulario] = useState({
    nombreUsers: "",
    passwordUsers: "",
    rolUsers: "",
    estatusUsers: true,
  });
  const [activeTabDetal, setActiveTabDetal] = useState("1");

  // Informacion del Usuario
  const { profileId } = useParams();
  const [formData, setFormData] = useState({
    user: "",
    docNom: "",
    docCed: "",
    nomCentro: "",
    docEsp: "",
    docNac: "REPUBLICA DOMINICANA",
    docProv: "",
    docDir: "",
    docExe: "",
    docEmail: "",
    docTel: "",
    docExt: "",
    docCel: "",
    docDirCert: "",
  });
  const [profileData, setProfileData] = useState(null); // Inicializa como null
  const [profiles, setProfiles] = useState([]);

  // Pais y regiones
  const [provincias, setProvincias] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [countries, setCountries] = useState([]);

  const openModal = async (user) => {
    setSelectedUserLocal(user);

    if (user) {
      setIsEditing(true);
      setFormulario({
        nombreUsers: user.username,
        passwordUsers: user.password,
        rolUsers: user.rol,
        estatusUsers: user.isActive ? true : false,
      });
      await loadProfileData(user.id);
    } else {
      setIsEditing(false);
      setFormulario({
        nombreUsers: "",
        passwordUsers: "",
        rolUsers: "",
        estatusUsers: true,
      });
      // setFormData(getDefaultFormData());
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUserLocal(null);
    setIsEditing(false);
    setModalOpen(false);
  };

  useEffect(() => {
    loadProvincias();
    loadMunicipios();
    loadCountries();
    fetchData();

    // Cargar el perfil cuando se selecciona un usuario
    if (selectedUserLocal && selectedUserLocal.id) {
      fetchProfileData(selectedUserLocal.id);
    }
  }, [searchTerm, selectedUserLocal]);

  useEffect(() => {
    // console.log('selectedUserLocal changed:', selectedUserLocal);
    if (selectedUserLocal && selectedUserLocal.profileId) {
      fetchProfileData(selectedUserLocal.profileId);
    } else {
      setProfileData(null); // Asegurar que profileData se limpie si no hay usuario seleccionado
      // console.warn('selectedUserLocal or profileId is not valid:', selectedUserLocal);
    }
  }, [selectedUserLocal]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await medicGeekApi.get("/users/detail", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedUsers = response.data.sort((a, b) => a.id - b.id);
      
      console.log(sortedUsers)
      
      setUsers(sortedUsers);
      // console.log('Usuarios y perfiles:', sortedUsers);
    } catch (error) {
      if (error.response) {
        // La solicitud fue hecha y el servidor respondió con un código de estado que no está en el rango de 2xx
        console.log("Error de respuesta del servidor:", error.response.data);
        console.log("Código de estado HTTP:", error.response.status);
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió ninguna respuesta
        console.log("No se recibió respuesta del servidor:", error.request);
      } else {
        // Ocurrió un error al configurar la solicitud
        console.log("Error al configurar la solicitud:", error.message);
      }
      console.log("Error al recuperar usuarios y perfiles:", error);
    }
  };

  const fetchProfileData = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await medicGeekApi.get(`/users/profile/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProfileData(response.data);
      // console.log('Perfil cargado:', response.data);
    } catch (error) {
      if (error.response) {
        console.log("Error de respuesta del servidor:", error.response.data);
        console.log("Código de estado HTTP:", error.response.status);
      } else if (error.request) {
        console.log("No se recibió respuesta del servidor:", error.request);
      } else {
        console.log("Error al configurar la solicitud:", error.message);
      }
      console.log("Error al cargar el perfil:", error);
    }
  };

  const handleUserSelection = (user) => {
    setSelectedUserLocal(user);
    console.log("User selected:", user);
    // Cargar el perfil correspondiente al usuario seleccionado
    if (user && user.profileId) {
      fetchProfileData(user.profileId);
    } else {
      setProfileData(null); // Asegurar que profileData se limpie si no hay usuario seleccionado
      console.warn(
        "No se puede cargar el perfil: profileId no válido en el usuario seleccionado."
      );
    }
  };

  const loadProfileData = async (userId) => {
    const selectedUser = users.find((user) => user.id === userId);
    if (selectedUser && selectedUser.profile) {
      // console.log("Perfil del usuario seleccionado:", selectedUser.profile);

      setFormData({
        user: selectedUser.profile.username,
        docNom: selectedUser.profile.docNom,
        docCed: selectedUser.profile.docCed,
        nomCentro: selectedUser.profile.nomCentro,
        docEsp: selectedUser.profile.docEsp,
        docNac: selectedUser.profile.docNac,
        docProv: selectedUser.profile.docProv,
        docDir: selectedUser.profile.docDir,
        docExe: selectedUser.profile.docExe,
        docEmail: selectedUser.profile.docEmail,
        docTel: selectedUser.profile.docTel,
        docExt: selectedUser.profile.docExt,
        docCel: selectedUser.profile.docCel,
        docDirCert: selectedUser.profile.docDirCert,
      });
    }
  };

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    if (searchTerm && typeof searchTerm === "string") {
      setSearchTerm(searchTerm.toLowerCase());
    } else {
      setSearchTerm(""); // Otra lógica de manejo si searchTerm no es una cadena válida
    }
  };

  const updateUsers = async () => {
    try {
      const response = await medicGeekApi.get("/users");
      setUsers(response.data);
    } catch (error) {
      console.log("Error al actualizar usuarios:", error);
    }
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.rol !== "ADMIN" &&
      ((user.username && user.username.toLowerCase().includes(searchTerm)) ||
        (user.rol && user.rol.toLowerCase().includes(searchTerm)) ||
        user.isActive.toString().toLowerCase().includes(searchTerm))
  );

  const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

  const displayedUsers = filteredUsers
    .filter((user) => {
      // Filtrar seguros basados en el término de búsqueda
      return (
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.rol.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.isActive.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage);

  const loadProvincias = async () => {
    try {
      const provinciasResponse = await medicGeekApi.get("/provincias");
      const provinciasData = provinciasResponse.data;
      setProvincias(provinciasData);
    } catch (error) {
      console.error("Error al obtener los datos de la provincias", error);
    }
  };

  const loadMunicipios = async () => {
    try {
      const municipiosResponse = await medicGeekApi.get("/municipios");
      const municipiosData = municipiosResponse.data;
      setMunicipios(municipiosData);
    } catch (error) {
      console.error("Error al obtener los datos de municipios", error);
    }
  };

  const loadCountries = async () => {
    try {
      const countriesResponse = await medicGeekApi.get("/countries");
      const countriesData = countriesResponse.data;
      setCountries(countriesData);
    } catch (error) {
      console.error("Error al obtener los datos de countries", error);
    }
  };

  const guardarNuevoUser = async () => {
    const nuevoUser = {
      username: formulario.nombreUsers,
      password: "MedicGeek123",
      rol: formulario.rolUsers,
      isActive: formulario.estatusUsers,
    };

    try {
      const token = localStorage.getItem("token");

      // Paso 1: Crear el perfil primero
      const profileResponse = await medicGeekApi.post(
        "/profile",
        { username: formulario.nombreUsers, ...formData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const profileId = profileResponse.data.profileId;

      // Paso 2: Crear el usuario con el perfilId obtenido
      const userResponse = await medicGeekApi.post(
        "/users",
        { ...nuevoUser, profileId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Mostrar alerta de éxito y realizar otras operaciones necesarias
      showSuccessAlert("Usuario y perfil creados con éxito");
      closeModal();
      fetchData();
    } catch (error) {
      console.error("Error al crear el usuario o el perfil", error);
    }
  };

  const editarUser = async () => {
    if (
      selectedUserLocal &&
      selectedUserLocal.id &&
      profileData &&
      profileData.profileId
    ) {
      const { id } = selectedUserLocal;
      const { profileId } = profileData; // Asegúrate de usar 'profileId' en lugar de 'idProfile'

      const updatedUser = {
        username: formulario.nombreUsers,
        rol: formulario.rolUsers,
        isActive: formulario.estatusUsers,
      };

      const updatedProfile = {
        ...formData,
        userId: id,
      };

      try {
        const token = localStorage.getItem("token");

        // Actualiza el usuario
        const responseUser = await medicGeekApi.put(
          `/users/${id}`,
          updatedUser,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // console.log('Usuario actualizado:', responseUser.data);

        // Actualiza el perfil
        const responseProfile = await medicGeekApi.patch(
          `/profile/${profileId}`,
          updatedProfile,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // console.log('Perfil actualizado:', responseProfile.data);
        setProfileData(responseProfile.data); // Actualiza profileData con el perfil cargado

        showSuccessEditAlert("Usuario y perfil actualizados con éxito");
        closeModal();
        fetchData();
      } catch (error) {
        console.error("Error al actualizar el usuario o el perfil", error);
      }
    } else {
      console.error("No se puede actualizar: usuario o perfil no válido.");
    }
  };

  const eliminarUser = async (userId) => {
    const confirmResult = await showConfirmationAlert(
      "Confirmación",
      "¿Está seguro de que desea eliminar el Usuario?"
    );

    if (confirmResult.isConfirmed) {
      try {
        const token = localStorage.getItem("token");

        // Paso 1: Obtener detalles del usuario
        const userDetailsResponse = await medicGeekApi.get(
          `/users/detail/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { id, profileId } = userDetailsResponse.data;

        if (!id || !profileId) {
          throw new Error("User ID or Profile ID is undefined");
        }

        // Paso 2: Eliminar el usuario
        const responseUser = await medicGeekApi.delete(`/users/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Usuario Eliminado", responseUser);

        // Paso 3: Eliminar el perfil
        const responseProfile = await medicGeekApi.delete(
          `/profile/${profileId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Perfil Eliminado", responseProfile);

        showDeleteAlert("Usuario y perfil eliminados con éxito");
        fetchData(); // Refrescar la lista de usuarios después de eliminar
      } catch (error) {
        console.error("Error al eliminar el usuario o el perfil", error);
        showErrorAlert(
          "Hubo un error al eliminar el Usuario y el Perfil. Por favor, inténtelo de nuevo."
        );
      }
    } else {
      showErrorAlert("No se puede eliminar el Usuario");
    }
  };

  const toggleTabDetal = (tab) => {
    if (activeTabDetal !== tab) setActiveTabDetal(tab);
  };

  return (
    <div>
      <div>
        <div
          className="d-flex justify-content-between miDivConGradiente px-3 rounded-bottom"
          style={{
            height: "5rem",
          }}
        >
          <div className="d-flex align-items-center text-white fw-bold text-uppercase">
            <h2>Mantenimiento de Usuarios</h2>
          </div>
          <div className="d-flex justify-content-right align-items-center">
            <form className="d-flex" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Buscar"
                aria-label="Buscar"
                value={searchTerm}
                onChange={handleSearchTermChange} // Manejar cambios en el término de búsqueda
              />
            </form>
            <button
              type="button"
              className="btn btn-danger"
              title="Crear Nuevo seguro"
              onClick={() => openModal(null)}
            >
              <i className="bi bi-file-plus"></i> Nuevo Usuario
            </button>
          </div>
        </div>
        <div
          className="mb-2"
          style={{
            height: "60vh",
            padding: "0rem 1rem",
          }}
        >
          <table className="table table-striped table-hover mt-5">
            <thead>
              <tr>
                <th>ID</th>
                <th>Usuario</th>
                <th>Rol</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {displayedUsers.map((user) => (
                <tr key={user.id}>
                  <td style={{ width: "5%" }}>{user.id}</td>
                  <td style={{ width: "30%" }}>{user.username}</td>
                  <td style={{ width: "30%" }}>{user.rol}</td>
                  <td style={{ width: "30%" }}>
                    {user.isActive ? "Activo" : "Inactivo"}
                  </td>
                  <td style={{ width: "5%" }}>
                    <div className="">
                      <button
                        type="button"
                        className="p-1 border-0"
                        title="Editar"
                        id="hoverIcon"
                        onClick={() => openModal(user)}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </button>
                      <button
                        type="button"
                        className="p-1 border-0"
                        title="Eliminar"
                        id="hoverIcon"
                        onClick={() => eliminarUser(user.id)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Paginación */}
          {!showForm && (
            <div className="d-flex justify-content-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className={`page-item`} style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      onClick={() => handlePageChange({ selected: 0 })}
                    >
                      Inicio
                    </span>
                  </li>
                  <li className={`page-item`} style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      style={{
                        height: "2.4rem",
                        width: "7rem",
                      }}
                      onClick={() =>
                        handlePageChange({
                          selected: Math.max(0, pageNumber - 10),
                        })
                      }
                    >
                      Anterior 10
                    </span>
                  </li>
                  {Array.from(
                    { length: Math.min(pageCount, 10) },
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          pageNumber === index ? " active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="page-link"
                          onClick={() => handlePageChange({ selected: index })}
                        >
                          {index + 1}
                        </span>
                      </li>
                    )
                  )}
                  <li className={`page-item`} style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      style={{
                        height: "2.4rem",
                        width: "7.5rem",
                      }}
                      onClick={() =>
                        handlePageChange({
                          selected: Math.min(pageCount - 1, pageNumber + 10),
                        })
                      }
                    >
                      Siguiente 10
                    </span>
                  </li>
                  <li className={`page-item`} style={{ cursor: "pointer" }}>
                    <span
                      className="page-link"
                      onClick={() =>
                        handlePageChange({ selected: pageCount - 1 })
                      }
                    >
                      Final
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
        {currentForm === "usersForm" && selectedUserLocal && (
          <div className="main-content"></div>
        )}

        <Modal
          isOpen={modalOpen}
          toggle={closeModal}
          size=" "
          style={{ maxWidth: "75rem" }}
        >
          <ModalHeader toggle={closeModal}>
            {isEditing ? "Editar Usuario" : "Mantenimiento de Usuario"}
          </ModalHeader>
          <ModalBody>
            <div className="d-flex py-2 m-1 border rounded bg-primary text-white">
              <div
                className="d-flex align-items-center px-2"
                style={{
                  width: "7rem",
                }}
              >
                <img
                  alt="Sample"
                  src="https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png"
                  style={{
                    width: "5rem",
                  }}
                />
              </div>
              {/* Imagen */}
            </div>
            {/* Información del paciente y consulta */}

            <div className="d-flex pt-3">
              {/* Contenido del Nav */}
              <div style={{ flex: 1 }}>
                <Nav tabs className="flex-column" style={{ cursor: "pointer" }}>
                  <NavItem>
                    <NavLink
                      className={activeTabDetal === "1" ? "active" : ""}
                      onClick={() => {
                        toggleTabDetal("1");
                      }}
                    >
                      Configuracion del Perfil
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTabDetal === "2" ? "active" : ""}
                      onClick={() => {
                        toggleTabDetal("2");
                      }}
                    >
                      Información del Usuario
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              {/* Contenido del TabContent */}
              <div style={{ flex: 3 }}>
                <TabContent
                  activeTab={activeTabDetal}
                  className=""
                  style={{
                    height: "50rem",
                    backgroundColor: "#fff",
                  }}
                >
                  <TabPane tabId="1">
                    <div className="bg-white text-black">
                      <div className="p-2">
                        <h4>Perfil del Usuario</h4>
                      </div>
                      <div
                        className="p-3 d-flex flex-column justify-content-top align-items-center "
                        style={{ height: "40rem" }}
                      >
                        <div
                          className="d-flex flex-column align-items-center pt-3"
                          style={{ width: "50rem" }}
                        >
                          <Col md={6}>
                            <FormGroup>
                              <Label for="nombreUsers">Nombre de Usuario</Label>
                              <Input
                                type="text"
                                id="nombreUsers"
                                value={formulario.nombreUsers}
                                onChange={(e) =>
                                  setFormulario({
                                    ...formulario,
                                    nombreUsers: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="rolUsers">Rol</Label>
                              <Input
                                type="select"
                                id="rolUsers"
                                value={formulario.rolUsers}
                                onChange={(e) =>
                                  setFormulario({
                                    ...formulario,
                                    rolUsers: e.target.value,
                                  })
                                }
                              >
                                <option value="DOCTOR">DOCTOR</option>
                                <option value="SECRETARIA">SECRETARIA</option>
                                <option value="SOPORTE">SOPORTE</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="estatusUsers">Estado</Label>
                              <Input
                                type="select"
                                id="estatusUsers"
                                value={
                                  formulario.estatusUsers
                                    ? "activo"
                                    : "inactivo"
                                } // Mostrar el valor como cadena
                                onChange={(e) =>
                                  setFormulario({
                                    ...formulario,
                                    estatusUsers: e.target.value === "activo",
                                  })
                                }
                              >
                                <option value="activo">Activo</option>
                                <option value="inactivo">Inactivo</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </div>
                        <div className="d-flex justify-content-center py-4">
                          <button
                            className="button-send py-2 px-3"
                            placeholder="Resetear la contraseña"
                          >
                            Restaurar Contraseña
                          </button>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="bg-white text-black d-flex flex-column">
                      <div className=" p-2">
                        <h4>Detalle de la información del Usuario</h4>
                      </div>
                      <div className="p-3" style={{ height: "40rem" }}>
                        <div className="row ">
                          <Col md={12}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="nombreUsers"
                                value={formulario.nombreUsers}
                                onChange={(e) =>
                                  handleInputChange(
                                    "nombreUsers",
                                    e.target.value
                                  )
                                }
                                placeholder="Nombre de Usuario"
                                disabled
                              />
                              <Label for="nombreUsers">Nombre de Usuario</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="docNom"
                                value={formData.docNom}
                                onChange={(e) =>
                                  handleInputChange("docNom", e.target.value)
                                }
                                placeholder="Dr. Jose Pérez"
                              />
                              <Label For="docNom">Nombre Completo</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="docCed"
                                value={formData.docCed}
                                onChange={(e) =>
                                  handleInputChange("docCed", e.target.value)
                                }
                                placeholder="000-0000000-0"
                              />
                              <Label For="docCed">Cédula de Identidad</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="nomCentro"
                                value={formData.nomCentro}
                                onChange={(e) =>
                                  handleInputChange("nomCentro", e.target.value)
                                }
                                placeholder="Centro"
                              />
                              <Label For="nomCentro">Centro de Salud</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="docEsp"
                                value={formData.docEsp}
                                onChange={(e) =>
                                  handleInputChange("docEsp", e.target.value)
                                }
                                placeholder="Especialidad"
                              />
                              <Label For="docEsp">Especialidad</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                className="form-control"
                                id="pcteNac"
                                value={formData.docNac}
                                onChange={(e) =>
                                  handleInputChange("docNac", e.target.value)
                                }
                                name="select"
                                type="select"
                              >
                                {countries.map((countries) => (
                                  <option
                                    key={countries.code}
                                    value={countries.pais}
                                  >
                                    {countries.pais}
                                  </option>
                                ))}
                              </Input>
                              <Label For="pcteNac">País de Residencia</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                className="form-control"
                                id="pcteNac"
                                value={formData.docProv}
                                onChange={(e) =>
                                  handleInputChange("docProv", e.target.value)
                                }
                                name="select"
                                type="select"
                              >
                                {provincias.map((provincias) => (
                                  <option
                                    key={provincias.idProvincia}
                                    value={provincias.Description}
                                  >
                                    {provincias.Description}
                                  </option>
                                ))}
                              </Input>
                              <Label For="pcteNac">Provincia</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="docDir"
                                value={formData.docDir}
                                onChange={(e) =>
                                  handleInputChange("docDir", e.target.value)
                                }
                                placeholder="Dirección"
                              />
                              <Label for="docDir">Dirección</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="docExe"
                                value={formData.docExe}
                                onChange={(e) =>
                                  handleInputChange("docExe", e.target.value)
                                }
                                placeholder="Exequátur"
                              />
                              <Label for="docExe">Exequátur</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="docEmail"
                                value={formData.docEmail}
                                onChange={(e) =>
                                  handleInputChange("docEmail", e.target.value)
                                }
                                placeholder="Email"
                              />
                              <Label for="docEmail">Email</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="docTel"
                                value={formData.docTel}
                                onChange={(e) =>
                                  handleInputChange("docTel", e.target.value)
                                }
                                placeholder="Teléfono"
                              />
                              <Label for="docTel">Teléfono</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="docExt"
                                value={formData.docExt}
                                onChange={(e) =>
                                  handleInputChange("docExt", e.target.value)
                                }
                                placeholder="Ext."
                              />
                              <Label for="docExt">Ext.</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="docCel"
                                value={formData.docCel}
                                onChange={(e) =>
                                  handleInputChange("docCel", e.target.value)
                                }
                                placeholder="Celular"
                              />
                              <Label for="docCel">Celular</Label>
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup floating>
                              <Input
                                type="text"
                                className="form-control"
                                id="docDirCert"
                                value={formData.docDirCert}
                                onChange={(e) =>
                                  handleInputChange(
                                    "docDirCert",
                                    e.target.value
                                  )
                                }
                                placeholder="Dirección"
                              />
                              <Label for="docDirCert">
                                Dirección Expedido de Certificado
                              </Label>
                            </FormGroup>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              className="button-cancel text-secondary"
              onClick={closeModal}
              style={{ height: "3rem" }}
            >
              Cancelar
            </Button>
            <Button
              className="button-save text-primary"
              onClick={isEditing ? editarUser : guardarNuevoUser}
              style={{ height: "3rem" }}
            >
              {isEditing ? "Guardar Edición" : "Guardar"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};
export default UsersForm;
