import { format } from "date-fns";
import jsPDF from "jspdf";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import medicGeekApi from "../../../config/axiosConfig";
import { showErrorAlert, showSuccessAlert, showInfoAlert } from "./../../../components/alerts";

const CertMedicoList = ({
  setCurrentForm,
  currentForm,
  setSelectedCertMedicos,
}) => {
  const [certMedicos, setCertMedicos] = useState([]);
  const [selectedPaciente, setSelectedPaciente] = useState(null);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const certMedicosPerPage = 10;
  const [selectedCertMedicosLocal, setSelectedCertMedicosLocal] =
    useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Variable para distinguir entre editar y crear
  const [searchTerm, setSearchTerm] = useState("");
  const [formulario, setFormulario] = useState({
    fechaCertMedico: "",
    constatado: "",
    recomedacion: "",
  });

  const [pacientes, setPacientes] = useState([]);
  const [pageSize, setPageSize] = useState(10); // Inicialmente, muestra 10 registros por página

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Cantidad de elementos por página

  const [profile, setProfile] = useState({
    docNom: "",
    docEsp: "",
    docCed: "",
    docExe: "",
    docProv: "",
  });

  const [constatado, setConstatado] = useState("");
  const [recomedacion, setRecomedacion] = useState("");

  // Contador de caracteres
  const maxChars1 = 5000;

  const [profileData, setProfileData] = useState(null);

  const openModal = (certMedico, customPageSize = 10) => {
    setSelectedCertMedicosLocal(certMedico); // Configura el certMedico seleccionado para la edición
    setPageSize(customPageSize); // Establece la cantidad de registros por página
  
    if (certMedico) {
      setIsEditing(true); // Establecer como modo edición cuando se abre el modal
      setFormulario({
        fechaCertMedico: certMedico.certifi_fe,
        constatado: certMedico.constatado,
        recomedacion: certMedico.recomedacion,
        nombrePaciente: certMedico?.paciente?.pcteNom,
        segundoNombrePaciente: certMedico?.paciente?.pcteNom2,
        apellidPaciente: certMedico?.paciente?.pcteApe1,
      });
      setSelectedPaciente(certMedico.paciente);
    } else {
      setIsEditing(false); // Establecer como modo creación cuando se abre el modal para crear uno nuevo
      
      setFormulario({
        fechaCertMedico: "",
        constatado: "",
        recomedacion: "",
        estatusCertMedico: "activo",
      });
      setSelectedPaciente(null); 
    }
    
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedCertMedicosLocal(null); // Limpia el CertMedico seleccionado
    setIsEditing(false); // Resetear el modo edición cuando se cierra el modal
    setModalOpen(false);
  };


  useEffect(() => {
    fetchProfile();
    fetchData();
  }, []);

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const fetchData = async () => {
    try {
      const patientsResponse = await medicGeekApi.get(
        `/patients?search=${searchTerm}`,
        {
          params: {
            $or: [
              { pcteNom: { $regex: searchTerm, $options: "i" } },
              { pcteNom2: { $regex: searchTerm, $options: "i" } },
              { pcteApe1: { $regex: searchTerm, $options: "i" } },
            ],
          },
        }
      );
      const sortedPacientes = patientsResponse.data.sort(
        (a, b) => a.pcteId - b.pcteId
      );
      setPacientes(sortedPacientes);

      const certMedicosResponse = await medicGeekApi.get("/certmedicos");
      if (certMedicosResponse.status === 200) {
        // Verifica si la solicitud fue exitosa
        const combinedData = certMedicosResponse.data.map((certMedico) => {
          const paciente = sortedPacientes.find(
            (p) => p.pcteId === certMedico.pcteId
          );
          return {
            ...certMedico,
            pcteNom: paciente.pcteNom,
            pcteNom2: paciente.pcteNom2,
            pcteApe1: paciente.pcteApe1,
            pcteApe2: paciente.pcteApe2,
            pcteDir: paciente.pcteDir,
            pcteCel: paciente.pcteCel,
            paciente: paciente || {},
          };
        });
        const sortedData = combinedData.sort(
          (a, b) => a.certifiId - b.certifiId
        );

        setCertMedicos(sortedData);

        const pageSize = 5; // Número de pacientes por página
        const totalPacientes = pacientes.length;
        const indexOfLastPaciente = currentPage * pageSize;
        const indexOfFirstPaciente = indexOfLastPaciente - pageSize;
        const currentPacientes = pacientes.slice(
          indexOfFirstPaciente,
          indexOfLastPaciente
        );
      } else {
        console.log(
          "Error al obtener datos de certificados médicos:",
          certMedicosResponse.statusText
        );
      }
    } catch (error) {
      console.log("Error al obtener datos de pacientes:", error);
    }
  };

  const fetchProfile = async () => {
    try {
      // Obtener el token desde localStorage
      const token = localStorage.getItem("token");
      if (token) {
        // Decodificar el token para obtener el nombre de usuario
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username; // Asegúrate de que 'username' sea correcto

        if (username) {
          // Buscar perfil por nombre de usuario
          const response = await medicGeekApi.get(`/profile/user/${username}`);
          const data = response.data;

          // Verificar si se recibió el perfil
          if (data) {
            setProfileData(data);
            setIsEditing(true);
          } else {
            console.error("No se encontró el perfil del usuario.");
          }
        } else {
          console.error("No se pudo obtener el nombre de usuario del token.");
        }
      } else {
        console.error("No se encontró el token.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const pageCount = Math.ceil(certMedicos.length / certMedicosPerPage);

  // Filtro para los pacientes en funcion de termino de busqueda
  const filteredPacientes = pacientes.filter((paciente) => {
    const fullName =
      `${paciente.pcteNom} ${paciente.pcteNom2} ${paciente.pcteApe1} ${paciente.pcteApe2}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedcertMedicos = certMedicos
    .filter((paciente) => {
      const searchWords = searchTerm.toLowerCase().split(" ");

      return searchWords.some(
        (word) =>
          paciente.pcteNom.toLowerCase().includes(word) ||
          paciente.pcteNom2.toLowerCase().includes(word) ||
          paciente.pcteApe1.toLowerCase().includes(word)
      );
    })
    .slice(
      pageNumber * certMedicosPerPage,
      (pageNumber + 1) * certMedicosPerPage
    );

    const guardarCertMedico = () => {
      const fechaActual = new Date();
      const dia = String(fechaActual.getDate()).padStart(2, "0");
      const mes = String(fechaActual.getMonth() + 1).padStart(2, "0"); // Meses empiezan en 0
      const año = fechaActual.getFullYear();
      const ampm = fechaActual.getHours() >= 12 ? "PM" : "AM";
      let horas = fechaActual.getHours() % 12;
      horas = horas === 0 ? 12 : horas; // Si es 0, establece 12
      const minutos = String(fechaActual.getMinutes()).padStart(2, "0");
    
      const fechaHoraFormateada = `${dia}/${mes}/${año} ${horas}:${minutos} ${ampm}`;
    
      // Validación de campos
      if (!formulario.constatado.trim() || !formulario.recomedacion.trim()) {
        showErrorAlert(
          "Algunos campos son obligatorios. Por favor, complete la información."
        );
        return;
      }
    
      const nuevoCertMedico = {
        certifi_fe: fechaHoraFormateada,
        constatado: formulario.constatado.trim(),
        recomedacion: formulario.recomedacion.trim(),
        pcteId: selectedPaciente ? selectedPaciente.pcteId : null,
      };
    
      medicGeekApi
        .post("/certmedicos", nuevoCertMedico)
        .then((response) => {
          closeModal();
          showSuccessAlert("Certificado creado con éxito.");
          fetchData();
        })
        .catch((error) => {
          showErrorAlert("Error al crear el Certificado Médico.");
          console.error("Error al crear el Certificado Médico", error);
        });
    };
    

    const editCertMedico = () => {
      if (selectedCertMedicosLocal && selectedCertMedicosLocal.certifiId) {
        const { certifiId, constatado: originalConstatado, recomedacion: originalRecomedacion } = selectedCertMedicosLocal;
    
        const nuevoConstatado = document.getElementById("constatado").value;
        const nuevaRecomedacion = document.getElementById("recomedacion").value;
    
        // Verifica si ha habido cambios
        const hasChanges = 
          originalConstatado !== nuevoConstatado || 
          originalRecomedacion !== nuevaRecomedacion;
    
        if (hasChanges) {
          const fechaActual = new Date().toISOString().split("T")[0];
          
          const newCertMedico = {
            certifi_fe: fechaActual,
            constatado: nuevoConstatado,
            recomedacion: nuevaRecomedacion,
            pcteId: selectedPaciente ? selectedPaciente.pcteId : null,
          };
    
          // Realiza una solicitud POST para guardar el nuevo registro en el servidor
          medicGeekApi
            .post("/certmedicos", newCertMedico)
            .then((response) => {
              closeModal();
              showSuccessAlert("Nuevo Certificado creado con éxito.");
              fetchData();
            })
            .catch((error) => {
              console.error("Error al crear el nuevo Certificado Médico", error);
              showErrorAlert("Error al crear el nuevo Certificado Médico.");
            });
        } else {
          showInfoAlert("No se han detectado cambios.");
        }
      } else {
        showErrorAlert("No se puede editar: registro no válido.");
      }
    };
    
    
    

  const handlePacienteSelection = (paciente) => {
    setSelectedPaciente(paciente);
    setModalOpen(false); // Cierra el modal actual
    openPacienteModal(); // Abre el nuevo modal para el paciente seleccionado
  };

  const openPacienteModal = () => {
    setModalOpen(true);
  };

  function convertirFechaLarga(fechaStr) {
    const opcionesFecha = { day: "numeric", month: "long", year: "numeric" };
    const opcionesHora = { hour: "numeric", minute: "numeric", hour12: true };

    // Convertir la cadena de fecha a un objeto Date
    const fecha = new Date(fechaStr);

    // Obtener partes de la fecha
    const fechaLarga = new Intl.DateTimeFormat("es-ES", opcionesFecha).format(
      fecha
    );
    const horaLarga = new Intl.DateTimeFormat("es-ES", opcionesHora).format(
      fecha
    );

    // Construir la cadena en formato largo
    const partesFecha = fechaLarga.split(" de ");
    const dia = partesFecha[0];
    const mes = partesFecha[1];
    const año = partesFecha[2];

    return `día ${dia} del mes de ${mes} del año ${año} a las ${horaLarga}`;
  }

  const fechaStr = "6/6/2024 9:44 AM";
  const fechaLarga = convertirFechaLarga(fechaStr);

  const previsualizarPDF = async (certMedico) => {
    const doc = new jsPDF();

    // Define las variables de estilo
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;
    const additionalMargin = 25; // Margen adicional de altura
    const fontSize = 12;

    doc.setFontSize(fontSize);

    const fechaLarga = convertirFechaLarga(certMedico.certifi_fe);

    const detalleText = `
    CERTIFICADO MEDICO
    `;

    const profileText = `
Yo, ${profileData.docNom}, Médico ${profileData.docEsp} CED. ${profileData.docCed}, provisto del correspondiente exequátur No. ${profileData.docExe} Certifico examinado a ${certMedico.pcteNom} ${certMedico.pcteNom2} ${certMedico.pcteApe1} ${certMedico.pcteApe2} Cédula de identidad y electoral No. ${selectedPaciente.pcteNumDoc}
    `;

    const certificarText = `
Certifico:
${certMedico.constatado}
  `;

    const recomendarText = `
Por lo que recomiendo:
${certMedico.recomedacion}
`;

    const footerText = `
${profileData.docDirCert} a petición del interesado, hoy ${fechaLarga}`;

    const contenido = `
  ${detalleText}
  ${profileText}
  ${certificarText}
  ${recomendarText}
    `;

    const lines = doc.splitTextToSize(contenido, pageWidth - 2 * margin);
    let y = margin + additionalMargin; // Coordenada y inicial con margen adicional
    const lineHeight = fontSize * 0.6;

    // Agrega el contenido línea por línea respetando los saltos de línea
    lines.forEach((line) => {
      if (
        y + lineHeight >
        pageHeight - margin - additionalMargin - lineHeight
      ) {
        doc.addPage();
        y = margin + additionalMargin; // Resetea la coordenada y en la nueva página con margen adicional
      }
      doc.text(line, margin, y);
      y += lineHeight;
    });

    // Asegúrate de que el footerText no se divida entre páginas
    const footerLines = doc.splitTextToSize(footerText, pageWidth - 2 * margin);
    if (
      y + footerLines.length * lineHeight >
      pageHeight - margin - additionalMargin
    ) {
      doc.addPage();
      y = margin + additionalMargin;
    }

    // Agrega el footerText
    footerLines.forEach((line) => {
      doc.text(line, margin, y);
      y += lineHeight;
    });

    // Abre una ventana emergente para previsualizar el PDF
    window.open(doc.output("bloburl"), "_blank");
  };

  // Contador de caractereres
  const handleChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxChars1) {
      setConstatado(text);
      setRecomedacion(text);
    }
  };

  return (
    <div>
      <div>
        <div
          className="d-flex justify-content-between miDivConGradiente px-3 rounded-bottom"
          style={{
            height: "5rem",
          }}
        >
          <div className="d-flex align-items-center text-white fw-bold text-uppercase">
            <h2>Certificados Médicos</h2>
          </div>
          <div className="d-flex justify-content-right align-items-center">
            <form className="d-flex" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Buscar"
                aria-label="Buscar"
                value={searchTerm}
                onChange={handleSearchTermChange} // Manejar cambios en el término de búsqueda
              />
            </form>
            <button
              type="button"
              className="btn btn-danger"
              title="Crear Nuevo seguro"
              onClick={() => openModal(null, 10)}
            >
              <i className="bi bi-person-add"></i> Nuevo Certificado Médico
            </button>
          </div>
        </div>
        <div
          className="mb-2"
          style={{
            height: "45vh",
            padding: "0rem 1rem",
          }}
        >
          <table className="table table-striped table-hover mt-5">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Dirección</th>
                <th>Teléfono</th>
                <th>Fecha de Certificado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {displayedcertMedicos.map((certMedico) => (
                <tr key={certMedico.certifiId}>
                  <td
                    style={{
                      width: "5%",
                    }}
                  >
                    {certMedico.certifiId}
                  </td>
                  <td
                    style={{
                      width: "25%",
                    }}
                  >
                    {certMedico.pcteNom} {certMedico.pcteNom2}{" "}
                    {certMedico.pcteApe1} {certMedico.pcteApe2}
                  </td>
                  <td
                    style={{ width: "30%", textAlign: "left" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={certMedico.pcteDir}
                  >
                    {certMedico.pcteDir?.length > 20
                      ? `${certMedico.pcteDir.slice(0, 35)}...`
                      : certMedico.pcteDir}
                  </td>
                  <td
                    style={{
                      width: "15%",
                    }}
                  >
                    {certMedico.pcteCel}
                  </td>
                  <td
                    style={{
                      width: "20%",
                    }}
                  >
                    {certMedico.certifi_fe}
                  </td>
                  <td
                    style={{
                      width: "5%",
                    }}
                  >
                    <div className="">
                      <button
                        type="button"
                        className="p-1 border-0"
                        title="Editar"
                        onClick={() => openModal(certMedico)}
                      >
                        <i class="bi bi-pencil-square"></i>
                      </button>
                      {/* <button 
                            type='button' 
                            className='p-1 border-0' 
                            title="Imprimir"
                          >
                            <i class="bi bi-printer"></i>
                          </button> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Paginación */}
          {!showForm && (
          <div className="d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                
                {/* Botón de página anterior */}
                <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                  <span
                    className="page-link"
                    aria-label="Previous"
                    onClick={() => handlePageChange({ selected: pageNumber - 1 })}
                    style={{ cursor: pageNumber === 0 ? "not-allowed" : "pointer" }}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </span>
                </li>

                {Array.from(
                  { length: Math.min(pageCount, 10) },
                  (_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        pageNumber === index ? " active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="page-link"
                        onClick={() => handlePageChange({ selected: index })}
                      >
                        {index + 1}
                      </span>
                    </li>
                  )
                )}

                {/* Botón de siguiente página */}
                <li className={`page-item ${pageNumber === pageCount - 1 ? "disabled" : ""}`}>
                  <span
                    className="page-link"
                    aria-label="Next"
                    onClick={() => handlePageChange({ selected: pageNumber + 1 })}
                    style={{ cursor: pageNumber === pageCount - 1 ? "not-allowed" : "pointer" }}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </span>
                </li>
                
              </ul>
            </nav>
          </div>
        )}
        </div>

        {currentForm === "CertMedicoList" && selectedCertMedicosLocal && (
          <div className="main-content"></div>
        )}
        <Modal
          isOpen={modalOpen}
          toggle={closeModal}
          modalClassName="custom-modal"
          contentClassName="custom-modal-content"
          style={{
            maxWidth: "40rem",
          }}
        >
          <ModalHeader toggle={closeModal}>
            {isEditing
              ? "Editar Certificado Médico"
              : "Crear Nuevo Certificado Médico"}
          </ModalHeader>
          <ModalBody>
            {selectedPaciente ? (
              <div>
                <div class="mb-4 sticky-top pt-3 border-bottom bg-primary text-white px-2 rounded-3">
                  <div className="d-flex justify-content-start">
                    <img
                      alt="Sample"
                      src="https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png"
                      style={{
                        width: "3rem",
                        marginTop: "1rem",
                      }}
                    />
                    <div className="input-group mx-2 input-group-sm text-uppercase fw-bold d-flex align-items-center">
                      <label className="border-0 fs-4" id="pcteNom">
                        {selectedPaciente.pcteNom} {selectedPaciente.pcteNom2}{" "}
                        {selectedPaciente.pcteApe1} {selectedPaciente.pcteApe2}
                      </label>
                    </div>
                    <div className="border-0 fs-6 d-flex align-items-center">
                      <label className="text-end" id="certifi_fe">
                        {formulario.fechaCertMedico
                          ? format(
                              new Date(formulario.fechaCertMedico),
                              "dd/MM/yyyy hh:mm a"
                            )
                          : ""}
                      </label>
                    </div>
                  </div>
                  <div class="d-flex bd-highlight mb-3">
                    <div className="d-flex justify-content-start">
                      <label className="border-0 fs-4 m-1" id="pcteEd">
                        {selectedPaciente.pcteEd} |
                      </label>
                      <label className="border-0 fs-4 m-1" id="pcteNumDoc">
                        {selectedPaciente.pcteNumDoc} |
                      </label>
                      <label className="border-0 fs-4 m-1" id="pcteSx">
                        {selectedPaciente.pcteSx}
                      </label>
                    </div>
                  </div>
                  <div></div>
                </div>
                <Form>
                  <FormGroup>
                    <Label for="constatado">
                      Constatado <span className="text-danger fw-bold">*</span>
                    </Label>
                    <Input
                      type="textarea"
                      id="constatado"
                      value={formulario.constatado}
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          constatado: e.target.value,
                        })
                      }
                      style={{
                        height: "10rem",
                      }}
                      maxLength={maxChars1}
                    />
                    <p style={{ fontSize: "12px", display: "flex" }}>
                      {maxChars1 - formulario.constatado.length}/{maxChars1}
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <Label for="recomedacion">
                      Recomendación{" "}
                      <span className="text-danger fw-bold">*</span>
                    </Label>
                    <Input
                      type="textarea"
                      id="recomedacion"
                      value={formulario.recomedacion}
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          recomedacion: e.target.value,
                        })
                      }
                      style={{
                        height: "10rem",
                      }}
                      maxLength={maxChars1}
                      required
                    />
                    <p style={{ fontSize: "12px", display: "flex" }}>
                      {maxChars1 - formulario.recomedacion.length}/{maxChars1}
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <Label for="recomedacion">Dirección</Label>
                    <Input
                      type="textarea"
                      id=""
                      value={profileData.docDirCert}
                      // onChange={(e) => profile && profile[0].docDirCert}
                      disabled
                    />
                  </FormGroup>
                </Form>
              </div>
            ) : (
              <div>
                <Form className="mb-4 sticky-top pt-3 border-bottom bg-primary text-white px-2 rounded-3">
                  <FormGroup>
                    <Label for="search">Buscar por nombre:</Label>
                    <Input
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Nombre del paciente"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </FormGroup>
                </Form>
                <ListGroup>
                  {filteredPacientes
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((paciente) => (
                      <ListGroupItem key={paciente.pcteId}>
                        <div className="d-flex justify-content-between">
                          <span>{`${paciente.pcteNom} ${paciente.pcteNom2} ${paciente.pcteApe1} ${paciente.pcteApe2}`}</span>
                          <Button
                            color="primary"
                            title="Nuevo Certificado Médico"
                            onClick={() => handlePacienteSelection(paciente)}
                          >
                            <i class="bi bi-clipboard-check-fill"></i>
                          </Button>
                        </div>
                      </ListGroupItem>
                    ))}
                </ListGroup>
                <nav className="pt-3 d-flex justify-content-center">
                  <ul className="pagination">
                    {Array.from(
                      {
                        length: Math.ceil(
                          filteredPacientes.length / itemsPerPage
                        ),
                      },
                      (_, index) => (
                        <li key={index} className="page-item">
                          <button
                            onClick={() => setCurrentPage(index + 1)}
                            className="page-link"
                          >
                            {index + 1}
                          </button>
                        </li>
                      )
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {selectedPaciente ? (
              <>
                <Button
                  className="button-save text-primary"
                  color="primary"
                  onClick={isEditing ? editCertMedico : guardarCertMedico}
                  style={{
                    height: "3rem",
                  }}
                >
                  <i class="bi bi-floppy-fill pe-1"></i>
                  {isEditing ? "Guardar Edición" : "Guardar"}
                </Button>

                <Button
                  className="button-print"
                  onClick={() => previsualizarPDF(selectedCertMedicosLocal)}
                  type="button"
                  style={{
                    color: "#cb05f3",
                    height: "3rem",
                    marginLeft: "10px",
                  }}
                >
                  <i className="bbi bi-printer-fill pe-1"></i>
                  Imprimir
                </Button>
              </>
            ) : (
              <Button
                className="button-cancel text-secondary"
                onClick={closeModal}
                style={{ height: "3rem" }}
              >
                Cancelar
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};
export default CertMedicoList;
