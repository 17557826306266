import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import PacienteList from './Forms/PacienteForm/PacienteList';
import ConsultaForm from './Forms/Consulta/ConsultaForm';
import PacienteHistory from './Forms/Historial/PatientsHistory';
import RegVademecumForm from './Forms/Mantenimientos/vademecum/VademecumList';
import RegSeguroForm from './Forms/Mantenimientos/seguro/SeguroList';
import RegCategoryForm from './Forms/Mantenimientos/category/CategoryList';
import RegClassificationForm from './Forms/Mantenimientos/classification/classificationForm';
import RegPaletaColorForm from './Forms/Mantenimientos/PaletaColores/PaletaColor';
import RegExternalCauseForm from './Forms/Mantenimientos/causaExterna/ExternalCauseForm';
import RegCie10Form from './Forms/Mantenimientos/cie10/cie10List';
import CertMedico from './Forms/Funciones/CertificadoMedico/CertificadoMedico';
import PacienteToEditForm from './Forms/PacienteForm/PacienteForm';
import PacienteForm from './Forms/PacienteForm/PacienteForm';
import CalendarForm from './Forms/Calendar/Calendar';
import ProfileForm from './Forms/Profile/Profile';
import LoginForm from './Forms/Login/Login';
import ReportCollections from './Forms/Report/Cobros/ReporteCobros';
import RestoreForm from './Forms/Login/restore';
import UserForm from './Forms/Seguridad/ConfigForms/users/usersForm';
import LabTestList from './Forms/Funciones/LabTest/labTestForm';
import LabTestListCrud from './Forms/Mantenimientos/labTest/labTestCrud';

const AuthContent = ({ globalStyles, isCollapsed, toggleSidebar }) => {
  const { isAuthenticated } = useAuth();
  const [showForm, setShowForm] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [selectedPaciente, setSelectedPaciente] = useState(null);
  const [selectedFormulario, setSelectedFormulario] = useState(null);

  const handleShowForm = (formName, paciente = null, formulario = null) => {
    setCurrentForm(formName);
    setSelectedPaciente(paciente);
    setSelectedFormulario(formulario);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setCurrentForm(null);
    setSelectedPaciente(null);
    setSelectedFormulario(null);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setCurrentForm('login');
    }
  }, [isAuthenticated]);

  return (
    <div
      className={`App ${isAuthenticated ? 'authenticated' : 'unauthenticated'}`}
      style={{
        ...globalStyles,
        display: 'grid',
        gridTemplateColumns: isAuthenticated ? (isCollapsed ? '2rem auto' : '0rem auto') : 'fr',
        height: '100vh',
      }}
    >
      {isAuthenticated ? (
        <>
          <Sidebar 
            isCollapsed={isCollapsed}
            ProfileClick={() => handleShowForm('ProfileForm')}
            LoginClick={() => handleShowForm('LoginForm')}
            RestoreClick={() => handleShowForm('RestoreForm')}
            onNewPacienteClick={() => handleShowForm('PacienteList')}
            onNewConsultaClick={() => handleShowForm('ConsultaForm')}
            onNewPacienteHistoryClick={() => handleShowForm('PacienteHistory')}
            RegVademecumClick={() => handleShowForm('RegVademecumForm')}
            RegSeguroClick={() => handleShowForm('RegSeguroForm')}
            RegCategoryClick={() => handleShowForm('RegCategoryForm')}
            RegClassificationClick={() => handleShowForm('RegClassificationForm')}
            RegPaletaColorClick={() => handleShowForm('RegPaletaColorForm')}
            RegCie10Click={() => handleShowForm('RegCie10Form')}
            RegExternalCauseClick={() => handleShowForm('RegExternalCauseForm')}
            CertMedicoClick={() => handleShowForm('CertMedico')}
            AgendaClick={() => handleShowForm('CalendarForm')}
            ReportCollectionsClick={() => handleShowForm('ReportCollections')}
            UsersClick={() => handleShowForm('UserForm')}
            LabTestListClick={() => handleShowForm('LabTestList')}
            LabTestListCrudClick={() => handleShowForm('LabTestListCrud')}
          />
          <div className={`main-content ${isCollapsed ? 'collapsed' : ''}`} style={{ backgroundColor: '#f5efef', height: '100%', overflowY: 'auto' }}>
            <Navbar toggleSidebar={toggleSidebar} />
            <div className="">
              <Routes>
                <Route path="/profile" element={<ProfileForm />} />
                <Route path="/restore" element={<RestoreForm onCloseForm={handleCloseForm} />} />
                <Route path="/patients" element={<PacienteList setCurrentForm={setCurrentForm} currentForm={currentForm} setSelectedPaciente={setSelectedPaciente} />} />
                <Route path="/patients/new/:newPcteId" element={<PacienteForm />} />
                <Route path="/patients/edit/:id" element={<PacienteToEditForm currentForm={currentForm} selectedPaciente={selectedPaciente} onCloseForm={handleCloseForm} />} />
                <Route path="/calendar" element={<CalendarForm setCurrentForm={setCurrentForm} currentForm={currentForm} setSelectedPaciente={setSelectedPaciente} setSelectedFormulario={setSelectedFormulario} />} />
                <Route path="/patient/:id/patient-history" element={selectedPaciente && <PacienteHistory setCurrentForm={setCurrentForm} currentForm={currentForm} onCloseForm={handleCloseForm} setSelectedPaciente={setSelectedPaciente} paciente={selectedPaciente} setSelectedFormulario={setSelectedFormulario} />} />
                <Route path="/patients/:id/encounters/:consultId" element={selectedPaciente && <ConsultaForm onCloseForm={handleCloseForm} paciente={selectedPaciente} setSelectedFormulario={setSelectedFormulario} />} />
                <Route path="/administracion/reg-vademecum-form" element={<RegVademecumForm onCloseForm={handleCloseForm} />} />
                <Route path="/administracion/reg-seguro-form" element={<RegSeguroForm onCloseForm={handleCloseForm} />} />
                <Route path="/administracion/reg-category-form" element={<RegCategoryForm onCloseForm={handleCloseForm} />} />
                <Route path="/administracion/reg-classification-form" element={<RegClassificationForm onCloseForm={handleCloseForm} />} />
                <Route path="/administracion/reg-paletaColor-form" element={<RegPaletaColorForm onCloseForm={handleCloseForm} />} />
                <Route path="/administracion/reg-cie10-form" element={<RegCie10Form onCloseForm={handleCloseForm} />} />
                <Route path="/administracion/reg-externalCause-form" element={<RegExternalCauseForm onCloseForm={handleCloseForm} />} />
                <Route path="/funciones/func-certmedico" element={<CertMedico onCloseForm={handleCloseForm} />} />
                <Route path="/report/report-collections" element={<ReportCollections onCloseForm={handleCloseForm} />} />
                <Route path="/administracion/config/users" element={<UserForm onCloseForm={handleCloseForm} />} />
                <Route path="/funciones/func-labTest" element={<LabTestList onCloseForm={handleCloseForm} />} />
                <Route path="/administracion/reg-labTestCrud" element={<LabTestListCrud onCloseForm={handleCloseForm} />} />
                <Route path="/" element={<MainContent setCurrentForm={setCurrentForm} currentForm={currentForm} setSelectedPaciente={setSelectedPaciente} setSelectedFormulario={setSelectedFormulario} />} />
              </Routes>
            </div>
          </div>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <LoginForm />
        </div>
      )}
    </div>
  );
};

export default AuthContent;
