// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* text editor*/
.rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 7px;
    padding-bottom: 7px;
    width: 282px;
}
/* picked date align */
.rs-picker-default .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 67px;
    display: inline-block;
    outline: none;
    cursor: pointer;
    color: #575757;
    border: 1px solid #e5e5ea;
    padding-left: 44px;
}
/* calander align */
.rs-picker-toggle-caret {
    display: inline-block;
    margin-right: 240px;
    position: absolute;
    top: 8px;
    right: 12px;
    font-weight: normal;
    color: #8e8e93;
}
/* ok button style */
.rs-picker-toolbar-right-btn-ok { 
    text-align: center; 
    cursor: pointer;
    outline: 0 ; 
    border: none; 
    padding: 8px 12px; 
    font-size: 14px; 
    border-radius: 30px;
    color: #fff;
    background-color: #3498ff;
    width: 100px;
}

/* range style */
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    padding-left: 0;
    padding-right: 0;
    border-radius: 21px;
    display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/styles/calendar.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;AAChB;AACA,sBAAsB;AACtB;IACI,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,qBAAqB;IACrB,aAAa;IACb,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,kBAAkB;AACtB;AACA,mBAAmB;AACnB;IACI,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,mBAAmB;IACnB,cAAc;AAClB;AACA,oBAAoB;AACpB;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,YAAY;AAChB;;AAEA,gBAAgB;AAChB;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":["/* text editor*/\n.rs-picker-default .rs-picker-toggle.rs-btn {\n    padding-top: 7px;\n    padding-bottom: 7px;\n    width: 282px;\n}\n/* picked date align */\n.rs-picker-default .rs-picker-toggle {\n    position: relative;\n    z-index: 5;\n    padding-right: 67px;\n    display: inline-block;\n    outline: none;\n    cursor: pointer;\n    color: #575757;\n    border: 1px solid #e5e5ea;\n    padding-left: 44px;\n}\n/* calander align */\n.rs-picker-toggle-caret {\n    display: inline-block;\n    margin-right: 240px;\n    position: absolute;\n    top: 8px;\n    right: 12px;\n    font-weight: normal;\n    color: #8e8e93;\n}\n/* ok button style */\n.rs-picker-toolbar-right-btn-ok { \n    text-align: center; \n    cursor: pointer;\n    outline: 0 ; \n    border: none; \n    padding: 8px 12px; \n    font-size: 14px; \n    border-radius: 30px;\n    color: #fff;\n    background-color: #3498ff;\n    width: 100px;\n}\n\n/* range style */\n.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {\n    padding-left: 0;\n    padding-right: 0;\n    border-radius: 21px;\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
