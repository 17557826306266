import "cally";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import "../../../styles/calendar.css";
// import Skeleton from 'react-loading-skeleton';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import medicGeekApi from "../../../config/axiosConfig";
dayjs.extend(customParseFormat);
var isBetween = require("dayjs/plugin/isBetween");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

const PayList = ({ currentForm }) => {
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [pagos, setPagos] = useState([]); // Estado para los pagos
  const [patientsMap, setPatientsMap] = useState({});
  const [filtroFechaInicio, setFiltroFechaInicio] = useState("");
  const [filtroFechaFin, setFiltroFechaFin] = useState("");
  const [pagosFiltrados, setPagosFiltrados] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const paysPerPage = 10;
  const [selectedPayLocal, setselectedPayLocal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedRange, setSelectedRange] = useState({
    from: null,
    to: null,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Función para actualizar filtroFechaInicio
  const handleFechaInicioChange = (fecha) => {
    setFiltroFechaInicio(fecha);
  };

  // Función para actualizar filtroFechaFin
  const handleFechaFinChange = (fecha) => {
    setFiltroFechaFin(fecha);
  };

  const fetchData = async () => {
    try {
      const response = await medicGeekApi.get("/pays", {
        params: {
          search: searchTerm, // Envía el término de búsqueda al backend
          filtroFechaInicio,
          filtroFechaFin,
        },
      });

      const sortedPays = response.data.sort((a, b) => a.payId - b.payId);

      const patientResponse = await medicGeekApi.get("/patients");
      const patientData = patientResponse.data;
      // Crear un mapa de pacientes para acceder fácilmente por ID
      const patientsMapData = {};
      patientData.forEach((patient) => {
        patientsMapData[
          patient.pcteId
        ] = `${patient.pcteNom} ${patient.pcteNom2} ${patient.pcteApe1}`;
      });

      // console.log(sortedPays)

      setPagos(sortedPays);
      setPatientsMap(patientsMapData);
      setLoading(false); // Marcar la carga como completada
    } catch (error) {
      console.log(error);
    }
  };

  const filtrarPagos = () => {
    const pagosFiltrados = pagos.filter((pago) => {
      if (!pago?.payFec) {
        return false; // Si no hay fecha de pago, no lo incluyas en los filtrados
      }

      const fechaPago = dayjs(pago?.payFec, "DD/MM/YYYY hh:mm A"); // Convierte la fecha de pago a un objeto dayjs

      if (!filtroFechaInicio && !filtroFechaFin) {
        return true; // Si no hay filtros de fechas, incluye todos los pagos
      }

      let fechaInicioFormatted, fechaFinFormatted;

      // Comprueba si hay filtro de fecha de inicio y formatea la fecha
      if (filtroFechaInicio) {
        fechaInicioFormatted = dayjs(filtroFechaInicio, "YYYY/MM/DD").startOf(
          "day"
        ); // Formatea y establece la hora a las 00:00:00
      }

      // Comprueba si hay filtro de fecha de fin y formatea la fecha
      if (filtroFechaFin) {
        fechaFinFormatted = dayjs(filtroFechaFin, "YYYY/MM/DD").endOf("day"); // Formatea y establece la hora a las 23:59:59
      }

      // Realiza la comparación para el filtro de fechas
      if (fechaInicioFormatted && fechaFinFormatted) {
        return fechaPago.isBetween(fechaInicioFormatted, fechaFinFormatted);
      }
      return true;
    });

    setPagosFiltrados(pagosFiltrados);
  };

  useEffect(() => {
    filtrarPagos();
  }, [filtroFechaInicio, filtroFechaFin, pagos]);

  const formatDateTimeColumn = (date) => {
    if (date === null) {
      return "";
    }
    return dayjs(date).format("DD/MM/YYYY h:mm A");
  };

  const formatDateColumn = (date) => {
    if (date === null) {
      return "";
    }
    // console.log(date)
    return dayjs(date, "DD/MM/YYYY").format("DD/MM/YYYY");
  };

  const isDateBetween = (dateToCheck, startDate, endDate) => {
    const date = dayjs(dateToCheck);
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    return date.isAfter(start) && date.isBefore(end);
  };

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    const startDate = dayjs(selection.startDate).format("YYYY-MM-DD");
    const endDate = dayjs(selection.endDate).format("YYYY-MM-DD");
    setFiltroFechaInicio(startDate);
    setFiltroFechaFin(endDate);
  };

  const pageCount = Math.ceil(pagosFiltrados.length / paysPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedPay = pagosFiltrados
    .filter((pay) => {
      return (
        pay.payId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        pay.pcteId.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .slice(pageNumber * paysPerPage, (pageNumber + 1) * paysPerPage);

  useEffect(() => {
    fetchData();
  }, []);

  // Contador de suma columna
  const totalAmount = pagosFiltrados.reduce(
    (total, pay) => total + parseFloat(pay.payCt),
    0
  );
  const currentDate = dayjs().format("DD-MM-DD"); // Obtiene la fecha actual

  const handleDayClick = (day) => {
    if (!filtroFechaInicio || (filtroFechaInicio && filtroFechaFin)) {
      setFiltroFechaInicio(day);
      setFiltroFechaFin(null);
    } else {
      setFiltroFechaFin(day);
    }
  };

  useEffect(() => {
    if (selectedRange.from && selectedRange.to) {
      setStartDate(selectedRange.from);
      setEndDate(selectedRange.to);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  }, [selectedRange]);

  const handlePrintPDF = () => {
    const doc = new jsPDF();
    const totalPagesExp = "{total_pages_count_string}";

    // Información estática para el PDF
    const header = "Listado de Pagos"; // Encabezado del documento
    const currentDate = dayjs().format("DD/MM/YYYY"); // Fecha actual

    // Información dinámica del PDF
    const fechaInicio = filtroFechaInicio
      ? dayjs(filtroFechaInicio).format("DD/MM/YYYY")
      : "-";
    const fechaFin = filtroFechaFin
      ? dayjs(filtroFechaFin).format("DD/MM/YYYY")
      : "-";
    const totalRegistros = pagosFiltrados.length;
    const totalMonto = totalAmount.toFixed(2); // Total de monto formateado

    // Contenido del PDF
    const content = [
      { label: "Fecha de inicio:", value: fechaInicio },
      { label: "Fecha de fin:", value: fechaFin },
      { label: "Total de registros:", value: totalRegistros.toString() },
      { label: "Total de monto:", value: totalMonto },
      // Puedes agregar más campos según sea necesario
    ];

    // Añadir el encabezado al documento
    doc.setFontSize(18);
    doc.text(header, 14, 22);

    // Añadir la fecha actual al documento
    doc.setFontSize(12);
    doc.text(`Fecha: ${currentDate}`, 14, 30);

    // Añadir los campos y su contenido al documento
    doc.setFontSize(12);
    content.forEach((item, index) => {
      doc.text(`${item.label} ${item.value}`, 14, 40 + index * 10);
    });

    // Añadir el listado de pagos al documento (si hay pagos filtrados)
    if (pagosFiltrados.length > 0) {
      doc.autoTable({
        startY: 80, // Posición inicial de la tabla
        head: [["Recibo No.", "Fecha", "Paciente", "Cantidad", "Nota"]], // Encabezados de la tabla
        body: pagosFiltrados.map((pay) => [
          pay.payId,
          formatDateColumn(pay.payFec),
          patientsMap[pay.pcteId] || "Nombre no encontrado",
          parseFloat(pay.payCt).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          pay.payDesc,
        ]),
        theme: "grid", // Estilo de la tabla
        styles: { fontSize: 10 }, // Estilo del texto
      });
    }

    // Agregar el número total de páginas
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        14,
        doc.internal.pageSize.height - 10,
        `Página ${i} de ${pageCount}`
      );
    }

    // Descargar el PDF
    doc.save("pay-list.pdf");
  };

  const handleDateChange = (event) => {
    const startDate = event.detail.startDate;
    const endDate = event.detail.endDate;
    setFiltroFechaInicio(startDate);
    setFiltroFechaFin(endDate);

    console.log("fecha inicio", startDate);
    console.log("fecha fin", endDate);
    // Llama a la función filtrarPagos con las fechas seleccionadas
    filtrarPagos(startDate, endDate);
  };

  return (
    <div>
      <div>
        <div
          className="d-flex justify-content-between miDivConGradiente px-3 rounded-bottom"
          style={{
            height: "5rem",
          }}
        >
          <div className="d-flex align-items-center text-white fw-bold text-uppercase">
            <h2>Reporte de Cobros</h2>
          </div>
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-success"
              title="Crear Nuevo Color"
              onClick={handlePrintPDF}
            >
              <i className="bbi bi-printer-fill"></i> Imprimir Listado
            </button>
          </div>
        </div>
        <div id="pay-list">
          <div
            className="mb-2"
            style={{
              height: "50vh",
              padding: "0rem 1rem",
            }}
          >
            <div
              className="miDivConGradiente rounded text-white fw-bold fs-5 my-2 d-flex justify-content-center py-2 gap-2"
              style={{ height: "3rem" }}
            >
              <label>Desde</label>
              <input
                class="rounded border border-1"
                id="filtroFechaInicio"
                type="date"
                value={filtroFechaInicio}
                onChange={(e) => setFiltroFechaInicio(e.target.value)}
              />
              <label>Hasta</label>
              <input
                class="rounded border border-1"
                type="date"
                value={filtroFechaFin}
                onChange={(e) => setFiltroFechaFin(e.target.value)}
              />
            </div>

            {/* Fecha */}

            <div>
              <table className="table table-striped table-hover mt-3">
                <thead>
                  <tr>
                    <th>Recibo No.</th>
                    <th>Fecha</th>
                    <th>Paciente</th>
                    <th>Cantidad</th>
                    <th>Nota</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedPay.map((pay) => (
                    <tr key={pay.payId}>
                      <td
                        style={{
                          width: "10%",
                        }}
                      >
                        {pay.payId}
                      </td>
                      <td
                        style={{
                          width: "15%",
                        }}
                      >
                        {pay.payFec}
                      </td>
                      <td
                        style={{
                          width: "40%",
                        }}
                      >
                        {patientsMap[pay.pcteId] || "Nombre no encontrado"}
                      </td>
                      <td style={{ width: "10%" }}>
                        {parseFloat(pay.payCt).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td
                        style={{
                          width: "20%",
                        }}
                      >
                        {pay.payDesc}
                      </td>
                      <td
                        style={{
                          width: "5%",
                        }}
                      >
                        <div className="">
                          <button
                            type="button"
                            className="p-1 border-0"
                            title="Imprimir"
                            id="hoverIcon"
                          >
                            <i class="bi bi-printer-fill"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Listado */}

            <div
              className="text-start miDivConGradiente w-1 rounded text-uppercase text-white mx-3 px-2 py-2 my-2 fs-5 d-flex justify-content-between"
              style={{ height: "3rem" }}
            >
              <label>
                Total de Registros:{" "}
                <span className="fw-bold text-decoration-underline">
                  {pagosFiltrados.length}
                </span>
              </label>
              <label>
                Total:{" "}
                <span className="fw-bold text-decoration-underline">
                  {totalAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </label>
            </div>
            {/* Contador */}

            {!showForm && (
              <div className="d-flex justify-content-center mt-5">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        onClick={() => handlePageChange({ selected: 0 })}
                      >
                        Inicio
                      </span>
                    </li>
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        style={{
                          height: "2.4rem",
                          width: "7rem",
                        }}
                        onClick={() =>
                          handlePageChange({
                            selected: Math.max(0, pageNumber - 10),
                          })
                        }
                      >
                        Anterior 10
                      </span>
                    </li>
                    {Array.from(
                      { length: Math.min(pageCount, 10) },
                      (_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            pageNumber === index ? " active" : ""
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              handlePageChange({ selected: index })
                            }
                          >
                            {index + 1}
                          </span>
                        </li>
                      )
                    )}
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        style={{
                          height: "2.4rem",
                          width: "7.5rem",
                        }}
                        onClick={() =>
                          handlePageChange({
                            selected: Math.min(pageCount - 1, pageNumber + 10),
                          })
                        }
                      >
                        Siguiente 10
                      </span>
                    </li>
                    <li className={`page-item`} style={{ cursor: "pointer" }}>
                      <span
                        className="page-link"
                        onClick={() =>
                          handlePageChange({ selected: pageCount - 1 })
                        }
                      >
                        Final
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
            {/* Paginación */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PayList;
