import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import medicGeekApi from "../../config/axiosConfig";
import { API_BASE_URL } from "../../config/constants";
import {
  showConfirmationAlert,
  showErrorAlert,
  showSuccessAlert,
} from "./../../components/alerts";
import "./../../styles/scrollbar.css";

const ConsultaForm = ({ paciente, onCloseForm, formulario }) => {
  const { pcteId, consultId } = useParams();

  const history = useNavigate();
  const [consultFec, setConsultFec] = useState("");
  const [consultMot, setConsultMot] = useState("");
  const [estatus, setEstatus] = useState("Activa");
  const [consultHea, setConsultHea] = useState("");
  const [consultMotOld, setConsultMotOld] = useState("");
  const [antOtros, setAntOtros] = useState("");
  const [antFarmacos, setAntFarmacos] = useState("");
  const [mquirur, setMquirur] = useState("");
  const [mtrauma, setMtrauma] = useState("");
  const [malergi, setMalergi] = useState("");
  const [mtransf, setMtransf] = useState("");
  const [mmadre, setMmadre] = useState("");
  const [mpadre, setMpadre] = useState("");
  const [mconyu, setMconyu] = useState("");
  const [motros, setMotros] = useState("");
  const [exam1, setExam1] = useState("");
  const [exam2, setExam2] = useState("");
  const [exam3, setExam3] = useState("");
  const [exam4, setExam4] = useState("");
  const [exam5, setExam5] = useState("");
  const [exam6, setExam6] = useState("");
  // const [exam7, setExam7] = useState('');
  const [exam8, setExam8] = useState("");
  const [exam9, setExam9] = useState("");
  const [exam10, setExam10] = useState("");
  const [exam11, setExam11] = useState("");
  const [exam12, setExam12] = useState("");
  const [exam13, setExam13] = useState("");
  const [exam14, setExam14] = useState("");
  const [exam15, setExam15] = useState("");
  const [exam16, setExam16] = useState("");
  const [exam17, setExam17] = useState("");
  const [exam18, setExam18] = useState("");
  const [examComentario, setExamComentario] = useState("");
  const [estGabinete, setEstGabinete] = useState("");
  const [consultDiag, setConsultDiag] = useState("");
  const [consultTipDiag, setConsultTipDiag] = useState("");
  const [consultClaDiag, setConsultClaDiag] = useState("");

  // Activar los tabs
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabLab, setActiveTabLab] = useState("1");

  // Patologia
  const [tappctesData, setTappctesData] = useState({
    checke1: false,
    checke2: false,
    checke3: false,
    checke4: false,
    checke5: false,
    checke6: false,
    checke7: false,
    checke8: false,
    checke9: false,
    checke10: false,
    checke11: false,
    checke12: false,
    checke13: false,
    checke14: false,
    checke15: false,
    toxicChecke1: false,
    toxicChecke2: false,
    toxicChecke3: false,
    toxicOtros: "",
  });

  // Analisis
  const [resultLab, setResultLab] = useState("");

  const [analysisData, setAnalysisData] = useState([]);
  const [citasFiltradas, setCitasFiltradas] = useState([]);
  const [analysisValues, setAnalysisValues] = useState({
    analysis1: "0.00",
    analysis2: "0",
    analysis3: "0.00",
    analysis4: "0",
    analysis5: "0",
    analysis6: "0.00",
    analysis7: "0.00",
    analysis8: "0.00",
    analysis9: "0.00",
    analysis10: "0.00",
    analysis11: "0.00",
    analysis12: "0",
    analysis13: "0.00",
    analysis14: "0",
    analysis15: "0.00",
    analysis16: "0.00",
    analysis17: "0.00",
    analysis18: "0",
    analysis19: "0.00",
    analysis20: "0.00",
    analysis21: "0.00",
    analysis22: "0.00",
    analysis23: "0.00",
    analysis24: "0.00",
    analysis25: "0.00",
    analysis26: "0.00",
    analysis27: "0.00",
    analysis28: "0.00",
    analysis29: "0.00",
    analysis30: "0.00",
    analysis31: "0",
    analysis32: "0.00",
    analysis33: "0",
    analysis34: "0.00",
    analysis35: "0.00",

    analysis36: "",
    analysis37: "",
    analysis38: "",
    analysis39: "",

    analysis40: "",
    analysis41: "",
    analysis42: "",
    analysis43: "",
  });

  // Consultas
  const [consultas, setConsultas] = useState([]);

  // Cruds
  const [externalCauses, setExternalCauses] = useState([]);
  const [causeId, setCauseId] = useState("");

  // Acordion
  const [isAccordionDiagnosticoOpen, setIsAccordionDiagnosticoOpen] =
    useState(false);
  const [isAccordionCitaOpen, setIsAccordionCitaOpen] = useState(true);
  const [openAccordion, setOpenAccordion] = useState(null);

  // Agregar altura
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [parentDivHeight, setParentDivHeight] = useState("18rem");
  const [alturaContenedorCita, setAlturaContenedorCita] = useState("40rem");
  const parentDivRef = useRef();
  const [expanded, setExpanded] = useState(false);

  // Contador de caracteres
  const maxChars1 = 5000;
  const maxChars2 = 2000;
  const maxChars3 = 500;
  const maxChars4 = 10000;

  // Nuevo estado para controlar si se están editando los campos
  const [isEditing, setIsEditing] = useState(false);

  // Buscador
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermMedic, setSearchTermMedic] = useState("");

  // Componente de visualizar Historico
  const [historicoVisible, setHistoricoVisible] = useState(false);
  const [ultimaConsulta, setUltimaConsulta] = useState({});
  const [ultimaConsultaAll, setUltimaConsultaAll] = useState(0);
  const [isEditingConsulta, setIsEditingConsulta] = useState(false);

  // Categoria
  const [categoryId, setCategoryId] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [payData, setPayData] = useState([]);
  const [ultimoPay, setUltimoPay] = useState([]);

  // Parametros para el Modal - Cie-10
  const [isModalCie10Open, setIsModalCie10Open] = useState(false);
  const [cie10Data, setCie10Data] = useState([]);
  const [consultCie10, setConsultCie10] = useState("");
  const [codeCie10, setCodeCie10] = useState("");
  const [descriptionCie10, setDescriptionCie10] = useState("");
  const [selectedCIE10, setSelectedCIE10] = useState(null);
  const [isDescriptionCie10Editable, setIsDescriptionCie10Editable] =
    useState(true);

  // Modal para guardar consulta
  const [isSaveConsultModalOpen, setIsSaveConsultModalOpen] = useState(false);
  const [camposCompletos, setCamposCompletos] = useState(false);
  const [openModalSaveConsult, setOpenModalSaveConsult] = useState(false);

  // Modal para el resultado de Laboratorio
  const [openModalResultadoLab, setOpenModalResultadoLab] = useState("");

  // Paginacion
  const consultasPerPage = 5;
  const [pageNumber, setPageNumber] = useState(0);
  const pageCountConsult = Math.ceil(consultas.length / consultasPerPage);
  // const pageCountConsultList = Math.ceil(consultas.length / consultasPerPage);

  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(cie10Data.length / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(0);

  // Recetas Medicas
  const [dosis, setDosis] = useState("");
  const [total, setTotal] = useState("");
  const [medicamentos, setMedicamentos] = useState([]);
  const [showMedicamentosModal, setShowMedicamentosModal] = useState(false);
  const [medicamentosSearchResults, setMedicamentosSearchResults] = useState(
    []
  );
  const [isMedicamentosModalOpen, setIsMedicamentosModalOpen] = useState(false);
  const [descripcionMedicamento, setDescripcionMedicamento] = useState("");
  const [dosisMedicamento, setDosisMedicamento] = useState("");
  const [recetaControlada, setRecetaControlada] = useState("");

  // Agendar Cita
  const [fechaActual, setFechaActual] = useState("");
  const [fechaCita, setFechaCita] = useState("");
  const [horaCita, setHoraCita] = useState("");
  const [horaFormateada, setHoraFormateada] = useState("");
  const [notaCita, setNotaCita] = useState("");
  const [categoryCitaId, setCategoryCitaId] = useState("");
  const [isSwift, setIsSwift] = useState(false); // Ajusta el valor inicial según tu lógica

  // Procesar Pago
  const [cantidadPago, setCantidadPago] = useState("");
  const [descripcionPago, setDescripcionPago] = useState("");

  const [fechaConsulta, setFechaConsulta] = useState("");

  useEffect(() => {
    //  console.log('entrando a la consulta');
    fetchData();
  }, [paciente, isAccordionOpen, pcteId, consultId, formulario]);

  const fetchData = async () => {
    try {
      // Llamadas a los endpoints independientes del paciente
      const [
        consultAllResponse,
        externalCauseResponse,
        categoryResponse,
        paysResponse,
        cie10Response,
      ] = await Promise.all([
        medicGeekApi.get("/consults"),
        medicGeekApi.get("/externalCauses"),
        medicGeekApi.get("/categorys"),
        medicGeekApi.get("/pays"),
        medicGeekApi.get("/cie10s"),
      ]);
  
      // Axios automáticamente parsea la respuesta JSON
      const consultAllData = consultAllResponse.data;
      const externalCauseData = externalCauseResponse.data;
      const categoryData = categoryResponse.data;
      const paysData = paysResponse.data;
      const cie10DataResponse = cie10Response.data;
  
      // Ultimo pago
      const ultimoPay = paysData.length + 1;
      // Consultar la última consulta de todas y sumarle 1
      const ultimaConsultaAll = consultAllData.length + 1;
  
      // Establecer estados correspondientes
      setCie10Data(cie10DataResponse);
      setExternalCauses(externalCauseData);
      setCategoryData(categoryData);
      setUltimoPay(ultimoPay);
      setUltimaConsultaAll(ultimaConsultaAll);
  
      // Llamadas a los endpoints relacionados con el paciente
      if (!paciente || !paciente.pcteId) {
        console.error("Error: El paciente no tiene un ID definido.");
        return;
      }
  
      const [
        consultPcteResponse,
        tappctesResponse,
        analysisDataResponse,
        citasDataResponse,
      ] = await Promise.all([
        medicGeekApi.get(`/consults/by-paciente/${paciente.pcteId}`),
        medicGeekApi.get(`/tappctes/by-paciente/${paciente.pcteId}`),
        medicGeekApi.get(`/analysis/by-paciente/${paciente.pcteId}`),
        medicGeekApi.get(`/calendars/detail`),
      ]);
  
      // Acceder a los datos directamente desde las respuestas
      const consultData = consultPcteResponse.data;
      const tappctesData = tappctesResponse.data;
      const analysisData = analysisDataResponse.data;
      const citasData = citasDataResponse.data;
  
      
      const citasFiltradas = citasData.filter(
        (cita) => cita.pcteId === paciente.pcteId && cita.estatus === "Activo"
      );
  
      if (parentDivRef.current) {
        setParentDivHeight(`${parentDivRef.current.scrollHeight}px`);
      }
  
      // Procesar datos relacionados con el paciente
      if (consultData.length > 0 && tappctesData.length > 0) {
        const ultimaConsulta = consultData[consultData.length - 1];
        const ultimoTappctes = tappctesData[tappctesData.length - 1];
  
        // Establecer estados relacionados con los datos del paciente
        setConsultas(consultData);
        setUltimaConsulta(ultimaConsulta);
        setAnalysisData(analysisData);
        setCitasFiltradas(citasFiltradas);
        setConsultMotOld(ultimaConsulta.consultMot);
        setConsultClaDiag(ultimaConsulta.consultClaDiag);
        setConsultTipDiag(ultimaConsulta.consultTipDiag);
        setConsultDiag(ultimaConsulta.consultDiag);
        setCauseId(ultimaConsulta.causeId || "");
        setCategoryId(ultimaConsulta.categoryId || "");
        setFechaActual(fechaActualFormateada);
  
        // Establecer estados relacionados con los antecedentes patológicos del paciente
        setTappctesData({
          checke1: ultimoTappctes.checke1,
          checke2: ultimoTappctes.checke2,
          checke3: ultimoTappctes.checke3,
          checke4: ultimoTappctes.checke4,
          checke5: ultimoTappctes.checke5,
          checke6: ultimoTappctes.checke6,
          checke7: ultimoTappctes.checke7,
          checke8: ultimoTappctes.checke8,
          checke9: ultimoTappctes.checke9,
          checke10: ultimoTappctes.checke10,
          checke11: ultimoTappctes.checke11,
          checke12: ultimoTappctes.checke12,
          checke13: ultimoTappctes.checke13,
          checke14: ultimoTappctes.checke14,
          checke15: ultimoTappctes.checke15,
          antOtros: ultimoTappctes.antOtros,
          mquirur: ultimoTappctes.mquirur,
          malergi: ultimoTappctes.malergi,
          mtrauma: ultimoTappctes.mtrauma,
          mtransf: ultimoTappctes.mtransf,
          mmadre: ultimoTappctes.mmadre,
          mpadre: ultimoTappctes.mpadre,
          mconyu: ultimoTappctes.mconyu,
          motros: ultimoTappctes.motros,
          antFarmacos: ultimoTappctes.antFarmacos,
          toxicChecke1: ultimoTappctes.toxicChecke1,
          toxicChecke2: ultimoTappctes.toxicChecke2,
          toxicChecke3: ultimoTappctes.toxicChecke3,
          toxicOtros: ultimoTappctes.toxicOtros,
        });
      } else {
        console.error(
          "No hay consultas o antecedentes patológicos disponibles."
        );
      }
    } catch (error) {
      console.error("Error al recuperar las consultas:", error.message);
    }
  };
  
  

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleTabLab = (tab) => {
    if (activeTabLab !== tab) setActiveTabLab(tab);
  };

  const obtenerFechaActual = () => {
    const fechaActual = new Date();
    const dia = String(fechaActual.getDate()).padStart(2, "0");
    const mes = String(fechaActual.getMonth() + 1).padStart(2, "0"); // El mes se cuenta desde 0, por eso se suma 1
    const anio = fechaActual.getFullYear();
    return `${dia}/${mes}/${anio}`;
  };
  const fechaActualFormateada = obtenerFechaActual();

  function formatearFecha(fecha) {
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const año = fecha.getFullYear();
    return `${dia}/${mes}/${año}`;
  }

  function formatearHora(fecha) {
    const ampm = fecha.getHours() >= 12 ? "PM" : "AM";
    let horas = fecha.getHours() % 12;
    horas = horas === 0 ? 12 : horas;
    const minutos = fecha.getMinutes();
    const horaFormateada = `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")} ${ampm}`;
    return horaFormateada;
  }

  function formatearFechaCita(fecha) {
    const dia = (fecha.getDate() + 1).toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Ajusta el mes sumando 1
    const año = fecha.getFullYear();
    return `${dia}/${mes}/${año}`;
  }

  function formatearHora12Horas(horaFormateada) {
    const [hora, minutos] = horaFormateada.split(":");
    const horaNumero = parseInt(hora);

    // Función auxiliar para agregar un cero delante de números menores que 10
    const agregarCero = (numero) => (numero < 10 ? `0${numero}` : `${numero}`);

    if (horaNumero === 0) {
      return `12:${minutos} AM`;
    } else if (horaNumero < 12) {
      return `${agregarCero(horaNumero)}:${minutos} AM`;
    } else if (horaNumero === 12) {
      return `12:${minutos} PM`;
    } else {
      return `${agregarCero(horaNumero - 12)}:${minutos} PM`;
    }
  }

  // Función para formatear la fecha y hora en el formato deseado
  function formatearFechaHora(fecha) {
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();

    // Formatea la hora con AM/PM
    const horaFormateada = formatearHora(fecha);

    return `${dia}/${mes}/${año} ${horaFormateada}`;
  }

  // Contador de caractereres
  const handleChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxChars1) {
      setConsultMot(text);
      setConsultHea(text);
      setEstGabinete(text);
      setConsultDiag(text);
      setResultLab(text);
      setRecetaControlada(text);
    }
    if (text.length <= maxChars2) {
      setAntOtros(text);
      setAntFarmacos(text);
      setNotaCita(text);
    }
    if (text.length <= maxChars4) {
      setRecetaControlada(text);
    }
  };

  // Sixe
  const handleToggleSize = () => {
    setExpanded(!expanded);
  };

  const handleEditarConsulta = () => {
    setIsEditingConsulta(true);
    // Puedes habilitar la edición de otros campos si es necesario
  };

  const toggleHistorico = () => {
    setHistoricoVisible(!historicoVisible);
  };

  // Nueva función para manejar el cambio de página
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleAccordionToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const displayedConsultas = consultas.slice(
    pageNumber * consultasPerPage,
    (pageNumber + 1) * consultasPerPage
  );

  // Modales
  // Modal para la Cie-10
  const openModalCie10 = async () => {
    try {
      const response = await medicGeekApi.get("/cie10s");
      setCie10Data(response.data);
      setIsModalCie10Open(true);
    } catch (error) {
      console.error("Error al cargar datos desde el endpoint:", error);
    }
  };

  const handleCIE10Selection = (selectedItem) => {
    setDescriptionCie10(selectedItem.description);
    setCodeCie10(selectedItem.code);
    setIsModalCie10Open(false);
  };

  const getCategoryName = (categoryId, categoryData) => {
    const categoriaSeleccionada = categoryData.find(
      (item) => item.categoryId === categoryId
    );
    return categoriaSeleccionada
      ? categoriaSeleccionada.categoryNm
      : "Categoría no encontrada";
  };

  // Mostrar antecedentes
  const tieneAntecedentes = () => {
    const antecedentes = [
      tappctesData.checke1,
      tappctesData.checke2,
      tappctesData.checke3,
      tappctesData.checke4,
      tappctesData.checke5,
      tappctesData.checke6,
      tappctesData.checke7,
      tappctesData.checke8,
      tappctesData.checke9,
      tappctesData.checke10,
      tappctesData.checke11,
      tappctesData.checke12,
      tappctesData.checke13,
      tappctesData.checke14,
      tappctesData.checke15,
    ];

    return antecedentes.some((antecedente) => antecedente);
  };

  const handleAnalysisChange = (fieldName, value) => {
    setAnalysisValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  // Agregar Receta Medica
  const handleAddMedicamento = () => {
    if (descripcionMedicamento.trim() !== "" && dosis.trim() !== "") {
      const nuevoMedicamento = {
        medicamento: descripcionMedicamento,
        dosis: dosis,
        total: total,
      };

      setMedicamentos([...medicamentos, nuevoMedicamento]);
      setDescripcionMedicamento("");
      setDosis("");
      setTotal("");
    }
  };
  // eliminar medicamentos del listado
  const handleDeleteMedicamento = (index) => {
    const nuevosMedicamentos = [...medicamentos];
    nuevosMedicamentos.splice(index, 1);
    setMedicamentos(nuevosMedicamentos);
  };
  // Buscar medicamentos
  const handleBuscarMedicamentos = async () => {
    try {
      // Realizar la solicitud utilizando medicGeekApi
      const response = await medicGeekApi.get('/medicamentos');
      
      // Verificar si la respuesta es exitosa
      if (!response || !response.data) {
        throw new Error('Error al obtener medicamentos');
      }
  
      // Obtener los datos de la respuesta
      const data = response.data;
  
      // Actualizar los resultados de la búsqueda de medicamentos
      setMedicamentosSearchResults(data);
      setIsMedicamentosModalOpen(true);
    } catch (error) {
      console.error('Error fetching medicamentos:', error.message);
    }
  };
  
  // Seleccionar medicamento y cargarlo
  const handleSelectMedicamento = (medicamento) => {
    setDescripcionMedicamento(medicamento.producNc);
    setDosis(medicamento.producDs);

    setIsMedicamentosModalOpen(false);
  };

  // Paginacion
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const ellipsis = "...";

    for (let i = 0; i < pageCountConsult; i++) {
      // Mostrar solo 5 páginas alrededor de la página actual
      if (
        i === 0 ||
        i === pageCountConsult - 1 ||
        (i >= currentPage - 2 && i <= currentPage + 2)
      ) {
        pageNumbers.push(
          <a
            key={i}
            href="!#"
            onClick={() => handlePageChange({ selected: i })}
            className={"page-link"}
          >
            {i + 1}
          </a>
        );
      } else if (i === currentPage - 3 || i === currentPage + 3) {
        // Mostrar (...) antes y después de las páginas ocultas
        pageNumbers.push(
          <span key={i} className={"page-link"}>
            {ellipsis}
          </span>
        );
      }
    }

    return pageNumbers;
  };

  const handleFechaCitaChange = (e) => {
    setFechaCita(e.target.value);
  };

  const handleHoraCitaChange = (e) => {
    setHoraCita(e.target.value);
  };

  const handleNotaCitaChange = (e) => {
    setNotaCita(e.target.value);
  };

  // Altura Receta
  const handleSwitchChange = () => {
    setIsAccordionCitaOpen(!isAccordionCitaOpen);
    setAlturaContenedorCita(isAccordionCitaOpen ? "10rem" : "auto");
    setIsSwift(!isAccordionCitaOpen);
  };

  const handleChangeCantidadPago = (e) => {
    // Obtener el valor introducido en el campo
    let valorIntroducido = e.target.value;

    // Eliminar las comas antes de convertir en número
    valorIntroducido = valorIntroducido.replace(/,/g, "");

    // Verificar si el valor es un número válido
    if (!isNaN(valorIntroducido)) {
      // Aplicar formato de comas para separar los miles
      valorIntroducido = formatoConComas(valorIntroducido);
    } else {
      // Si no es un número válido, establecer el valor predeterminado como 0
      valorIntroducido = "0";
    }

    // Actualizar el estado con el valor formateado
    setCantidadPago(valorIntroducido);
  };
  // Función para aplicar formato de comas para separar los miles
  const formatoConComas = (valor) => {
    if (valor.includes(".")) {
      const partes = valor.split(".");
      partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return partes.join(".");
    } else {
      return valor.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const handleChangeDescripcionPago = (e) => {
    setDescripcionPago(e.target.value);
  };

  const SaveConsult = async (e) => {
    e.preventDefault();
    try {
      if (paciente && paciente.pcteId) {
        if (!consultMot.trim() || !consultHea.trim()) {
          showErrorAlert(
            "Algunos campos son obligatorios. Por favor, complete la información."
          );
          return;
        }

        const confirmResult = await showConfirmationAlert(
          "Confirmación",
          "¿Está seguro de que desea guardar la consulta?"
        );

        if (confirmResult.isConfirmed) {
          setCamposCompletos(true); // Marcar los campos como completos
          setOpenModalSaveConsult(true); // Abre el modal
        }
      } else {
        console.error(
          "No se puede guardar la consulta sin fecha o ID de paciente"
        );
        showErrorAlert(
          "No se puede guardar la consulta sin fecha o ID de paciente"
        );
      }
    } catch (error) {
      console.error("Error al guardar la consulta:", error);
      showErrorAlert("Hubo un error al guardar la consulta");
    }
  };

// Guardar Consulta
  const saveConsultModal = async (e) => {
    e.preventDefault();
    let consultId;
    let tappctesId;

    const fechaFormateada = formatearFecha(new Date());
    const horaFormateada = formatearHora(new Date());

    // Guardando la Consulta
    const consultaData = {
      pcteId: paciente.pcteId,
      consultFec: `${fechaFormateada} ${horaFormateada}`,
      estatus: "Completada",
      codeCie10,
      causeId,
      categoryId,
      consultMot,
      consultHea,
      exam1,
      exam2,
      exam3,
      exam4,
      exam5,
      exam6,
      exam7,
      exam8,
      exam9,
      exam10,
      exam11,
      exam12,
      exam13,
      exam14,
      exam15,
      exam16,
      exam17,
      exam18,
      examComentario,
      estGabinete,
      consultDiag,
      consultTipDiag,
      consultClaDiag,
      resultLab,
    };

    const responseConsulta = isEditing
      ? await medicGeekApi.put(`/consults/${consultId}`, consultaData)
      : await medicGeekApi.post("/consults", consultaData);

    consultId = responseConsulta.data.consultId;

    if (responseConsulta.status === 400) {
      console.error(
        "Error al guardar/actualizar la consulta:",
        responseConsulta.statusText
      );
      showErrorAlert("Hubo un error al guardar/actualizar la consulta");
      return;
    }

    // Guardando los datos patologicos
    const antPatologicosData = {
      pcteId: paciente.pcteId,
      consultId: consultId,
    };

    const camposACopiar = [
      "checke1",
      "checke2",
      "checke3",
      "checke4",
      "checke5",
      "checke6",
      "checke7",
      "checke8",
      "checke9",
      "checke10",
      "checke11",
      "checke12",
      "checke13",
      "checke14",
      "checke15",
      "toxicChecke1",
      "toxicChecke2",
      "toxicChecke3",
      "toxicOtros",
      "antOtros",
      "antFarmacos",
      "mquirur",
      "mtrauma",
      "malergi",
      "mtransf",
      "mmadre",
      "mpadre",
      "mconyu",
      "motros",
    ];

    for (const campo of camposACopiar) {
      antPatologicosData[campo] = tappctesData[campo];
    }

    const responseAntPat = isEditing
      ? await medicGeekApi.put(`/tappctes/${tappctesId}`, antPatologicosData)
      : await medicGeekApi.post("/tappctes", antPatologicosData);

    // console.log('Los datos de patologia',antPatologicosData)

    if (responseAntPat.status === 400) {
      console.error(
        "Error al guardar/actualizar los antecedentes patológicos:",
        responseAntPat.statusText
      );
      showErrorAlert(
        "Hubo un error al guardar/actualizar los antecedentes patológicos"
      );
      return;
    }
    // Termina de guardar la patologia

    
    // Después de guardar la receta
    if (medicamentos.length > 0) {
      for (const medicamento of medicamentos) {
        // Comprobar si hay medicamento existente con el mismo nombre exacto
        const medicamentoExistenteResponse = await medicGeekApi.get(
          `/medicamentos?producNc=${encodeURIComponent(
            medicamento.medicamento
          )}`
        );
        const medicamentoExistenteData = medicamentoExistenteResponse.data;

        if (
          medicamentoExistenteData.some(
            (existingMedicamento) =>
              existingMedicamento.producNc.trim().toLowerCase() ===
              medicamento.medicamento.trim().toLowerCase()
          )
        ) {
          // console.log(`El medicamento "${medicamento.medicamento}" existe, se actualizará`);
          // El medicamento existe, verificar si hay cambios

          const medicamentoExistente = medicamentoExistenteData[0];

          if (medicamentoExistente.producDs !== medicamento.dosis) {
            // Hay cambios en producNc o producDs, actualizar
            try {
              await medicGeekApi.patch(
                `/medicamentos/${medicamentoExistente.producId}`,
                {
                  producDs: medicamento.dosis,
                }
              );

              // console.log(`Se actualizó el medicamento ${medicamento.medicamento}. Nueva dosis: ${medicamento.dosis}`);
            } catch (error) {
              console.error("Error al actualizar el medicamento:", error);
            }
          }
        } else {
          console.log(
            `Se está insertando un nuevo medicamento: ${medicamento.medicamento} `
          );
          // El medicamento no existe, insertar uno nuevo
          const nuevoMedicamento = {
            producNm: medicamento.medicamento,
            producNc: medicamento.medicamento,
            producDs: medicamento.dosis,
            estatus: "Activo",
          };
          try {
            const responseRecetaNueva = await medicGeekApi.post(
              "/medicamentos",
              nuevoMedicamento,
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              }
            );

            console.log("El nuevo medicamento es;", nuevoMedicamento);

            if (responseRecetaNueva.status === 200 || responseRecetaNueva.status === 201) {
              // console.log('Nuevo Medicamento guardada exitosamente');
            } else {
              console.error(
                "Error al guardar el Medicamento:",
                responseRecetaNueva.statusText
              );
              showErrorAlert("Hubo un error al guardar el Medicamento");
            }

            console.log(
              `Se insertó un nuevo medicamento ${medicamento.medicamento} con dosis: ${medicamento.dosis}`
            );
          } catch (error) {
            console.error("Error al insertar un nuevo medicamento:", error);
          }
        }
      }

      // Después de la lógica de medicamentos, sigue con la lógica de recetas
      for (const medicamento of medicamentos) {
        const recetaData = {
          consultId: consultId, // Asignar el mismo consultId a cada medicamento
          medicNm: medicamento.medicamento || "",
          medicDosis: medicamento.dosis || "",
          medicTotal: medicamento.total || "",
        };

        try {
          const responseReceta = await medicGeekApi.post(
            "/recetas",
            recetaData,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );

          if (responseReceta.status === 200 || responseReceta.status === 201 ) {
            // console.log('Receta guardada exitosamente');
          } else {
            console.error(
              "Error al guardar la receta:",
              responseReceta.statusText
            );
            showErrorAlert("Hubo un error al guardar la receta");
          }
        } catch (error) {
          console.error("Error al guardar la receta:", error);
          showErrorAlert("Hubo un error al guardar la receta");
        }
      }
    } else {
      // console.log('No hay medicamentos para procesar. El código relacionado con medicamentos y recetas no se ejecutará.');
    }

    // Registrar la cita
    if (isSwift) {
      const fechaActual = new Date();
      const fechaCitaReg = formatearFechaHora(fechaActual);

      const nuevaCita = {
        pcteId: paciente.pcteId,
        categoryId: categoryCitaId,
        fechaCitaReg: fechaCitaReg,
        horaCita: formatearHora12Horas(horaCita),
        fechaCita: `${formatearFechaCita(
          new Date(fechaCita)
        )} ${formatearHora12Horas(horaCita)}`,
        notaCita: notaCita,
        estatus: "Activo",
      };

      try {
        const responseCita = await medicGeekApi.post("/calendars", nuevaCita);

        if (responseCita.status === 200 || responseCita.status === 201) {
          showSuccessAlert("Cita creada con éxito.", 3000);
        } else {
          console.error("Error al crear la cita:", responseCita.statusText);
          showErrorAlert("Hubo un error al crear la cita");
        }
      } catch (error) {
        console.error("Error al crear la cita:", error);
        showErrorAlert("Hubo un error al crear la cita");
      }
    } else {
      // console.log('La opción Swift no está habilitada. No se registrará la cita.');
    }

    // Actualizar el estado de activo a completado en calendario
    if (paciente.estatus || paciente.estatusCalendar === "Activo") {
      const updateStatusData = {
        estatus: "Completada",
      };

      const calendarId = paciente.calendarId;

      if (calendarId) {
        try {
          const responseUpdateStatus = await medicGeekApi.patch(
            `/calendars/${calendarId}`,
            updateStatusData
          );

          if (responseUpdateStatus.status === 200 || responseUpdateStatus.status === 201 ) {
            console.log('Estado actualizado a "Completada" en el calendario');
            showSuccessAlert(
              'Estado actualizado a "Completada" en el calendario'
            );
          } else {
            console.error(
              "Error al actualizar el estado en el calendario:",
              responseUpdateStatus.statusText
            );
            showErrorAlert(
              "Hubo un error al actualizar el estado en el calendario"
            );
          }

          console.log(
            "Respuesta de la actualización del estado:",
            responseUpdateStatus
          ); // Agregar este console.log
        } catch (error) {
          console.error(
            "Error al actualizar el estado en el calendario:",
            error
          );
          showErrorAlert(
            "Hubo un error al actualizar el estado en el calendario"
          );
        }
      } else {
        console.error("No se pudo obtener el calendarId desde paciente");
        showErrorAlert("No se pudo obtener el ID del calendario");
      }
    }

    // Registrar pago
    const pagoData = {
      payCt:
        cantidadPago.trim() !== ""
          ? parseFloat(cantidadPago.replace(/,/g, ""))
          : 0,
      pcteId: paciente.pcteId,
      consultId: consultId,
      payFec: `${fechaFormateada} ${horaFormateada}`,
      payDesc: descripcionPago,
    };

    try {
      const responsePago = await medicGeekApi.post("/pays", pagoData);

      if (responsePago.status === 200 || responsePago.status === 201) {
        
      } else {
        console.error("Error al registrar el pago:", responsePago.statusText);
        showErrorAlert("Hubo un error al registrar el pago");
      }
    } catch (error) {
      console.error("Error al registrar el pago:", error);
      showErrorAlert("Hubo un error al registrar el pago");
    }

    showSuccessAlert("La Consulta fue Guardada Correctamente");
    onCloseForm();
    history("/patients");
  };

  // Guardar los Análisis
  const saveAnalysis = async () => {
    const fechaFormateada = formatearFecha(new Date());
    const horaFormateada = formatearHora(new Date());

    try {
      // Datos de los Análisis
      const analysisData = {
        pcteId: paciente.pcteId,
        // consultId: consultId,
        analysiFec: `${fechaFormateada} ${horaFormateada}`,
      };

      // Nombres de los campos de análisis
      const analysisFields = Array.from(
        { length: 43 },
        (_, i) => `analysis${i + 1}`
      );

      // Generar las propiedades para los campos de análisis
      analysisFields.forEach((fieldName) => {
        analysisData[fieldName] = analysisValues[fieldName];
      });

      // Enviar los análisis al endpoint correcto
      const responseAnalisis = await medicGeekApi.post(
        "/analysis",
        analysisData
      );

      // console.log('Datos del análisis:', analysisData);

      if (responseAnalisis.status === 400) {
        console.error(
          "Error al guardar los análisis:",
          responseAnalisis.statusText
        );
        showErrorAlert("Hubo un error al guardar los análisis");
        return;
      }

      showSuccessAlert("Los Análisis fueron Guardados Correctamente");

      // Objeto con valores iniciales
      const initialAnalysisValues = Object.fromEntries(
        analysisFields.map((fieldName) => [fieldName, ""]) // Puedes inicializar con los valores que desees
      );

      // Reiniciar el estado de analysisValues a su estado inicial
      setAnalysisValues(initialAnalysisValues);

      // Cerrar el modal
      setOpenModalResultadoLab(false);
    } catch (error) {
      console.error("Error al guardar los análisis:", error);
      showErrorAlert("Hubo un error al guardar los análisis");
    }
  };

  const CancelConsult = () => {
    onCloseForm();
    history("/patients");
  };

  const formatearFechaCitaReport = (fecha) => {
    const opciones = { day: "numeric", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("es-ES", opciones).format(fecha);
  };

  const convertirFechaLarga = (fechaStr, horaStr) => {
    const opcionesFecha = { day: "numeric", month: "long", year: "numeric" };
    const opcionesHora = { hour: "numeric", minute: "numeric", hour12: true };

    if (!fechaStr || !horaStr) {
      return ""; // Retorna una cadena vacía si no hay fecha u hora
    }

    // Dividir la fecha y hora correctamente
    const [year, month, day] = fechaStr.split("-");
    const [hour, minute] = horaStr.split(":");

    // Crear una instancia de Date
    const fecha = new Date(year, month - 1, day, hour, minute);

    // Verificar si la fecha es válida
    if (isNaN(fecha.getTime())) {
      return "Fecha inválida"; // Manejar el error de fecha inválida
    }

    // Formatear la fecha
    const fechaLarga = new Intl.DateTimeFormat("es-ES", opcionesFecha).format(
      fecha
    );

    // Formatear la hora en formato AM/PM
    const horaLarga = new Intl.DateTimeFormat("es-ES", opcionesHora).format(
      fecha
    );

    const partesFecha = fechaLarga.split(" de ");
    const dia = partesFecha[0];
    const mes = partesFecha[1];
    const año = partesFecha[2];

    return `día ${dia} del mes de ${mes} del año ${año} a las ${horaLarga}`;
  };

  const fechaStr = "6/6/2024 9:44 AM";
  const fechaCitaLarga = convertirFechaLarga(fechaStr);

  const previsualizarPDF = () => {
    const doc = new jsPDF();
    const fechaFormateada = formatearFechaCitaReport(new Date());

    let fechaCitaLarga = "";
    if (isAccordionCitaOpen && fechaCita && horaCita) {
      fechaCitaLarga = convertirFechaLarga(fechaCita, horaCita);
    }

    if (!medicamentos || medicamentos.length === 0) {
      showErrorAlert("No hay datos de receta seleccionada");
      return;
    }

    const contenido = `
      <div style="
        margin: 0; 
        padding-top: 40px;
        width: 200px;
        height: 3000px,
        text-align: center;
        font-family: Arial, sans-serif; 
        margin: 0.3rem;
        font-size: 0.3rem;
      ">
        <div 
          style="
            width: 200px;
            text-align: start;
            padding-top: 0.02rem;
            display: flex;
            flex-direction: column;
            font-weight: bold;
            font-size: 4px;
          "
        >
          <label>Paciente: ${paciente.pcteNom} ${paciente.pcteNom2} ${
      paciente.pcteApe1
    } ${paciente.pcteApe2}</label> 
          <label>Cédula: ${paciente.pcteNumDoc}</label> 
          <label>Fecha: ${fechaFormateada}</label> 
        </div>
        
        <div style="
          text-align: start;
          width: 200px;
          height: 60%;
          font-size: 4px;
          text-decoration: underline;
          margin-top: 10px;
        ">
        ${
          Array.isArray(medicamentos) && medicamentos.length > 0
            ? medicamentos
                .map(
                  (medicamento, index) =>
                    `<div style="
                display: flex;
                flex-direction: column;
                padding: 0.1rem;
            ">
              <label 
                style="
                  font-weight: bold;
              ">${index + 1} - ${medicamento.medicamento}</label>
              <label>${medicamento.dosis}</label>
            </div>`
                )
                .join("")
            : `<p>No hay datos disponibles</p>`
        }
      </div>
  
        <div style="
          text-align: start;
          width: 200px;
          font-size: 0.2rem;
          height: 50px;
        ">
        <label>Próxima Cita: ${
          fechaCitaLarga ? `${fechaCitaLarga}` : "No hay próxima cita asignada"
        }</label>
        </div>
      </div>
    `;

    doc.html(contenido, {
      callback: function (doc) {
        window.open(doc.output("bloburl"), "_blank");
      },
    });
  };

  const previsualizarPDFRecetaControlada = () => {
    const doc = new jsPDF();
    const fechaFormateada = formatearFechaCitaReport(new Date());

    console.log("Listado de medicamentos:", medicamentos);
    console.log("cita:", fechaCitaLarga);

    const contenido = `
      <div style="
        margin: 0; 
        padding-top: 40px;
        width: 200px;
        height: 3000px,
        text-align: center;
        font-family: Arial, sans-serif; 
        margin: 0.3rem;
        font-size: 0.3rem;
      ">
        <div 
          style="
            width: 200px;
            text-align: start;
            padding-top: 0.02rem;
            display: flex;
            flex-direction: column;
            font-weight: bold;
            font-size: 4px;
          "
        >
          <label>Paciente: ${paciente.pcteNom} ${paciente.pcteNom2} ${paciente.pcteApe1} ${paciente.pcteApe2}</label> 
          <label>Cédula: ${paciente.pcteNumDoc}</label> 
          <label>Fecha: ${fechaFormateada}</label> 
        </div>
        
        <div style="
          text-align: start;
          width: 200px;
          height: 60%;
          font-size: 4px;
          margin-top: 10px;
        ">
        ${recetaControlada}
      </div>
  
        <div style="
          text-align: start;
          width: 200px;
          font-size: 0.2rem;
          height: 50px;
        ">
        
        </div>
      </div>
    `;

    doc.html(contenido, {
      callback: function (doc) {
        window.open(doc.output("bloburl"), "_blank");
      },
    });
  };

  const calculateIMC = () => {
    if (exam5 && exam6) {
      const pesoKg = parseFloat(exam6) * 0.45359237; // Convertir peso a kilogramos
      const alturaM = parseFloat(exam5) / 100; // Convertir altura a metros
      return (pesoKg / (alturaM * alturaM)).toFixed(0); // Calcular IMC y redondear a 2 decimales
    }
    return "";
  };

  const exam7 = calculateIMC();

  return (
    <div className="consulta-form">
      <div class="sticky-top pt-4 border-bottom miDivConGradiente text-white px-2 rounded-bottom pb-2">
        <div className="d-flex justify-content-start">
          <div
            className="d-flex align-items-center"
            style={{
              width: "4rem",
            }}
          >
            <img
              alt="Sample"
              src="https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png"
              style={{
                width: "100%",
                // marginTop:'1rem'
              }}
            />
          </div>
          <div className="input-group mx-2 input-group-sm text-uppercase fw-bold d-flex align-items-center">
            <label className="border-0 fs-4" id="pcteNom">
              {paciente.pcteNom} {paciente.pcteNom2} {paciente.pcteApe1}{" "}
              {paciente.pcteApe2}
            </label>
          </div>
        </div>
        <div class="d-flex bd-highlight mb-3">
          <div className="d-flex justify-content-start">
            <label className="border-0 fs-4 m-1" id="pcteEd">
              {paciente.pcteEd} <div class="vr"></div>
            </label>
            <label className="border-0 fs-4 m-1" id="pcteNumDoc">
              {paciente.pcteNumDoc} <div class="vr"></div>
            </label>
            <label className="border-0 fs-4 m-1" id="pcteSx">
              {paciente.pcteSx}
            </label>
          </div>

          <div
            className="ms-auto cursor-pointer d-flex justify-content-end"
            style={{
              margin: "0 0",
              width: "36rem",
              height: "2rem",
            }}
          >
            <button
              className="fs-6 text-uppercase fw-bold btn button-access"
              placeholder="Historico"
              onClick={toggleHistorico}
            >
              {historicoVisible ? (
                <>
                  <i className="bi bi-x-lg px-1"></i>
                  Resumen clínico
                </>
              ) : (
                <>
                  <i className="bi bi-arrow-bar-left px-1"></i>
                  Resumen clínico
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="px-3 pb-5 pt-2 d-flex">
        {/* Consulta */}
        <div
          className="mx-2"
          style={{
            width: "100%",
          }}
        >
          <Row>
            <Form
              d-flex
              justify-content-end
              onSubmit={SaveConsult}
              className="bg-white pt-2 rounded-2"
            >
              <div
                className={`mb-5 `}
                style={{ height: expanded ? "30rem" : "15rem" }}
              >
                <div
                  className={`row bg-light mb-1 mx-1 border border-light px-2 pt-2 rounded col ${
                    expanded ? "transicionWipeButton" : ""
                  }`}
                  style={{
                    height: "90%",
                  }}
                >
                  <div className="col-6">
                    <Form>
                      <div
                        className="d-flex flex-column align-items-start py-2"
                        style={{
                          height: "7rem",
                        }}
                      >
                        <div className=" fw-bold fs-6">
                          <label className="text-decoration-underline">
                            {" "}
                            Antecedentes Patológicos
                          </label>
                        </div>

                        <div className="">
                          {tieneAntecedentes() ? (
                            // Mostrar antecedentes como tags
                            <div className="d-flex flex-wrap align-items-center gap-1 py-1">
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke1 ? "d-flex" : "d-none"
                                }`}
                              >
                                Cardiopatias
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke2 ? "d-flex" : "d-none"
                                }`}
                              >
                                Diabletes
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke3 ? "d-flex" : "d-none"
                                }`}
                              >
                                Enfermedades Infecciosas
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke4 ? "d-flex" : "d-none"
                                }`}
                              >
                                HTA
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke5 ? "d-flex" : "d-none"
                                }`}
                              >
                                Infertilidad
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke6 ? "d-flex" : "d-none"
                                }`}
                              >
                                Edampsia
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke7 ? "d-flex" : "d-none"
                                }`}
                              >
                                Alergias
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke8 ? "d-flex" : "d-none"
                                }`}
                              >
                                Asma Bronquial
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke9 ? "d-flex" : "d-none"
                                }`}
                              >
                                Cirugias
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke10 ? "d-flex" : "d-none"
                                }`}
                              >
                                Ulceras
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke11 ? "d-flex" : "d-none"
                                }`}
                              >
                                Epilepsia
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke12 ? "d-flex" : "d-none"
                                }`}
                              >
                                Neoplasias
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke13 ? "d-flex" : "d-none"
                                }`}
                              >
                                Preeclamsia
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke14 ? "d-flex" : "d-none"
                                }`}
                              >
                                VIH/SIDA
                              </span>
                              <span
                                className={`badge bg-primary rounded-pill me-2 ${
                                  tappctesData.checke15 ? "d-flex" : "d-none"
                                }`}
                              >
                                Ninguno
                              </span>
                              {/* ... agregar el resto de los antecedentes */}
                            </div>
                          ) : (
                            // Mostrar mensaje si no hay antecedentes
                            <label>
                              No tiene ningun antecedente Registrado
                            </label>
                          )}
                        </div>
                      </div>

                      <div
                        className="d-flex flex-direction-column align-items-start"
                        style={{
                          height: "7rem",
                        }}
                      >
                        <div
                          className=""
                          style={{
                            width: "30%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <label className="fw-bold text-decoration-underline">
                            Seguridad social:{" "}
                          </label>
                          <label className="fw-bold">
                            Nombre del Paciente:{" "}
                          </label>
                        </div>

                        <div
                          className=""
                          style={{
                            width: "70%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <br></br>
                          <span>
                            {paciente.pcteNom} {paciente.pcteNom2}{" "}
                            {paciente.pcteApe1} {paciente.pcteApe2}{" "}
                          </span>
                        </div>
                      </div>
                    </Form>
                  </div>
                  {/* Antecedentes Toxicologicos */}

                  <div className="col-6">
                    <div
                      className="d-flex flex-column align-items-start py-2"
                      style={{
                        height: "7rem",
                      }}
                    >
                      <div className=" fw-bold fs-6">
                        <label className="text-decoration-underline">
                          {" "}
                          Antecedentes Alérgicos
                        </label>
                      </div>
                      <div className="">
                        <label>Datos de los antecedentes</label>
                      </div>
                    </div>
                  </div>
                  {/* Antecedentes Alergicos */}

                  <div
                    className={`mb-3 col-12`}
                    style={{
                      height: expanded ? "30rem" : "15rem",
                      visibility: expanded ? "visible" : "hidden",
                    }}
                  >
                    <Form>
                      <div className="text-start fw-bold fs-6 mb-3">
                        <label className="text-decoration-underline">
                          Información complementaria
                        </label>
                      </div>

                      <div className="d-flex align-items-start">
                        <div
                          className="fw-bold"
                          style={{
                            width: "15%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                            textColor: "#gris-claro",
                          }}
                        >
                          <label>Género: </label>
                          <label>País: </label>
                          <label>Provincia: </label>
                          <label>Ciudad: </label>
                        </div>

                        <div
                          className=""
                          style={{
                            width: "30%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <span>{paciente.pcteSx} </span>
                          <span>{paciente.pcteNac}</span>
                          <span>{paciente.pcteProv}</span>
                          <span>{paciente.pcteCiud}</span>
                        </div>
                      </div>
                      {/* Informacion complementaria */}
                    </Form>
                  </div>
                  {/* Información complementaria
                   */}
                </div>
                <div
                  className="col my-2"
                  style={{
                    height: "10%",
                  }}
                >
                  <button
                    className="rounded-2 border-0 bg-light text-primary bg-opacity-25"
                    type="button"
                    onClick={handleToggleSize}
                  >
                    {expanded
                      ? "Ocultar Información"
                      : "Conoce más Información del paciente"}
                    <i
                      className={`bi bi-arrow-${expanded ? "up" : "down"}`}
                    ></i>
                  </button>
                </div>
              </div>
              {/* Informacion del Paciente */}

              <div className="d-flex gap-3">
                <Col>
                  <FormGroup floating>
                    <Input
                      type="select"
                      className="form-control"
                      id="categoryId"
                      value={paciente.categoryId}
                      onChange={(e) => setCategoryId(e.target.value)}
                      placeholder="Category"
                    >
                      {categoryData.map((category) => (
                        <option
                          key={category.categoryId}
                          value={category.categoryId.toString()}
                        >
                          {category.categoryNm}
                        </option>
                      ))}
                    </Input>
                    <Label For="Category">
                      Tipo de Consulta{" "}
                      <span className="text-danger fw-bold">*</span>
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <div
                      className="border rounded-2 bg-light "
                      style={{
                        padding: "0.7rem",
                      }}
                    >
                      <Label>
                        Número de Exp -{" "}
                        <span className="fw-bold">{ultimaConsultaAll}</span>
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
              </div>
              {/* Tipo de Consulta */}

              <div>
                <Col md={12}>
                  <FormGroup floating>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="consultMot"
                      value={consultMot}
                      onChange={(e) => setConsultMot(e.target.value)}
                      placeholder="Motivo de la Consulta"
                      style={{ height: "10rem" }}
                      maxLength={maxChars1}
                    />
                    <Label For="Consulta">
                      Enfermedad actual y Motivo de la Consulta{" "}
                      <span className="text-danger fw-bold">*</span>
                    </Label>
                    <p style={{ fontSize: "12px", display: "flex" }}>
                      {maxChars1 - consultMot.length}/{maxChars1}
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="consultHea"
                      value={consultHea}
                      onChange={(e) => setConsultHea(e.target.value)}
                      placeholder="Enfermedad Actual"
                      style={{ height: "10rem" }}
                      maxLength={maxChars1}
                    />
                    <Label For="Enfermedad Actual">
                      Observaciones{" "}
                      <span className="text-danger fw-bold">*</span>
                    </Label>
                    <p style={{ fontSize: "12px", display: "flex" }}>
                      {maxChars1 - consultHea.length}/{maxChars1}
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="consultMotOld"
                      value={consultMotOld}
                      onChange={(e) => setConsultMotOld(e.target.value)}
                      placeholder="Enfermedad Anterior"
                      style={{ height: "10rem", backgroundColor: "" }}
                      disabled
                    />
                    <Label For="Consulta Anterior">Consulta Anterior</Label>
                  </FormGroup>
                </Col>
              </div>
              {/* Consultas */}

              <div>
                <Row>
                  <Form
                    d-flex
                    justify-content-end
                    onSubmit={SaveConsult}
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    <Col md={12} className="border">
                      <Nav tabs className="text-uppercase">
                        <NavItem style={{ cursor: "pointer" }}>
                          <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => toggleTab("1")}
                          >
                            Ant. Personales Patológicos
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                          <NavLink
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => toggleTab("2")}
                          >
                            Ant. No Patológicos & Heredo Familiares
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                          <NavLink
                            className={activeTab === "3" ? "active" : ""}
                            onClick={() => toggleTab("3")}
                          >
                            Toxicológicos
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                          <NavLink
                            className={activeTab === "4" ? "active" : ""}
                            onClick={() => toggleTab("4")}
                          >
                            Exámen Físico
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={activeTab}
                        style={{
                          backgroundColor: "aliceblue",
                        }}
                      >
                       <TabPane tabId="1">
                        <div className="col-12 d-flex flex-column justify-content-around text-secondary tabGb">
                          <div className="d-flex flex-column gap-3 text-secondary tabGb p-4"
                            style={{
                              minHeight: "20vh",
                              textAlign: "left",
                            }}
                          >
                            <div className="row">
                              {[
                                { id: "checke1", label: "Cardiopatías", checked: tappctesData.checke1 },
                                { id: "checke2", label: "Diabetes", checked: tappctesData.checke2 },
                                { id: "checke3", label: "Enfermedades Infecciosas", checked: tappctesData.checke3 },
                                { id: "checke4", label: "HTA", checked: tappctesData.checke4 },
                                { id: "checke5", label: "Infertilidad", checked: tappctesData.checke5 },
                                { id: "checke6", label: "Edampsia", checked: tappctesData.checke6 },
                                { id: "checke7", label: "Alergias", checked: tappctesData.checke7 },
                                { id: "checke8", label: "Asma Bronquial", checked: tappctesData.checke8 },
                                { id: "checke9", label: "Cirugías", checked: tappctesData.checke9 },
                                { id: "checke10", label: "Úlceras", checked: tappctesData.checke10 },
                                { id: "checke11", label: "Epilepsia", checked: tappctesData.checke11 },
                                { id: "checke12", label: "Neoplasias", checked: tappctesData.checke12 },
                                { id: "checke13", label: "Preeclamsia", checked: tappctesData.checke13 },
                                { id: "checke14", label: "VIH/SIDA", checked: tappctesData.checke14 },
                                { id: "checke15", label: "Ninguno", checked: tappctesData.checke15 }
                              ].map(({ id, label, checked }) => (
                                <div className="col-6" key={id}>
                                  <FormGroup switch>
                                    <Input
                                      id={id}
                                      type="checkbox"
                                      checked={checked}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          [id]: e.target.checked,
                                        })
                                      }
                                    />
                                    <Label check>{label}</Label>
                                  </FormGroup>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="px-4 d-flex flex-wrap gap-4 align-content-end text-start my-4">
                            <FormGroup className="col-md-6 col-sm-5 col-xl-5">
                              <Label for="antOtros">Otros:</Label>
                              <Input
                                type="textarea"
                                className="form-control"
                                id="antOtros"
                                value={tappctesData.antOtros}
                                onChange={(e) =>
                                  setTappctesData({
                                    ...tappctesData,
                                    antOtros: e.target.value,
                                  })
                                }
                                placeholder="Tiene algún otro antecedente?"
                                style={{
                                  height: "10rem",
                                }}
                                maxLength={maxChars3}
                              />
                              <p style={{ fontSize: "12px" }}>
                                {maxChars3 - (tappctesData.antOtros?.length || 0)}/{maxChars3}
                              </p>
                            </FormGroup>

                            <FormGroup className="col-md-6 col-sm-5 col-xl-5">
                              <Label for="antFarmacos">Fármacos:</Label>
                              <Input
                                type="textarea"
                                className="form-control"
                                id="antFarmacos"
                                value={tappctesData.antFarmacos}
                                onChange={(e) =>
                                  setTappctesData({
                                    ...tappctesData,
                                    antFarmacos: e.target.value,
                                  })
                                }
                                placeholder="Fármacos?"
                                style={{
                                  height: "10rem",
                                }}
                                maxLength={maxChars3}
                              />
                              <p style={{ fontSize: "12px" }}>
                                {maxChars3 - (tappctesData.antFarmacos?.length || 0)}/{maxChars3}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </TabPane>


                        <TabPane tabId="2">
                          <div
                            className="d-flex flex-wrap gap-1 text-secondary tabGb"
                            style={{
                              height: "20rem",
                              flexDirection: "column",
                              padding: "2rem",
                              textAlign: "left",
                            }}
                          >
                            <div
                              className="px-2"
                              style={{
                                width: "50%",
                              }}
                            >
                              <label className="fs-5 fw-bold">
                                Antecedentes Personales No Patológicos
                              </label>
                              <div className="d-flex flex-wrap justify-content-center gap-1 py-2 px-2 border rounded">
                                <Col md={4}>
                                  <FormGroup floating>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="mquirur"
                                      value={tappctesData.mquirur}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          mquirur: e.target.value,
                                        })
                                      }
                                      style={{}}
                                    />
                                    <Label For="Consulta">Quirúrgicos</Label>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup floating>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="malergi"
                                      value={tappctesData.malergi}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          malergi: e.target.value,
                                        })
                                      }
                                      style={{}}
                                    />
                                    <Label For="Consulta">Alérgicos</Label>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup floating>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="mtrauma"
                                      value={tappctesData.mtrauma}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          mtrauma: e.target.value,
                                        })
                                      }
                                      style={{}}
                                    />
                                    <Label For="Consulta">Traumáticos</Label>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup floating>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="mtransf"
                                      value={tappctesData.mtransf}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          mtransf: e.target.value,
                                        })
                                      }
                                      style={{}}
                                    />
                                    <Label For="Consulta">
                                      Transfucionales
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </div>
                            </div>
                            <div
                              className="px-2"
                              style={{
                                width: "50%",
                              }}
                            >
                              <label className="fs-5 fw-bold">
                                Antecedentes Heredo-Familiares
                              </label>
                              <div className="d-flex flex-wrap justify-content-center gap-1 py-2 px-2 border ">
                                <Col md={4}>
                                  <FormGroup floating>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="mmadre"
                                      value={tappctesData.mmadre}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          mmadre: e.target.value,
                                        })
                                      }
                                      style={{}}
                                    />
                                    <Label For="Consulta">Madre</Label>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup floating>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="mpadre"
                                      value={tappctesData.mpadre}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          mpadre: e.target.value,
                                        })
                                      }
                                      style={{}}
                                    />
                                    <Label For="Consulta">Padre</Label>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup floating>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="mconyu"
                                      value={tappctesData.mconyu}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          mconyu: e.target.value,
                                        })
                                      }
                                      style={{}}
                                    />
                                    <Label For="Consulta">Cónyuge</Label>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup floating>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="motros"
                                      value={tappctesData.motros}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          motros: e.target.value,
                                        })
                                      }
                                      style={{}}
                                    />
                                    <Label For="Consulta">Otros</Label>
                                  </FormGroup>
                                </Col>
                              </div>
                            </div>
                          </div>
                        </TabPane>

                        <TabPane tabId="3">
                          <div
                            className="d-flex flex-wrap gap-1 text-secondary tabGb"
                            style={{
                              height: "20rem",
                              flexDirection: "column",
                              padding: "2rem",
                              textAlign: "left",
                            }}
                          >
                            <div className="px-2">
                              <div className="d-flex flex-wrap justify-content-center gap-1 py-2 px-2">
                                <Col md={12}>
                                  <FormGroup switch>
                                    <Input
                                      id="toxicChecke1"
                                      type="checkbox"
                                      checked={tappctesData.toxicChecke1}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          toxicChecke1: e.target.checked,
                                        })
                                      }
                                    />{" "}
                                    <Label check>Drogas</Label>
                                  </FormGroup>
                                  <FormGroup switch>
                                    <Input
                                      id="toxicChecke2"
                                      type="checkbox"
                                      checked={tappctesData.toxicChecke2}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          toxicChecke2: e.target.checked,
                                        })
                                      }
                                    />{" "}
                                    <Label check>Tabaquismo</Label>
                                  </FormGroup>
                                  <FormGroup switch>
                                    <Input
                                      id="toxicChecke3"
                                      type="checkbox"
                                      checked={tappctesData.toxicChecke3}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          toxicChecke3: e.target.checked,
                                        })
                                      }
                                    />{" "}
                                    <Label check>Consumo Alcohol</Label>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup floating>
                                    <Input
                                      type="textarea"
                                      className="form-control"
                                      id="toxicOtros"
                                      value={tappctesData.toxicOtros}
                                      onChange={(e) =>
                                        setTappctesData({
                                          ...tappctesData,
                                          toxicOtros: e.target.value,
                                        })
                                      }
                                      placeholder="Otros"
                                      style={{
                                        height: "10rem",
                                        marginTop: "1rem",
                                      }}
                                    />
                                    <Label For="toxicOtros">
                                      Otros (Opcional)
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </div>
                            </div>
                          </div>
                        </TabPane>

                        <TabPane tabId="4">
                          <div
                            className="d-flex align-content-start flex-wrap text-secondary tabGb"
                            style={{
                              height: "20rem",
                              flexDirection: "column",
                              textAlign: "left",
                            }}
                          >
                            <div
                              className="px-2"
                              style={{
                                height: "50%",
                              }}
                            >
                              <label className="fs-5 fw-bold">
                                Examen Fisico
                              </label>
                              <div className="d-flex flex-wrap  gap-1 py-0 px-0">
                                <Col md={2}>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="exam1"
                                      value={exam1}
                                      onChange={(e) => setExam1(e.target.value)}
                                      placeholder="F.C"
                                      style={{}}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="exam2"
                                      value={exam2}
                                      onChange={(e) => setExam2(e.target.value)}
                                      placeholder="F.R"
                                      style={{}}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="exam3"
                                      value={exam3}
                                      onChange={(e) => setExam3(e.target.value)}
                                      placeholder="P.ABD"
                                      style={{}}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="exam4"
                                      value={exam4}
                                      onChange={(e) => setExam4(e.target.value)}
                                      placeholder="Temperatura"
                                      style={{}}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="exam5"
                                      value={exam5}
                                      onChange={(e) => setExam5(e.target.value)}
                                      placeholder="Talla"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam6"
                                        value={exam6}
                                        onChange={(e) =>
                                          setExam6(e.target.value)
                                        }
                                        placeholder="Peso"
                                      />
                                      <label className="ms-2 pe-3 fw-bold">
                                        Lbs.
                                      </label>
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam6kg"
                                        value={(exam6 * 0.45359237).toFixed(1)}
                                        onChange={(e) =>
                                          setExam6(e.target.value)
                                        }
                                        placeholder="Kg."
                                        disabled
                                      />
                                      <label className="ms-2 pe-3 fw-bold">
                                        Kg.
                                      </label>
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam7"
                                        value={exam7}
                                        // onChange={(e) => setExam7(e.target.value)}
                                        placeholder="IMC"
                                        disabled
                                      />
                                      <label className="ms-2 pe-3 fw-bold">
                                        Kg/M²
                                      </label>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </div>
                            </div>
                            <div
                              className="px-2"
                              style={{
                                height: "50%",
                              }}
                            >
                              <div className="d-flex">
                                <div
                                  className="d-flex flex-wrap py-0 px-0"
                                  style={{
                                    width: "70%",
                                    gap: "0.1rem",
                                  }}
                                >
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam8"
                                        value={exam8}
                                        onChange={(e) =>
                                          setExam8(e.target.value)
                                        }
                                        placeholder="Corazón"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam9"
                                        value={exam9}
                                        onChange={(e) =>
                                          setExam9(e.target.value)
                                        }
                                        placeholder="Cuello"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam10"
                                        value={exam10}
                                        onChange={(e) =>
                                          setExam10(e.target.value)
                                        }
                                        placeholder="Pulmón"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam11"
                                        value={exam11}
                                        onChange={(e) =>
                                          setExam11(e.target.value)
                                        }
                                        placeholder="M.I"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam12"
                                        value={exam12}
                                        onChange={(e) =>
                                          setExam12(e.target.value)
                                        }
                                        placeholder="T.A B Izquierdo"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam13"
                                        value={exam13}
                                        onChange={(e) =>
                                          setExam13(e.target.value)
                                        }
                                        placeholder="T.A B Derecho"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam14"
                                        value={exam14}
                                        onChange={(e) =>
                                          setExam14(e.target.value)
                                        }
                                        placeholder="TA Decúbico"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam15"
                                        value={exam15}
                                        onChange={(e) =>
                                          setExam15(e.target.value)
                                        }
                                        placeholder="Cabeza"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam16"
                                        value={exam16}
                                        onChange={(e) =>
                                          setExam16(e.target.value)
                                        }
                                        placeholder="Torax"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam17"
                                        value={exam17}
                                        onChange={(e) =>
                                          setExam17(e.target.value)
                                        }
                                        placeholder="Abdomen"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="exam18"
                                        value={exam18}
                                        onChange={(e) =>
                                          setExam18(e.target.value)
                                        }
                                        placeholder="Especuloscopia"
                                        style={{}}
                                      />
                                    </FormGroup>
                                  </Col>
                                </div>
                                <div
                                  className=""
                                  style={{
                                    width: "40%",
                                  }}
                                >
                                  <Col md={12}>
                                    <FormGroup>
                                      <Input
                                        type="textarea"
                                        className="form-control"
                                        id="examComentario"
                                        value={examComentario}
                                        onChange={(e) =>
                                          setExamComentario(e.target.value)
                                        }
                                        placeholder="Comentario"
                                        style={{
                                          width: "100%",
                                          height: "5rem",
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Form>
                </Row>
              </div>
              {/* Tabs de Patologia */}

              <div
                className="bg-light mb-3 border border-light rounded d-flex"
                style={{
                  height: "20rem",
                }}
              >
                <div
                  className="mx-2"
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="col-12 text-start">
                    <label className=" fs-5 fw-bold m-2">
                      Estudio de Gabinete
                    </label>
                  </div>

                  <div className="col-12" style={{}}>
                    <FormGroup floating>
                      <Input
                        type="textarea"
                        className="form-control"
                        id="estGabinete"
                        value={estGabinete}
                        onChange={(e) => setEstGabinete(e.target.value)}
                        placeholder="Estudio de Gabinete"
                        style={{ height: "10rem" }}
                        maxLength={maxChars2}
                      />
                      <Label For="Estudio de Gabinete">
                        Estudio de Gabinete (Opcional)
                      </Label>
                      <p style={{ fontSize: "12px", display: "flex" }}>
                        {maxChars2 - estGabinete.length}/{maxChars2}
                      </p>
                    </FormGroup>
                  </div>
                </div>
              </div>
              {/* Estudio de Gabinete */}

              <div
                className="bg-light mb-3 border border-light rounded d-flex"
                style={{
                  height: "20rem",
                }}
              >
                <div
                  className="mx-2"
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="col-12 text-start">
                    <label className=" fs-5 fw-bold m-2">
                      Resultados de laboratorio clínico
                    </label>
                  </div>

                  <div className="col-12" style={{}}>
                    <FormGroup floating>
                      <Input
                        type="textarea"
                        className="form-control"
                        id="resultLab"
                        value={resultLab}
                        onChange={(e) => setResultLab(e.target.value)}
                        placeholder="Resultado de Laboratorio"
                        style={{ height: "10rem" }}
                        maxLength={maxChars1}
                      />
                      <Label For="Estudio de Gabinete">
                        Comentario del resultado (Opcional)
                      </Label>
                      <p style={{ fontSize: "12px", display: "flex" }}>
                        {maxChars1 - resultLab.length}/{maxChars1}
                      </p>
                    </FormGroup>
                  </div>

                  <div className="col-12">
                    <svg
                      width="5rem"
                      height="5rem"
                      viewBox="0 0 1024 1024"
                      class="icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M416 425.6l78.4 44.8c9.6 6.4 16 16 16 27.2v91.2c0 11.2-6.4 22.4-16 27.2L416 662.4c-9.6 6.4-22.4 6.4-32 0l-78.4-44.8c-9.6-6.4-16-16-16-27.2v-91.2c0-11.2 6.4-22.4 16-27.2l78.4-44.8c9.6-8 22.4-8 32-1.6z"
                        fill="#2F4BFF"
                      />
                      <path
                        d="M643.2 267.2c-3.2-1.6-4.8-1.6-8 0l-67.2 38.4c-3.2 1.6-3.2 4.8-3.2 6.4v76.8c0 3.2 1.6 4.8 3.2 6.4l67.2 38.4c3.2 1.6 4.8 1.6 8 0l67.2-38.4c3.2-1.6 3.2-4.8 3.2-6.4v-76.8c0-3.2-1.6-4.8-3.2-6.4l-67.2-38.4z m9.6-12.8l67.2 38.4c8 4.8 12.8 12.8 12.8 20.8v76.8c0 8-4.8 16-12.8 20.8l-67.2 38.4c-8 4.8-16 4.8-24 0l-67.2-38.4c-8-4.8-12.8-12.8-12.8-20.8v-76.8c0-8 4.8-16 12.8-20.8l67.2-38.4c6.4-4.8 16-4.8 24 0zM688 691.2l-67.2 38.4v76.8l67.2 38.4 67.2-38.4v-76.8L688 691.2z m83.2 9.6c9.6 6.4 16 16 16 27.2v76.8c0 11.2-6.4 22.4-16 27.2L704 873.6c-9.6 6.4-22.4 6.4-32 0l-67.2-38.4c-9.6-6.4-16-16-16-27.2v-76.8c0-11.2 6.4-22.4 16-27.2l67.2-38.4c9.6-6.4 22.4-6.4 32 0l67.2 35.2zM176 169.6v44.8l40 22.4 40-22.4v-44.8l-40-22.4-40 22.4zM275.2 144c8 4.8 12.8 12.8 12.8 20.8v54.4c0 8-4.8 16-12.8 20.8l-48 27.2c-8 4.8-16 4.8-24 0l-48-27.2c-6.4-4.8-11.2-12.8-11.2-20.8v-54.4c0-8 4.8-16 12.8-20.8l48-27.2c8-4.8 16-4.8 24 0L275.2 144zM192 777.6l-48 27.2v54.4l48 27.2 48-27.2v-54.4l-48-27.2z m8-14.4l48 27.2c4.8 3.2 8 8 8 14.4v54.4c0 6.4-3.2 11.2-8 14.4l-48 27.2c-4.8 3.2-11.2 3.2-16 0l-48-27.2c-4.8-3.2-8-8-8-14.4v-54.4c0-6.4 3.2-11.2 8-14.4l48-27.2c4.8-3.2 11.2-3.2 16 0z"
                        fill="#050D42"
                      />
                      <path
                        d="M403.2 776l-62.4 62.4c-1.6 1.6-3.2 1.6-6.4 1.6h-88c-4.8 0-8-3.2-8-8s3.2-8 8-8h84.8l59.2-59.2v-68.8c0-4.8 3.2-8 8-8s8 3.2 8 8v64H576c4.8 0 8 3.2 8 8s-3.2 8-8 8H403.2z m-11.2-436.8l-108.8-94.4c-3.2-3.2-3.2-8-1.6-11.2 3.2-3.2 8-3.2 11.2-1.6l110.4 94.4H528c4.8 0 8 3.2 8 8s-3.2 8-8 8h-120V400c0 4.8-3.2 8-8 8s-8-3.2-8-8v-60.8zM800 728c-4.8 0-8-3.2-8-8s3.2-8 8-8h88c4.8 0 8 3.2 8 8s-3.2 8-8 8H800z m-49.6-435.2c-3.2 3.2-8 3.2-11.2 1.6-3.2-3.2-3.2-8-1.6-11.2l96-112c3.2-3.2 8-3.2 11.2-1.6 3.2 3.2 3.2 8 1.6 11.2l-96 112zM160 504c-4.8 0-8-3.2-8-8s3.2-8 8-8h112c4.8 0 8 3.2 8 8s-3.2 8-8 8h-112z m536 144c0 4.8-3.2 8-8 8s-8-3.2-8-8V544c0-4.8 3.2-8 8-8s8 3.2 8 8v104z"
                        fill="#050D42"
                      />
                    </svg>
                    <button
                      type="button"
                      className="button-access__new"
                      onClick={() => setOpenModalResultadoLab(true)}
                    >
                      <i class="bi bi-plus-circle-fill pe-1"></i>
                      Agregar Nuevo Resultado
                    </button>

                    {/* Modal para el resultado de laboratorio */}
                    <Modal
                      isOpen={openModalResultadoLab}
                      toggle={() => setOpenModalResultadoLab(false)}
                      style={{
                        maxWidth: "90%",
                      }}
                      className="border-3 rounded"
                    >
                      <ModalHeader
                        toggle={() => setOpenModalResultadoLab(false)}
                      >
                        Mantenimiento de Análisis
                      </ModalHeader>
                      <ModalBody>
                        <div>
                          <Row>
                            <Form
                              d-flex
                              justify-content-end
                              onSubmit={SaveConsult}
                              style={{
                                marginBottom: "1rem",
                              }}
                            >
                              <Col md={12} className="border">
                                <Nav tabs className="text-uppercase">
                                  <NavItem style={{ cursor: "pointer" }}>
                                    <NavLink
                                      className={
                                        activeTabLab === "1" ? "active" : ""
                                      }
                                      onClick={() => toggleTabLab("1")}
                                    >
                                      Mantenimiento de Análisis
                                    </NavLink>
                                  </NavItem>
                                  <NavItem style={{ cursor: "pointer" }}>
                                    <NavLink
                                      className={
                                        activeTabLab === "2" ? "active" : ""
                                      }
                                      onClick={() => toggleTabLab("2")}
                                    >
                                      Consulta de Análisis
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTabLab}>
                                  <TabPane tabId="1">
                                    <div className="align-content-start flex-wrap gap-3 text-secondary tabGb d-flex">
                                      {" "}
                                      <div
                                        style={{
                                          height: "45rem",
                                          width: "100%",
                                          flexDirection: "column",
                                          padding: "1rem",
                                          textAlign: "left",
                                        }}
                                      >
                                        <div className="d-flex justify-content-start fw-bold mb-2 text-primary fs-5">
                                          <label>Hemograma Completo</label>
                                        </div>
                                        <div
                                          className="d-flex flex-wrap gap-1 flex-column"
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                          }}
                                        >
                                          <div
                                            className="d-flex flex-wrap p-2 flex-column align-items-start border"
                                            style={{
                                              height: "98%",
                                            }}
                                          >
                                            {[...Array(43).keys()].map((i) => (
                                              <FormGroup key={i} floating>
                                                {i < 35 ? (
                                                  <Input
                                                    type="number"
                                                    className="form-control"
                                                    id={`analysis${i + 1}`}
                                                    value={
                                                      analysisValues[
                                                        `analysis${i + 1}`
                                                      ]
                                                    }
                                                    onChange={(e) =>
                                                      handleAnalysisChange(
                                                        `analysis${i + 1}`,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                ) : i < 39 ? (
                                                  <Input
                                                    type="select"
                                                    className="form-select"
                                                    style={{
                                                      width: "13rem",
                                                    }}
                                                    id={`analysis${i + 1}`}
                                                    value={
                                                      analysisValues[
                                                        `analysis${i + 1}`
                                                      ]
                                                    }
                                                    onChange={(e) =>
                                                      handleAnalysisChange(
                                                        `analysis${i + 1}`,
                                                        e.target.value
                                                      )
                                                    }
                                                  >
                                                    <option value="" disabled>
                                                      Seleccione
                                                    </option>
                                                    <option value="N">
                                                      Negativo
                                                    </option>
                                                    <option value="P">
                                                      Positivo
                                                    </option>
                                                  </Input>
                                                ) : (
                                                  <Input
                                                    type="textarea"
                                                    className="form-control"
                                                    style={{
                                                      height: "8rem",
                                                      width: "15rem",
                                                    }}
                                                    id={`analysis${i + 1}`}
                                                    value={
                                                      analysisValues[
                                                        `analysis${i + 1}`
                                                      ]
                                                    }
                                                    onChange={(e) =>
                                                      handleAnalysisChange(
                                                        `analysis${i + 1}`,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                )}
                                                <Label For={`analysis${i + 1}`}>
                                                  {
                                                    [
                                                      "GB",
                                                      "N",
                                                      "L",
                                                      "Mono",
                                                      "Eo",
                                                      "GR",
                                                      "HB",
                                                      "Hcto",
                                                      "Mev",
                                                      "MCH",
                                                      "MCHC",
                                                      "PIJ",
                                                      "Reticulocitos",
                                                      "Hierro Serico",
                                                      "Ferritina",
                                                      "Capt. de Hierro",
                                                      "Det. Vit. B12",
                                                      "% Sat. Hierro",
                                                      "Det. Acido Fólico",
                                                      "LDH",
                                                      "Fosfatasa Alcalina",
                                                      "AST",
                                                      "ALT",
                                                      "Bilirrubina Total",
                                                      "Bilirrubina Directa",
                                                      "Bilirrubina indirecta",
                                                      "TC",
                                                      "TS",
                                                      "TP",
                                                      "TPT",
                                                      "Eritrosedimentación",
                                                      "Calcio",
                                                      "Urea",
                                                      "Creatinina",
                                                      "INR",
                                                      "HIV",
                                                      "Hepatitis B",
                                                      "Hepatitis C",
                                                      "PCR",
                                                      "Orina",
                                                      "Cronológico",
                                                      "Cultivos",
                                                      "Otros",
                                                    ][i]
                                                  }
                                                </Label>
                                              </FormGroup>
                                            ))}
                                          </div>
                                        </div>
                                        {/* Hemograma Completo */}
                                      </div>
                                      {/* Tab completo */}
                                      <div
                                        className="tabGb d-flex justify-content-end mx-3 gap-2"
                                        style={{
                                          height: "4rem",
                                          width: "100%",
                                        }}
                                      >
                                        <Button
                                          className="button-save text-primary"
                                          style={{
                                            width: "10rem",
                                            height: "3rem",
                                          }}
                                          onClick={saveAnalysis}
                                        >
                                          Guardar
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            setOpenModalResultadoLab(false)
                                          }
                                          className="button-cancel text-secondary"
                                          style={{
                                            width: "10rem",
                                            height: "3rem",
                                          }}
                                        >
                                          Cancelar
                                        </Button>
                                      </div>
                                    </div>
                                  </TabPane>
                                  <TabPane tabId="2">
                                    <div className="align-content-start flex-wrap gap-3 text-secondary tabGb d-flex">
                                      {" "}
                                      <div
                                        style={{
                                          height: "50rem",
                                          width: "100%",
                                          flexDirection: "column",
                                          padding: "1rem",
                                          textAlign: "left",
                                          overflowX: "auto", // Agregado para scroll horizontal
                                          overflowY: "auto", // Agregado para scroll vertical
                                        }}
                                      >
                                        <div className="d-flex justify-content-start mb-2">
                                          <table className="table table-striped-columns table-hover">
                                            <thead>
                                              <tr className="bg-secondary border">
                                                <th>ID Analisis</th>
                                                <th>Nombre</th>
                                                {/* Agrega más encabezados según la estructura de tus datos */}
                                                {Array.from({ length: 43 }).map(
                                                  (_, i) => (
                                                    <th key={i}>
                                                      {
                                                        [
                                                          "GB",
                                                          "N",
                                                          "L",
                                                          "Mono",
                                                          "Eo",
                                                          "GR",
                                                          "HB",
                                                          "Hcto",
                                                          "Mev",
                                                          "MCH",
                                                          "MCHC",
                                                          "PIJ",
                                                          "Reticulocitos",
                                                          "Hierro Serico",
                                                          "Ferritina",
                                                          "Capt. de Hierro",
                                                          "Det. Vit. B12",
                                                          "% Sat. Hierro",
                                                          "Det. Acido Fólico",
                                                          "LDH",
                                                          "Fosfatasa Alcalina",
                                                          "AST",
                                                          "ALT",
                                                          "Bilirrubina Total",
                                                          "Bilirrubina Directa",
                                                          "Bilirrubina indirecta",
                                                          "TC",
                                                          "TS",
                                                          "TP",
                                                          "TPT",
                                                          "Eritrosedimentación",
                                                          "Calcio",
                                                          "Urea",
                                                          "Creatinina",
                                                          "INR",
                                                          "HIV",
                                                          "Hepatitis B",
                                                          "Hepatitis C",
                                                          "PCR",
                                                          "Orina",
                                                          "Cronológico",
                                                          "Cultivos",
                                                          "Otros",
                                                        ][i]
                                                      }
                                                    </th>
                                                  )
                                                )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {analysisData.map((analisis) => (
                                                <tr key={analisis.analysiId}>
                                                  <td style={{ width: "3%" }}>
                                                    {analisis.analysiId}
                                                  </td>
                                                  <td style={{ width: "20%" }}>
                                                    {analisis.analysiFec}
                                                  </td>
                                                  {/* Agrega más celdas según la estructura de tus datos */}
                                                  {Array.from({
                                                    length: 43,
                                                  }).map((_, i) => (
                                                    <td
                                                      key={i}
                                                      style={{ width: "20%" }}
                                                    >
                                                      {
                                                        analisis[
                                                          `analysis${i + 1}`
                                                        ]
                                                      }
                                                    </td>
                                                  ))}
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                        {/* Hemograma Completo */}
                                      </div>
                                      {/* Hemograma Completo */}
                                    </div>
                                    {/* Tab completo */}
                                  </TabPane>
                                </TabContent>
                              </Col>
                            </Form>
                          </Row>
                        </div>
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                    </Modal>
                  </div>
                </div>
              </div>
              {/* Resultado de Laboratorio */}

              <div
                className="bg-light mb-3 border border-light rounded p-2"
                style={{ height: parentDivHeight }}
              >
                <div className="text-start fs-5 fw-bold m-2">
                  <label>Diagnóstico</label>
                </div>
                <div className="" style={{ width: "50%" }}>
                  <FormGroup floating>
                    <Input
                      type="select"
                      className="form-control"
                      id="causeId"
                      value={causeId}
                      onChange={(e) => setCauseId(e.target.value)}
                      placeholder="Causa Externo"
                    >
                      <option value="" disabled>
                        Selecciona una Causa Externa
                      </option>
                      {externalCauses.map((cause) => (
                        <option
                          key={cause.causeId}
                          value={cause.causeId.toString()}
                        >
                          {cause.causeNm}
                        </option>
                      ))}
                    </Input>
                    <Label For="Causa Externa">Causa Externa (Opcional)</Label>
                  </FormGroup>
                </div>
                {/* Causa externa */}
                <div className="border rounded-2 d-flex pb-3 px-2">
                  <div className="mx-3" style={{ width: "100%" }}>
                    <div className="d-flex mt-3 gap-1">
                      <div>
                        <button
                          type="button"
                          className="btn button-search"
                          onClick={() => openModalCie10()}
                        >
                          <i className="bi bi-search"></i>
                        </button>
                      </div>
                      <div className="" style={{ width: "100%" }}>
                        <FormGroup>
                          <Input
                            type="text"
                            className="form-control"
                            id="descriptionCie10"
                            value={descriptionCie10}
                            onChange={(e) =>
                              setDescriptionCie10(e.target.value)
                            }
                            placeholder="Diagnóstico principal (CIE-10)"
                            disabled={true}
                          />
                        </FormGroup>
                      </div>
                      <button
                        type="button"
                        className="btn"
                        style={{ height: "1rem" }}
                        onClick={() => {
                          setDescriptionCie10("");
                          openModalCie10();
                        }}
                      >
                        <i className="bi bi-x-lg"></i>
                      </button>
                    </div>
                    <div
                      class="accordion accordion-flush"
                      id="accordionDiagnostico"
                    >
                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="flush-headingDiagnostico"
                        >
                          <button
                            class={`accordion-button rounded-2 ${
                              isAccordionDiagnosticoOpen ? "" : "collapsed"
                            } `}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded={
                              isAccordionDiagnosticoOpen ? "true" : "false"
                            }
                            aria-controls="flush-collapseOne"
                            onClick={() => {
                              setIsAccordionDiagnosticoOpen(
                                !isAccordionDiagnosticoOpen
                              );
                              setParentDivHeight(
                                isAccordionDiagnosticoOpen ? "20rem" : "auto"
                              );
                            }}
                          >
                            Información adicional
                          </button>
                        </h2>
                        <div
                          id="flush-collapseDiagnostico"
                          class={`accordion-collapse collapse ${
                            isAccordionDiagnosticoOpen ? "show" : ""
                          } ${expanded ? "transicionWipeButton" : ""}`}
                          aria-labelledby="flush-headingDiagnostico"
                          data-bs-parent="#accordionDiagnostico"
                        >
                          <div class="accordion-body row">
                            <Col md={6}>
                              <FormGroup floating>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="consultClaDiag"
                                  value={consultClaDiag}
                                  onChange={(e) =>
                                    setConsultClaDiag(e.target.value)
                                  }
                                  placeholder="Clasificación de Diagnóstico"
                                >
                                  <option value="" disabled>
                                    Selecciona una Clasificación de diagnóstico
                                  </option>
                                  <option value="Quirúrgico">Quirúrgico</option>
                                  <option value="Tóxico">Tóxico</option>
                                  <option value="Alérgico">Alérgico</option>
                                  <option value="Clínico">Clínico</option>
                                  <option value="Patologia">Patologia</option>
                                  <option value="Otro">Otro</option>
                                  <option value="No Aplica">No Aplica</option>
                                  <option value="Farmacológico">
                                    Farmacológico
                                  </option>
                                  <option value="Hospitalario">
                                    Hospitalario
                                  </option>
                                  <option value="Traumático">Traumático</option>
                                </Input>
                                
                                <Label For="Causa Externa">
                                  Clasificación de diagnóstico{" "}
                                  <span className="text-danger fw-bold">*</span>
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup floating>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="consultTipDiag"
                                  value={consultTipDiag}
                                  onChange={(e) =>
                                    setConsultTipDiag(e.target.value)
                                  }
                                  placeholder="Caso Externo"
                                >
                                  <option value="" disabled>
                                    Selecciona un Tipo de diagnóstico
                                  </option>
                                  <option value="diagnóstico">
                                    Impresión diagnóstico
                                  </option>
                                  <option value="Confirmado">
                                    Confirmado nuevo
                                  </option>
                                  <option value="repetido">
                                    Confirmado repetido
                                  </option>
                                </Input>
                                <Label For="Causa Externa">
                                  Tipo de diagnóstico{" "}
                                  <span className="text-danger fw-bold">*</span>
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup floating>
                                <Input
                                  type="textarea"
                                  className="form-control"
                                  id="consultDiag"
                                  value={consultDiag}
                                  onChange={(e) =>
                                    setConsultDiag(e.target.value)
                                  }
                                  placeholder="Diagnostico"
                                  style={{ height: "10rem" }}
                                  maxLength={maxChars2}
                                />
                                <Label For="Diagnostico">
                                  Observaciones (Opcional)
                                </Label>
                                <p
                                  style={{ fontSize: "12px", display: "flex" }}
                                >
                                  {maxChars2 - consultDiag.length}/{maxChars2}
                                </p>
                              </FormGroup>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Informacion adicional - Cordion */}
                  </div>

                  <Modal
                    isOpen={isModalCie10Open}
                    toggle={() => setIsModalCie10Open(false)}
                    style={{
                      maxWidth: "50rem",
                    }}
                  >
                    <ModalHeader toggle={() => setIsModalCie10Open(false)}>
                      Lista CIE-10
                    </ModalHeader>
                    <ModalBody>
                      <Form className="mb-4 sticky-top pt-3 border-bottom bg-primary text-white px-2 rounded-3">
                        <FormGroup>
                          <Label for="search">
                            Buscar por código o descripción:
                          </Label>
                          <Input
                            type="text"
                            name="search"
                            id="search"
                            placeholder="Código o descripción del CIE-10"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </FormGroup>
                      </Form>
                      <ul
                        style={{
                          height: "35rem",
                          listStyle: "none",
                          padding: 0,
                          margin: 0,
                          border: "1px solid",
                        }}
                      >
                        {cie10Data
                          .filter((item) => {
                            return (
                              item.description
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              item.code
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            );
                          })
                          .slice(pagesVisited, pagesVisited + itemsPerPage)
                          .map((item, index) => (
                            <li
                              key={item.code}
                              className={`pt-2 px-2 ${
                                index % 2 === 0 ? "even-row" : "odd-row"
                              }`}
                            >
                              <div className="d-flex justify-content-between align-items-center pb-1">
                                <span>{`${item.code} - ${item.description}`}</span>

                                <button
                                  className="button-save"
                                  type="button"
                                  title="Seleccionar CIE-10"
                                  onClick={() => handleCIE10Selection(item)}
                                >
                                  <i className="bi bi-clipboard-check-fill"></i>
                                </button>
                              </div>
                            </li>
                          ))}
                      </ul>
                      <div className="d-flex justify-content-center">
                        <ReactPaginate
                          previousLabel={"<< "}
                          nextLabel={" >>"}
                          pageCount={pageCount}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination"}
                          previousLinkClassName={"pagination__link"}
                          nextLinkClassName={"pagination__link"}
                          disabledClassName={"pagination__link--disabled"}
                          activeClassName={"pagination__link--active"}
                          pageRangeDisplayed={10}
                          marginPagesDisplayed={0}
                          breakLabel={"..."}
                          breakClassName={"pagination__link"}
                          breakLinkClassName={"pagination__link"}
                          pageLinkClassName={"pagination__link"}
                          initialPage={0}
                        />
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className="button-cancel text-secondary"
                        onClick={() => setIsModalCie10Open(false)}
                      >
                        Cancelar
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
              {/* Diagnostico */}

              <div
                className="bg-light pb-3 border border-light rounded-top p-2"
                style={{
                  height: "30rem",
                }}
              >
                <div
                  className=""
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className="col-12 text-start">
                    <label className=" fs-5 fw-bold m-2">
                      Gestor de Recetas Médicas
                    </label>
                  </div>

                  <div
                    className="d-flex"
                    style={{ width: "100%", height: "90%" }}
                  >
                    <div
                      className="d-flex flex-column py-2 px-2"
                      style={{ width: "30%", height: "100%" }}
                    >
                      <div>
                        <FormGroup floating>
                          <Input
                            type="text"
                            className="form-control text-uppercase"
                            id="descripcionMedicamento"
                            value={descripcionMedicamento}
                            onChange={(e) =>
                              setDescripcionMedicamento(e.target.value)
                            }
                            placeholder="Medicamento"
                            style={{ width: "100%" }}
                          />
                          <Label For="Medicamento">Medicamento</Label>
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup floating>
                          <Input
                            type="text"
                            className="form-control text-uppercase"
                            id="dosis"
                            value={dosis}
                            onChange={(e) => setDosis(e.target.value)}
                            placeholder="Dosis"
                            style={{ width: "100%" }}
                          />
                          <Label For="Dosis">Dosis</Label>
                        </FormGroup>
                      </div>
                      <div className="">
                        <FormGroup floating>
                          <Input
                            type="text"
                            className="form-control text-uppercase"
                            id="total"
                            value={total}
                            onChange={(e) => setTotal(e.target.value)}
                            placeholder="Total"
                            style={{ width: "100%" }}
                          />
                          <Label For="Total">Total (Opcional)</Label>
                        </FormGroup>
                      </div>
                      <div
                        className="m-2"
                        style={{
                          width: "100%",
                          height: "5rem",
                        }}
                      >
                        <button
                          className="button-access__new"
                          type="button"
                          style={{
                            height: "4rem",
                            width: "10rem",
                          }}
                          onClick={handleBuscarMedicamentos}
                        >
                          <i class="bi bi-search pe-1"></i>
                          Buscar
                        </button>
                      </div>
                      <div
                        className="d-flex gap-1 px-1"
                        style={{
                          width: "100%",
                          height: "4rem",
                        }}
                      >
                        <button
                          className="button-send "
                          style={{
                            width: "50%",
                            height: "4rem",
                          }}
                          type="button"
                          onClick={handleAddMedicamento}
                        >
                          <i class="bi bi-plus-circle-fill pe-1"></i>
                          Agregar
                        </button>
                        <button
                          className="button-print"
                          style={{
                            width: "50%",
                            height: "4rem",
                          }}
                          type="button"
                          onClick={() =>
                            previsualizarPDF(
                              medicamentos,
                              fechaConsulta,
                              paciente,
                              fechaCita,
                              horaCita,
                              categoryCitaId,
                              notaCita
                            )
                          }
                        >
                          <i className="bi bi-printer-fill pe-1"></i>
                          Imprimir
                        </button>
                      </div>
                    </div>
                    {/* Campos del medicamento */}

                    <div
                      className="d-flex flex-column bg-secondary"
                      style={{ width: "70%", height: "100%", overflow: "auto" }}
                    >
                      {/* Tabla de Medicamentos */}
                      <table className="table table-striped table-hover text-uppercase">
                        <thead>
                          <tr>
                            <th>Medicamento</th>
                            <th>Dosis</th>
                            <th>Total</th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {medicamentos.map((medicamento, index) => (
                            <tr key={index}>
                              <td>{medicamento.medicamento}</td>
                              <td>{medicamento.dosis}</td>
                              <td>{medicamento.total}</td>
                              <td>
                                <button
                                  className="button-delete text-danger"
                                  type="button"
                                  placeholder="Eliminar registro"
                                  onClick={() => handleDeleteMedicamento(index)}
                                >
                                  <i class="bi bi-trash3"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* Tabla */}
                    <div>
                      {/* Modal de Búsqueda de Medicamentos */}
                      <Modal
                        isOpen={isMedicamentosModalOpen}
                        toggle={() => setIsMedicamentosModalOpen(false)}
                        style={{
                          maxWidth: "65rem",
                        }}
                      >
                        <ModalHeader
                          toggle={() => setIsMedicamentosModalOpen(false)}
                        >
                          Buscar Medicamentos
                        </ModalHeader>
                        <ModalBody>
                          <Form className="mb-4 sticky-top pt-3 border-bottom bg-primary text-white px-2 rounded-3">
                            <FormGroup>
                              <Label for="search">
                                Buscar por código o descripción:
                              </Label>
                              <Input
                                type="text"
                                name="search"
                                id="search"
                                placeholder="Código o descripción del Medicamento"
                                value={searchTermMedic}
                                onChange={(e) =>
                                  setSearchTermMedic(e.target.value)
                                }
                              />
                            </FormGroup>
                          </Form>
                          {/* Listado */}
                          <div>
                            <table className="table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>Nombre Generico</th>
                                  <th>Nombre Comerciar</th>
                                  <th>Dosis</th>
                                  <th>Acción</th>
                                </tr>
                              </thead>
                              <tbody>
                                {medicamentosSearchResults
                                  .filter((medicamento) => {
                                    return (
                                      medicamento.producNm
                                        .toLowerCase()
                                        .includes(
                                          searchTermMedic.toLowerCase()
                                        ) ||
                                      medicamento.producId
                                        .toLowerCase()
                                        .includes(searchTermMedic.toLowerCase())
                                    );
                                  })
                                  .slice(
                                    pagesVisited,
                                    pagesVisited + itemsPerPage
                                  )
                                  .map((medicamento, index) => (
                                    <tr key={index}>
                                      <td>{medicamento.producId}</td>
                                      <td>{medicamento.producNm}</td>
                                      <td>{medicamento.producNc}</td>
                                      <td>{medicamento.producDs}</td>
                                      <td>
                                        <button
                                          className="button-save text-center"
                                          type="button"
                                          title="Seleccionar Registro"
                                          onClick={() =>
                                            handleSelectMedicamento(medicamento)
                                          }
                                        >
                                          <i className="bi bi-clipboard-check-fill"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>

                          {/* Paginación */}
                          <div className="d-flex justify-content-center">
                            <nav aria-label="Page navigation example">
                              <ul className="pagination justify-content-center">
                                <li
                                  className={`page-item`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    className="page-link"
                                    onClick={() =>
                                      handlePageChange({ selected: 0 })
                                    }
                                  >
                                    Inicio
                                  </span>
                                </li>
                                <li
                                  className={`page-item`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    className="page-link"
                                    style={{
                                      height: "2.4rem",
                                      width: "7rem",
                                    }}
                                    onClick={() =>
                                      handlePageChange({
                                        selected: Math.max(0, pageNumber - 10),
                                      })
                                    }
                                  >
                                    Anterior 10
                                  </span>
                                </li>
                                {Array.from(
                                  { length: Math.min(pageCount, 10) },
                                  (_, index) => (
                                    <li
                                      key={index}
                                      className={`page-item ${
                                        pageNumber === index ? " active" : ""
                                      }`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span
                                        className="page-link"
                                        onClick={() =>
                                          handlePageChange({ selected: index })
                                        }
                                      >
                                        {index + 1}
                                      </span>
                                    </li>
                                  )
                                )}
                                <li
                                  className={`page-item`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    className="page-link"
                                    style={{
                                      height: "2.4rem",
                                      width: "7.5rem",
                                    }}
                                    onClick={() =>
                                      handlePageChange({
                                        selected: Math.min(
                                          pageCount - 1,
                                          pageNumber + 10
                                        ),
                                      })
                                    }
                                  >
                                    Siguiente 10
                                  </span>
                                </li>
                                <li
                                  className={`page-item`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    className="page-link"
                                    onClick={() =>
                                      handlePageChange({
                                        selected: pageCount - 1,
                                      })
                                    }
                                  >
                                    Final
                                  </span>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            color="secondary"
                            onClick={() => setIsMedicamentosModalOpen(false)}
                          >
                            Cerrar
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                    {/* Modal de medicamentos */}
                  </div>
                </div>
              </div>
              {/* Recetas Medicas */}

              {/* <div
                className="bg-light mb-3 border border-light rounded-bottom d-flex"
                style={{
                  height: "26rem",
                }}
              >
                <div
                  className="mx-2"
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="col-12 text-start">
                    <label className=" fs-5 fw-bold m-2">Receta General</label>
                  </div>

                  <div className="col-12" style={{}}>
                    <FormGroup floating>
                      <Input
                        type="textarea"
                        className="form-control"
                        id="recetaControlada"
                        value={recetaControlada}
                        onChange={(e) => setRecetaControlada(e.target.value)}
                        placeholder="Estudio de Gabinete"
                        style={{ height: "15rem" }}
                        maxLength={maxChars4}
                      />
                      <Label For="Estudio de Gabinete">
                        Recetas Controladas (Opcional)
                      </Label>
                      <p style={{ fontSize: "12px", display: "flex" }}>
                        {maxChars4 - recetaControlada.length}/{maxChars4}
                      </p>
                    </FormGroup>
                  </div>
                  <div>
                    <button
                      className="button-print"
                      style={{
                        width: "15rem",
                        height: "4rem",
                      }}
                      type="button"
                      onClick={() =>
                        previsualizarPDFRecetaControlada(recetaControlada)
                      }
                    >
                      <i className="bi bi-printer-fill pe-1"></i>
                      Imprimir
                    </button>
                  </div>
                </div>
              </div> */}
              {/* Recetas Controlada */}

              <div
                className="bg-light pb-3 border border-light rounded p-2 mt-4"
                style={{
                  height: alturaContenedorCita,
                }}
              >
                <div
                  className=""
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className="col-12 text-start">
                    <label className=" fs-5 fw-bold m-2">
                      Gestor de Citas Médicas
                    </label>
                  </div>

                  <div
                    class="accordion accordion-flush"
                    id="accordionDiagnostico"
                  >
                    <div class="accordion-item">
                      <div className="bg-light d-flex align-items-center justify-content-evenly ">
                        <h2
                          className="accordion-header mb-3"
                          id="flush-headingDiagnostico"
                        >
                          <div className="form-check form-switch px-2">
                            <label
                              className="form-check-label fs-4"
                              htmlFor="flexSwitchCheckDefault"
                            >
                              ¿Desea agendar una cita?
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              checked={isAccordionCitaOpen}
                              onChange={handleSwitchChange}
                            />
                          </div>
                        </h2>
                      </div>
                      <div
                        id="flush-collapseDiagnostico"
                        class={`accordion-collapse collapse ${
                          isAccordionCitaOpen ? "show" : ""
                        } ${expanded ? "transicionWipeButton" : ""}`}
                        aria-labelledby="flush-headingDiagnostico"
                        data-bs-parent="#accordionDiagnostico"
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div className="text-start mx-2 my-4 fw-bold fs-6">
                            <label>Información de la Cita</label>
                          </div>
                          <div
                            className="d-flex gap-3 m-2"
                            style={{
                              height: "20%",
                            }}
                          >
                            <Col md={4}>
                              <FormGroup floating>
                                <Input
                                  type="date"
                                  className="form-control"
                                  id="fechaCita"
                                  value={fechaCita}
                                  onChange={handleFechaCitaChange}
                                  placeholder="Fecha de Cita"
                                />
                                <Label htmlFor="fechaCita">Fecha</Label>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup floating>
                                <Input
                                  type="time"
                                  className="form-control"
                                  id="horaCita"
                                  value={horaCita}
                                  onChange={handleHoraCitaChange}
                                  placeholder="Hora de Cita"
                                />
                                <Label htmlFor="horaFormateada">Hora</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup floating>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="categoryCitaId"
                                  value={categoryCitaId}
                                  onChange={(e) =>
                                    setCategoryCitaId(e.target.value)
                                  }
                                  placeholder="CategoryCita"
                                >
                                  {categoryData.map((category) => (
                                    <option
                                      key={category.categoryId}
                                      value={category.categoryId.toString()}
                                    >
                                      {category.categoryNm}
                                    </option>
                                  ))}
                                </Input>
                                <Label for="categoriaNm">
                                  Tipo de Consulta
                                </Label>
                              </FormGroup>
                            </Col>
                          </div>
                          <div
                            className="px-2"
                            style={{
                              height: "70%",
                              width: "100%",
                            }}
                          >
                            <FormGroup floating>
                              <Input
                                type="textarea"
                                className="form-control"
                                id="notaCita"
                                value={notaCita}
                                onChange={handleNotaCitaChange}
                                style={{
                                  height: "15rem",
                                }}
                                placeholder="Nota"
                                maxLength={maxChars3}
                              />
                              <Label htmlFor="notaCita">Nota</Label>
                              <p
                                className=""
                                style={{ fontSize: "12px", display: "flex" }}
                              >
                                {maxChars3 - notaCita.length}/{maxChars3}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Asignar Cita */}

              <div
                className="tabGb d-flex justify-content-center align-items-center my-3 gap-2"
                style={{
                  height: "10rem",
                  width: "100%",
                }}
              >
                <button
                  onClick={() => CancelConsult()}
                  type="button"
                  className="button-cancel text-secondary"
                  style={{
                    width: "10rem",
                    height: "3rem",
                  }}
                >
                  {" "}
                  Cancelar{" "}
                </button>
                <button
                  onClick={SaveConsult}
                  className="button-save text-primary"
                  style={{ width: "15rem", height: "3rem" }}
                >
                  Guardar Consulta
                </button>
              </div>

              <div>
                <Modal
                  isOpen={openModalSaveConsult}
                  toggle={() => setOpenModalSaveConsult(false)}
                  style={{ maxWidth: "40rem" }}
                >
                  <ModalHeader toggle={() => setIsSaveConsultModalOpen(false)}>
                    Detalle de Pago
                  </ModalHeader>
                  <ModalBody>
                    <Form className="mb-4 sticky-top border-bottom text-white px-2 rounded-3">
                      <div className="d-flex flex-column text-black  text-center">
                        <div className="d-flex justify-content-between pb-4">
                          <div>
                            <FormGroup floating>
                              <Input
                                type="text"
                                name="Fecha de Proceso"
                                id="Fecha de Proceso"
                                placeholder="Fecha de Proceso"
                                value={fechaActual}
                                disabled
                              />
                              <Label htmlFor="fechaActual">
                                Fecha de Proceso
                              </Label>
                            </FormGroup>
                            <FormGroup floating>
                              <Input
                                type="text"
                                name="ID"
                                id="ID"
                                placeholder="ID"
                                value={ultimoPay}
                                disabled
                              />
                              <Label htmlFor="ultimoPay">ID</Label>
                            </FormGroup>
                          </div>
                          <div>
                            <FormGroup floating>
                              <Input
                                type="text"
                                name="No. Consulta"
                                id="No. Consulta"
                                placeholder="No. Consulta"
                                value={ultimaConsultaAll}
                                disabled
                              />
                              <Label htmlFor="No. Consulta">No. Consulta</Label>
                            </FormGroup>
                            <FormGroup floating>
                              <Input
                                type="text"
                                name="No. Expediente"
                                id="No. Expediente"
                                placeholder="No. Expediente"
                                value={paciente.pcteId}
                                disabled
                              />
                              <Label htmlFor="No. Expediente">Expediente</Label>
                            </FormGroup>
                          </div>
                        </div>

                        <div
                          className="d-flex justify-content-between fw-bold fs-5"
                          style={
                            {
                              // width: '50rem'
                            }
                          }
                        >
                          <FormGroup>
                            <Label>Seguro Médico</Label>
                            <Input
                              style={{ width: "15rem" }}
                              type="text"
                              name="seguroNm"
                              id="seguroNm"
                              placeholder="Seguro Médico"
                              value={paciente.seguroNm}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Monto</Label>
                            <Input
                              style={{
                                width: "20rem",
                                height: "5rem",
                                fontSize: "3rem",
                                textAlign: "right",
                              }}
                              type="text"
                              name="search"
                              id="search"
                              placeholder="RD$0.00"
                              value={cantidadPago}
                              onChange={handleChangeCantidadPago}
                            />
                          </FormGroup>
                        </div>
                        <div className="text-start fw-bold">
                          <FormGroup>
                            <Label>Descripción (Opcional)</Label>
                            <Input
                              style={{ width: "100%" }}
                              type="textarea"
                              name="descripcionPago"
                              id="descripcionPago"
                              placeholder="¿Desea agregar alguna descripción?"
                              value={descripcionPago} // Valor del estado para la descripción
                              onChange={(e) =>
                                setDescripcionPago(e.target.value)
                              }
                              maxLength={maxChars3}
                            />
                          </FormGroup>
                          <p style={{ fontSize: "12px", display: "flex" }}>
                            {maxChars3 - descripcionPago.length}/{maxChars3}
                          </p>
                        </div>
                        {/* Información para el pago */}
                        <div style={{ maxHeight: "100%", overflowY: "auto" }}>
                          <div className="text-start mt-5 mb-1 text-primary">
                            <label>Citas Pendientes del Paciente</label>
                          </div>
                          <table className="table table-striped table-hoverfs-6">
                            <thead>
                              <tr>
                                <th>Fecha</th>
                                <th>Cita ID</th>
                                <th>Notas</th>
                              </tr>
                            </thead>
                            <tbody>
                              {citasFiltradas.map((cita) => (
                                <tr key={cita.calendarId}>
                                  <td style={{ width: "20%" }}>
                                    {cita.fechaCita.split(" ")[0]}
                                  </td>
                                  <td style={{ width: "10%" }}>
                                    {cita.calendarId}
                                  </td>
                                  <td
                                    style={{ width: "40%", textAlign: "left" }}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={cita.notaCita}
                                  >
                                    {cita.notaCita && cita.notaCita.length > 20
                                      ? `${cita.notaCita.slice(0, 40)}...`
                                      : cita.notaCita}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        {/* Listadod e Citas pendientes */}
                      </div>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="button-send text-success"
                      onClick={saveConsultModal}
                    >
                      {" "}
                      Procesar Pago
                    </Button>
                    <Button
                      className="button-cancel text-secondary"
                      onClick={() => setIsSaveConsultModalOpen(false)}
                    >
                      Cerrar
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              {/* Buttons Save and Cancel */}
            </Form>
          </Row>
        </div>
        {/* Consulta */}

        {/* Resumen del Paciente  */}
        <div className="">
          {historicoVisible && (
            <div
              className={`historico rounded-end border-start  ${
                historicoVisible ? "transicionWipeLeft" : ""
              }`}
              style={{
                height: historicoVisible ? "100%" : "0",
                width: "35rem",
                backgroundColor: "#fff",
                top: "19rem",
                right: "1rem",
                overflow: "hidden",
              }}
            >
              <div class="accordion accordion-flush" id="accordionHistorial">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingHistorial">
                    <button
                      class={`accordion-button ${
                        openAccordion === "flush-collapseOne" ? "" : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded={
                        openAccordion === "flush-collapseOne" ? "true" : "false"
                      }
                      aria-controls="flush-collapseOne"
                      onClick={() => handleAccordionToggle("flush-collapseOne")}
                    >
                      Información del Paciente
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingHistorial"
                    data-bs-parent="#accordionHistorial"
                  >
                    <div class="accordion-body">
                      <div className="d-flex flex-direction-column align-items-start">
                        <div
                          className=""
                          style={{
                            width: "40%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <label>Número de Documento: </label>
                          <label>Sede del Paciente: </label>
                          <label>Número de Contacto: </label>
                          <label>País: </label>
                          <label>Provincia: </label>
                          <label>Municipio: </label>
                          <label>Dirección:</label>
                        </div>

                        <div
                          className=""
                          style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <span>{paciente.pcteNumDoc}</span>
                          <span>
                            {paciente.pcteNom} {paciente.pcteNom2}{" "}
                            {paciente.pcteApe1} {paciente.pcteApe2}
                          </span>
                          <span>{paciente.pcteCel} </span>
                          <span>{paciente.pcteNac}</span>
                          <span>{paciente.pcteProv}</span>
                          <span>{paciente.pcteCiud}</span>
                          <span className="text-start">{paciente.pcteDir}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button
                      class={`accordion-button ${
                        openAccordion === "flush-collapseTwo" ? "" : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded={
                        openAccordion === "flush-collapseTwo" ? "true" : "false"
                      }
                      aria-controls="flush-collapseTwo"
                      onClick={() => handleAccordionToggle("flush-collapseTwo")}
                    >
                      Histórico de atenciones
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionHistorial"
                  >
                    <div class="accordion-body">
                      <div
                        className="mb-2"
                        style={{
                          height: "25rem",
                        }}
                      >
                        <table className="table table-striped table-hover ">
                          <thead>
                            <tr>
                              <th>Fecha</th>
                              <th>Atención</th>
                              <th>Motivo</th>
                            </tr>
                          </thead>
                          <tbody className="text-start">
                            {displayedConsultas.map((consult) => (
                              <tr key={consult.consultId}>
                                <td style={{ width: "10%" }}>
                                  {consult.consultFec}
                                </td>
                                <td style={{ width: "10%" }}>
                                  {consult.categoryId && categoryData.length > 0
                                    ? getCategoryName(
                                        consult.categoryId,
                                        categoryData
                                      )
                                    : "Sin categoría"}
                                </td>
                                <td
                                  style={{ width: "30%" }}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title={consult.consultMot}
                                >
                                  {consult.consultMot?.length > 10
                                    ? `${consult.consultMot.slice(0, 15)}...`
                                    : consult.consultMot}
                                </td>
                                <td style={{ width: "5%" }}>
                                  <div className="">
                                    <button
                                      type="button"
                                      className="p-1 border-0"
                                      title="Visualizar"
                                      id="hoverIcon"
                                    >
                                      <i className="bi bi-file-earmark-pdf"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex gap-1">
                        <div
                          className="d-flex flex-column"
                          style={{
                            width: "50%",
                          }}
                        >
                          <ReactPaginate
                            pageCount={pageCountConsult}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            activeClassName={"pagination__link--active"}
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            breakClassName={"break-me"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"pagination__link"}
                            previousClassName={"previous pagination__arrow"}
                            nextClassName={"next pagination__arrow"}
                            pageLinkRenderer={({ page, onPageClick }) => (
                              <a
                                key={page}
                                href="!#"
                                onClick={onPageClick}
                                className={"page-link"}
                              >
                                {page}
                              </a>
                            )}
                          />
                        </div>
                        <div
                          className="d-flex"
                          style={{
                            width: "50%",
                          }}
                        >
                          <label className="">
                            Total atenciones: {consultas.length}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button
                      class={`accordion-button ${
                        openAccordion === "flush-collapseThree"
                          ? ""
                          : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded={
                        openAccordion === "flush-collapseThree"
                          ? "true"
                          : "false"
                      }
                      aria-controls="flush-collapseThree"
                      onClick={() =>
                        handleAccordionToggle("flush-collapseThree")
                      }
                    >
                      Certificados Médicos
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionHistorial"
                  >
                    <div class="accordion-body">
                      Contenido placeholder para este acordeón, que pretende
                      demostrar la clase <code>.accordion-flush</code>. Este es
                      el cuerpo del acordeón del tercer elemento. No sucede nada
                      más emocionante aquí en términos de contenido, sino
                      simplemente llenar el espacio para que se vea, al menos a
                      primera vista, un poco más representativo de cómo se vería
                      esto en una aplicación del mundo real.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Resumen del Paciente  */}
      </div>
    </div>
  );
};
export default ConsultaForm;
