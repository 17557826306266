import { format } from "date-fns";
import jsPDF from "jspdf";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import medicGeekApi from "../../../config/axiosConfig";
import { showErrorAlert, showSuccessAlert, showInfoAlert } from "./../../../components/alerts";

const LabTestList = ({
  setCurrentForm,
  currentForm,
  setSelectedCertMedicos,
}) => {
  const [certMedicos, setCertMedicos] = useState([]);
  const [labTests, setLabTests] = useState([]);
  const [labTestsParam, setLabTestsParam] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [sortColumn, setSortColumn] = useState(''); // Columna actual para ordenar
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección del ordenamiento


  const [selectedPaciente, setSelectedPaciente] = useState(null);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const labTestsPerPage = 10;
  const [selectedLabTestsLocal, setSelectedLabTestsLocal] =
    useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Variable para distinguir entre editar y crear
  const [searchTerm, setSearchTerm] = useState("");
  const [formulario, setFormulario] = useState({
    fechaCertMedico: "",
    constatado: "",
    recomedacion: "",
  });

  const [pacientes, setPacientes] = useState([]);
  const [pageSize, setPageSize] = useState(10); // Inicialmente, muestra 10 registros por página

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Cantidad de elementos por página

  const [profile, setProfile] = useState({
    docNom: "",
    docEsp: "",
    docCed: "",
    docExe: "",
    docProv: "",
  });

  const [constatado, setConstatado] = useState("");
  const [recomedacion, setRecomedacion] = useState("");

  // Contador de caracteres
  const maxChars1 = 5000;

  const [profileData, setProfileData] = useState(null);

  const openModal = (labTest, customPageSize = 10) => {
    setSelectedLabTestsLocal(labTest); 
    setPageSize(customPageSize);
  
    if (labTest) {
      setIsEditing(true);
      setFormulario({
        dynamicFields: labTest.dynamicFields || [],
        pcteId: labTest.pcteId,
        profileId: labTest.profileId,
        createdAt: labTest.createdAt,
      });
      setSelectedPaciente(labTest.paciente);
  
      // Establecer los tests seleccionados basados en labTest
      const selectedTestsFromLabTest = labTest.dynamicFields.map(field => field.AnalisisNm);
      setSelectedTests(selectedTestsFromLabTest); // Asegúrate de que este estado se inicializa correctamente
    } else {
      setIsEditing(false);
      setFormulario({
        dynamicFields: [],
        pcteId: null,
        profileId: null,
        createdAt: "",
      });
      setSelectedPaciente(null);
      setSelectedTests([]); // Reinicia los tests seleccionados para un nuevo registro
    }
  
    setModalOpen(true);
  };
  
  const closeModal = () => {
    setSelectedLabTestsLocal(null); // Limpia el CertMedico seleccionado
    setIsEditing(false); // Resetear el modo edición cuando se cierra el modal
    setModalOpen(false);
  };

  useEffect(() => {
    fetchProfile();
    fetchLabTestParams();
    fetchData();
  }, []);

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const fetchData = async () => {
    try {
      const labTestsResponse = await medicGeekApi.get('/labtest');
      const pacientesResponse = await medicGeekApi.get('/patients');
      if (
        
        pacientesResponse.status === 200 &&
        labTestsResponse.status === 200
      ) {
        const labTestsData = labTestsResponse.data;
        const pacientesData = pacientesResponse.data;
  
        // Guardamos los pacientes en el estado
        setPacientes(pacientesData);
  
        // Combina los datos de labTest con los pacientes
        const combinedData = labTestsData.map((labTest) => {
          const paciente = pacientesData.find((p) => p.pcteId === labTest.pcteId);
  
          return {
            ...labTest,
            pcteNom: paciente ? paciente.pcteNom : '',
            pcteNom2: paciente ? paciente.pcteNom2 : '',
            pcteApe1: paciente ? paciente.pcteApe1 : '',
            pcteApe2: paciente ? paciente.pcteApe2 : '',
            paciente: paciente || {},
          };
        });
  
        // Ordena los datos combinados por labTestParamId
        const sortedData = combinedData.sort((a, b) => a.idLabTest - b.idLabTest);
  
        // Actualiza el estado con los datos combinados
        setLabTests(sortedData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchProfile = async () => {
    try {
      // Obtener el token desde localStorage
      const token = localStorage.getItem("token");
      if (token) {
        // Decodificar el token para obtener el nombre de usuario
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username; // Asegúrate de que 'username' sea correcto

        if (username) {
          // Buscar perfil por nombre de usuario
          const response = await medicGeekApi.get(`/profile/user/${username}`);
          const data = response.data;

          // Verificar si se recibió el perfil
          if (data) {
            setProfileData(data);
            setIsEditing(true);
          } else {
            console.error("No se encontró el perfil del usuario.");
          }
        } else {
          console.error("No se pudo obtener el nombre de usuario del token.");
        }
      } else {
        console.error("No se encontró el token.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchLabTestParams = async () => {
    try {
      const response = await medicGeekApi.get('/labtestparam');
      if (response.status === 200) {
        setLabTestsParam(response.data);
      }
    } catch (error) {
      console.error("Error fetching lab test params:", error);
    }
  };

  const sortData = (column) => {
    const isAscending = sortColumn === column && sortDirection === 'asc';
    
    // Define the sorting function
    const sortFunction = (a, b) => {
      if (column === 'idLabTest') {
        // Convert to number for correct numeric sorting
        return (isAscending ? a - b : b - a);
      } else if (column === 'createdAt') {
        // Handle date sorting
        return isAscending
          ? new Date(a) - new Date(b)
          : new Date(b) - new Date(a);
      } else {
        // Handle string sorting
        return isAscending
          ? a.localeCompare(b)
          : b.localeCompare(a);
      }
    };
    
    // Sort the data
    const sortedData = [...labTests].sort((a, b) => sortFunction(a[column], b[column]));
  
    setLabTests(sortedData); // Update the state with the sorted data
    setSortColumn(column);
    setSortDirection(isAscending ? 'desc' : 'asc');
  };
  
  

  const activeLabTests = labTestsParam.filter(
    (param) => param.estatus === 'Activo'
  );
  
  // Función para manejar el cambio de los checkboxes
  const handleCheckboxChange = (analisisNm) => {
    if (selectedTests.includes(analisisNm)) {
      // Si el test ya está seleccionado, lo quitamos
      setSelectedTests(selectedTests.filter((test) => test !== analisisNm));
    } else {
      // Si el test no está seleccionado, lo añadimos
      setSelectedTests([...selectedTests, analisisNm]);
    }
  };

  const pageCount = Math.ceil(labTests.length / labTestsPerPage);

  // Filtro para los pacientes en funcion de termino de busqueda
  const filteredPacientes = pacientes.filter((paciente) => {
    const fullName =
      `${paciente.pcteNom} ${paciente.pcteNom2} ${paciente.pcteApe1} ${paciente.pcteApe2}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedlabTests = labTests
    .filter((paciente) => {
      const searchWords = searchTerm.toLowerCase().split(" ");

      return searchWords.some(
        (word) =>
          paciente.pcteNom.toLowerCase().includes(word) ||
          paciente.pcteNom2.toLowerCase().includes(word) ||
          paciente.pcteApe1.toLowerCase().includes(word)
      );
    })
    .slice(
      pageNumber * labTestsPerPage,
      (pageNumber + 1) * labTestsPerPage
    );

  const guardarLabTests = () => {
    const fechaActual = new Date();
    const dia = String(fechaActual.getDate()).padStart(2, "0");
    const mes = String(fechaActual.getMonth() + 1).padStart(2, "0"); // Meses empiezan en 0
    const año = fechaActual.getFullYear();
    const ampm = fechaActual.getHours() >= 12 ? "PM" : "AM";
    let horas = fechaActual.getHours() % 12;
    horas = horas === 0 ? 12 : horas; // Si es 0, establece 12
    const minutos = String(fechaActual.getMinutes()).padStart(2, "0");
  
    const fechaHoraFormateada = `${dia}/${mes}/${año} ${horas}:${minutos} ${ampm}`;
  
  
    // Recopilar los análisis seleccionados
    const selectedDynamicFields = labTestsParam
      .filter((test) => selectedTests.includes(test.AnalisisNm)) // Filtrar los análisis seleccionados
      .map((test) => ({
        idLabTestParam: test.idLabTestParam,
        AnalisisNm: test.AnalisisNm,
      }));
  
    // Crear el objeto para enviar al backend
    const nuevoLabTests = {
      pcteId: selectedPaciente ? selectedPaciente.pcteId : null, // ID del paciente seleccionado
      dynamicFields: selectedDynamicFields, // Los análisis seleccionados
      profileId: profileData ? profileData.profileId : null, // ID del perfil del usuario
      createdAt: fechaHoraFormateada, // Fecha y hora actuales
      
    };
  
    // Enviar los datos al backend
    medicGeekApi
      .post("/labtest", nuevoLabTests)
      .then((response) => {
        closeModal();
        showSuccessAlert("Análisis guardado con éxito.");
        fetchData(); // Refresca los datos después de guardar
      })
      .catch((error) => {
        showErrorAlert("Error al guardar los análisis.");
        // console.error("Error al guardar los análisis", error);
      });
  };

  const areDynamicFieldsEqual = (fields1, fields2) => {
    // Verificar si ambos arrays tienen el mismo número de elementos
    if (fields1.length !== fields2.length) return false;
  
    // Obtener solo los nombres de los análisis
    const getNames = fields => fields.map(field => field.AnalisisNm).sort();
    
    // Comparar los arrays de nombres ordenados
    const sortedNames1 = getNames(fields1);
    const sortedNames2 = getNames(fields2);
  
    return sortedNames1.every((name, index) => name === sortedNames2[index]);
  };
  
  
  const editLabTests = () => {
    if (selectedLabTestsLocal && selectedLabTestsLocal.idLabTest) {
      const { idLabTest, dynamicFields: originalDynamicFields } = selectedLabTestsLocal;
  
      // Completar `originalDynamicFields` con `isSelected` por defecto
      const originalDynamicFieldsComplete = originalDynamicFields.map(field => ({
        AnalisisNm: field.AnalisisNm,
        isSelected: field.isSelected !== undefined ? field.isSelected : false,
      }));
  
      // Recopilar los análisis seleccionados
      const selectedDynamicFields = labTestsParam
        .filter((test) => selectedTests.includes(test.AnalisisNm)) // Filtrar los análisis seleccionados
        .map((test) => ({
          AnalisisNm: test.AnalisisNm,
          isSelected: selectedTests.includes(test.AnalisisNm),
        }));

      // Ordenar y comparar los arrays
      const hasChanges = !areDynamicFieldsEqual(originalDynamicFieldsComplete, selectedDynamicFields);
  
      const fechaActual = new Date();
      const dia = String(fechaActual.getDate()).padStart(2, "0");
      const mes = String(fechaActual.getMonth() + 1).padStart(2, "0"); // Meses empiezan en 0
      const año = fechaActual.getFullYear();
      const ampm = fechaActual.getHours() >= 12 ? "PM" : "AM";
      let horas = fechaActual.getHours() % 12;
      horas = horas === 0 ? 12 : horas; // Si es 0, establece 12
      const minutos = String(fechaActual.getMinutes()).padStart(2, "0");
  
      const fechaHoraFormateada = `${dia}/${mes}/${año} ${horas}:${minutos} ${ampm}`;
  
      if (hasChanges) {
        // Crear el objeto para enviar al backend
        const newLabTests = {
          pcteId: selectedPaciente ? selectedPaciente.pcteId : null, // ID del paciente seleccionado
          profileId: profileData ? profileData.profileId : null, // ID del perfil del usuario
          dynamicFields: selectedDynamicFields, // Los análisis seleccionados
          createdAt: fechaHoraFormateada, // Fecha y hora actuales
        };
  
        // Mostrar alerta antes de realizar la solicitud
        showSuccessAlert("Análisis creado con éxito.");
        
        // Realiza una solicitud POST para crear un nuevo registro en el servidor
        medicGeekApi
          .post("/labtest", newLabTests)
          .then((response) => {
            closeModal();
            showSuccessAlert("Análisis creado con éxito.");
            fetchData(); // Refresca los datos después de crear el nuevo registro
          })
          .catch((error) => {
            showErrorAlert("Error al crear el análisis.");
            console.error("Error al crear el análisis", error);
          });
      } else {
        showInfoAlert("No se han detectado cambios.");
      }
    } else {
      showErrorAlert("No se puede editar: registro no válido.");
    }
  };
  
  const handlePacienteSelection = (paciente) => {
    setSelectedPaciente(paciente);
    setModalOpen(false); // Cierra el modal actual
    openPacienteModal(); // Abre el nuevo modal para el paciente seleccionado
  };

  const openPacienteModal = () => {
    setModalOpen(true);
  };

  function convertirFechaLarga(fechaStr) {
    const opcionesFecha = { day: "numeric", month: "long", year: "numeric" };
    const opcionesHora = { hour: "numeric", minute: "numeric", hour12: true };

    // Convertir la cadena de fecha a un objeto Date
    const fecha = new Date(fechaStr);

    // Obtener partes de la fecha
    const fechaLarga = new Intl.DateTimeFormat("es-ES", opcionesFecha).format(
      fecha
    );
    const horaLarga = new Intl.DateTimeFormat("es-ES", opcionesHora).format(
      fecha
    );

    // Construir la cadena en formato largo
    const partesFecha = fechaLarga.split(" de ");
    const dia = partesFecha[0];
    const mes = partesFecha[1];
    const año = partesFecha[2];

    return `día ${dia} del mes de ${mes} del año ${año} a las ${horaLarga}`;
  }

  const fechaStr = "6/6/2024 9:44 AM";
  const fechaLarga = convertirFechaLarga(fechaStr);

  const previsualizarPDF = async (certMedico) => {
    const doc = new jsPDF();

    // Define las variables de estilo
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;
    const additionalMargin = 25; // Margen adicional de altura
    const fontSize = 12;

    doc.setFontSize(fontSize);

    const fechaLarga = convertirFechaLarga(certMedico.certifi_fe);

    const detalleText = `
    CERTIFICADO MEDICO
    `;

    const profileText = `
Yo, ${profileData.docNom}, Médico ${profileData.docEsp} CED. ${profileData.docCed}, provisto del correspondiente exequátur No. ${profileData.docExe} Certifico examinado a ${certMedico.pcteNom} ${certMedico.pcteNom2} ${certMedico.pcteApe1} ${certMedico.pcteApe2} Cédula de identidad y electoral No. ${selectedPaciente.pcteNumDoc}
    `;

    const certificarText = `
Certifico:
${certMedico.constatado}
  `;

    const recomendarText = `
Por lo que recomiendo:
${certMedico.recomedacion}
`;

    const footerText = `
${profileData.docDirCert} a petición del interesado, hoy ${fechaLarga}`;

    const contenido = `
  ${detalleText}
  ${profileText}
  ${certificarText}
  ${recomendarText}
    `;

    const lines = doc.splitTextToSize(contenido, pageWidth - 2 * margin);
    let y = margin + additionalMargin; // Coordenada y inicial con margen adicional
    const lineHeight = fontSize * 0.6;

    // Agrega el contenido línea por línea respetando los saltos de línea
    lines.forEach((line) => {
      if (
        y + lineHeight >
        pageHeight - margin - additionalMargin - lineHeight
      ) {
        doc.addPage();
        y = margin + additionalMargin; // Resetea la coordenada y en la nueva página con margen adicional
      }
      doc.text(line, margin, y);
      y += lineHeight;
    });

    // Asegúrate de que el footerText no se divida entre páginas
    const footerLines = doc.splitTextToSize(footerText, pageWidth - 2 * margin);
    if (
      y + footerLines.length * lineHeight >
      pageHeight - margin - additionalMargin
    ) {
      doc.addPage();
      y = margin + additionalMargin;
    }

    // Agrega el footerText
    footerLines.forEach((line) => {
      doc.text(line, margin, y);
      y += lineHeight;
    });

    // Abre una ventana emergente para previsualizar el PDF
    window.open(doc.output("bloburl"), "_blank");
  };


  return (
    <div>
      <div>
        <div
          className="d-flex justify-content-between miDivConGradiente px-3 rounded-bottom"
          style={{
            height: "5rem",
          }}
        >
          <div className="d-flex align-items-center text-white fw-bold text-uppercase">
            <h2>Prueba de Lab.</h2>
          </div>
          <div className="d-flex justify-content-right align-items-center">
            <form className="d-flex" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Buscar"
                aria-label="Buscar"
                value={searchTerm}
                onChange={handleSearchTermChange} // Manejar cambios en el término de búsqueda
              />
            </form>
            <button
              type="button"
              className="btn btn-danger"
              title="Crear Nuevo seguro"
              onClick={() => openModal(null, 10)}
            >
              <i className="bi bi-person-add"></i> Nueva Prueba de Lab.
            </button>
          </div>
        </div>
        <div
          className="mb-2"
          style={{
            height: "45vh",
            padding: "0rem 1rem",
          }}
        >
          <table className="table table-striped table-hover mt-5">
  <thead>
    <tr>
      <th onClick={() => sortData('idLabTest')}>
        ID {sortColumn === 'idLabTest' && (sortDirection === 'asc' ? " 🔼" : " 🔽")}
      </th>
      <th onClick={() => sortData('pcteNom')}>
        Paciente {sortColumn === 'pcteNom' && (sortDirection === 'asc' ? " 🔼" : " 🔽")}
      </th>
      <th onClick={() => sortData('dynamicFields')}>
        Nombre del Análisis {sortColumn === 'dynamicFields' && (sortDirection === 'asc' ? " 🔼" : " 🔽")}
      </th>
      <th onClick={() => sortData('createdAt')}>
        Fecha Prueba de Lab. {sortColumn === 'createdAt' && (sortDirection === 'asc' ? " 🔼" : " 🔽")}
      </th>
      <th>Acción</th>
      {/* Agrega más encabezados según sea necesario */}
    </tr>
  </thead>
  <tbody>
    {labTests.map((test) => (
      <tr key={test.idLabTest}>
        <td>{test.idLabTest}</td>
        <td>
          {test.paciente ? `${test.pcteNom} ${test.pcteApe1}` : 'N/A'}
        </td>
        <td
          style={{ width: "40%" }}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={test.dynamicFields.map((field) => field.AnalisisNm).join(', ')} // Mostrar todos los análisis en el tooltip
        >
          {test.dynamicFields && test.dynamicFields.length > 0
            ? test.dynamicFields.map((field) => field.AnalisisNm).join(', ').length > 30
              ? `${test.dynamicFields.map((field) => field.AnalisisNm).join(', ').slice(0, 30)}...`
              : test.dynamicFields.map((field) => field.AnalisisNm).join(', ')
            : 'N/A'}
        </td>
        <td>{test.createdAt || 'N/A'}</td>
        <td
          style={{
            width: "5%",
          }}
        >
          <div className="">
            <button
              type="button"
              className="p-1 border-0"
              title="Editar"
              onClick={() => openModal(test)}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            {/* <button 
                  type='button' 
                  className='p-1 border-0' 
                  title="Imprimir"
                >
                  <i className="bi bi-printer"></i>
                </button> */}
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>



          {/* Paginación */}
          {!showForm && (
          <div className="d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                
                {/* Botón de página anterior */}
                <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                  <span
                    className="page-link"
                    aria-label="Previous"
                    onClick={() => handlePageChange({ selected: pageNumber - 1 })}
                    style={{ cursor: pageNumber === 0 ? "not-allowed" : "pointer" }}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </span>
                </li>

                {Array.from(
                  { length: Math.min(pageCount, 10) },
                  (_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        pageNumber === index ? " active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="page-link"
                        onClick={() => handlePageChange({ selected: index })}
                      >
                        {index + 1}
                      </span>
                    </li>
                  )
                )}

                {/* Botón de siguiente página */}
                <li className={`page-item ${pageNumber === pageCount - 1 ? "disabled" : ""}`}>
                  <span
                    className="page-link"
                    aria-label="Next"
                    onClick={() => handlePageChange({ selected: pageNumber + 1 })}
                    style={{ cursor: pageNumber === pageCount - 1 ? "not-allowed" : "pointer" }}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </span>
                </li>
                
              </ul>
            </nav>
          </div>
        )}
        </div>

        {currentForm === "LabTestList" && selectedLabTestsLocal && (
          <div className="main-content"></div>
        )}
        <Modal
         isOpen={modalOpen}
         toggle={closeModal}
         modalClassName="custom-modal"
         contentClassName="custom-modal-content"
         style={{ maxWidth: "40rem" }}
        >
          <ModalHeader toggle={closeModal}>
            {isEditing
              ? "Editar Certificado Médico"
              : "Crear Nuevo Certificado Médico"}
          </ModalHeader>
          <ModalBody>
            {selectedPaciente ? (
              <div>
                <div class="mb-4 sticky-top pt-3 border-bottom bg-primary text-white px-2 rounded-3">
                  <div className="d-flex justify-content-start">
                    <img
                      alt="Sample"
                      src="https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png"
                      style={{
                        width: "3rem",
                        marginTop: "1rem",
                      }}
                    />
                    <div className="input-group mx-2 input-group-sm text-uppercase fw-bold d-flex align-items-center">
                      <label className="border-0 fs-4" id="pcteNom">
                        {selectedPaciente.pcteNom} {selectedPaciente.pcteNom2}{" "}
                        {selectedPaciente.pcteApe1} {selectedPaciente.pcteApe2}
                      </label>
                    </div>
                    <div className="border-0 fs-6 d-flex align-items-center">
                      <label className="text-end" id="certifi_fe">
                        {formulario.fechaCertMedico
                          ? format(
                              new Date(formulario.fechaCertMedico),
                              "dd/MM/yyyy hh:mm a"
                            )
                          : ""}
                      </label>
                    </div>
                  </div>
                  <div class="d-flex bd-highlight mb-3">
                    <div className="d-flex justify-content-start">
                      <label className="border-0 fs-4 m-1" id="pcteEd">
                        {selectedPaciente.pcteEd} |
                      </label>
                      <label className="border-0 fs-4 m-1" id="pcteNumDoc">
                        {selectedPaciente.pcteNumDoc} |
                      </label>
                      <label className="border-0 fs-4 m-1" id="pcteSx">
                        {selectedPaciente.pcteSx}
                      </label>
                    </div>
                  </div>
                  <div></div>
                </div>
                <Form className="my-4">
                  <h5>Seleccionar Análisis</h5>
                  <div className="d-flex flex-wrap">
                    <div className="row w-100">
                    {activeLabTests.map((param, index) => (
                      <div className="col-md-4 col-sm-6 mb-3" key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`analisis-${param.idLabTestParam}`}
                            value={param.AnalisisNm}
                            onChange={() => handleCheckboxChange(param.AnalisisNm)}
                            checked={selectedTests.includes(param.AnalisisNm)} // Marcar según estado
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`analisis-${param.idLabTestParam}`}
                          >
                            {param.AnalisisNm}
                          </label>
                        </div>
                      </div>
                    ))}

                    </div>
                  </div>
                </Form>
              </div>
            ) : (
              <div>
                <Form className="mb-4 sticky-top pt-3 border-bottom bg-primary text-white px-2 rounded-3">
                  <FormGroup>
                    <Label for="search">Buscar por nombre:</Label>
                    <Input
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Nombre del paciente"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </FormGroup>
                </Form>
                <ListGroup>
                  {filteredPacientes
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((paciente) => (
                      <ListGroupItem key={paciente.pcteId}>
                        <div className="d-flex justify-content-between">
                          <span>{`${paciente.pcteNom} ${paciente.pcteNom2} ${paciente.pcteApe1} ${paciente.pcteApe2}`}</span>
                          <Button
                            color="primary"
                            title="Nuevo Certificado Médico"
                            onClick={() => handlePacienteSelection(paciente)}
                          >
                            <i class="bi bi-clipboard-check-fill"></i>
                          </Button>
                        </div>
                      </ListGroupItem>
                    ))}
                </ListGroup>
                <nav className="pt-3 d-flex justify-content-center">
                  <ul className="pagination">
                    {Array.from(
                      {
                        length: Math.ceil(
                          filteredPacientes.length / itemsPerPage
                        ),
                      },
                      (_, index) => (
                        <li key={index} className="page-item">
                          <button
                            onClick={() => setCurrentPage(index + 1)}
                            className="page-link"
                          >
                            {index + 1}
                          </button>
                        </li>
                      )
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {selectedPaciente ? (
              <>
                <Button
                  className="button-save text-primary"
                  color="primary"
                  onClick={isEditing ? editLabTests : guardarLabTests}
                  style={{
                    height: "3rem",
                  }}
                >
                  <i class="bi bi-floppy-fill pe-1"></i>
                  {isEditing ? "Guardar Edición" : "Guardar"}
                </Button>

                <Button
                  className="button-print"
                  // onClick={() => previsualizarPDF(selectedCertMedicosLocal)}
                  type="button"
                  style={{
                    color: "#cb05f3",
                    height: "3rem",
                    marginLeft: "10px",
                  }}
                >
                  <i className="bbi bi-printer-fill pe-1"></i>
                  Imprimir
                </Button>
              </>
            ) : (
              <Button
                className="button-cancel text-secondary"
                onClick={closeModal}
                style={{ height: "3rem" }}
              >
                Cancelar
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};
export default LabTestList;
