import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  showErrorAlert,
  showSuccessAlert,
  showSuccessEditAlert,
} from "../../../components/alerts";
import medicGeekApi from "../../../config/axiosConfig";

const ExternalCauseForm = ({ currentForm }) => {
  const [externalCause, setExternalCause] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const externalCausePerPage = 10;
  const [selectedExternalCauseLocal, setSelectedExternalCauseLocal] =
    useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [formulario, setFormulario] = useState({
    nombreExternalCause: "",
    descripcionExternalCause: "",
    estatusExternalCause: "activo",
  });

  const openModal = (externalCause) => {
    setSelectedExternalCauseLocal(externalCause); // Configura el ExternalCause seleccionado para la edición

    if (externalCause) {
      setIsEditing(true); // Establecer como modo edición cuando se abre el modal
      setFormulario({
        nombreExternalCause: externalCause.externalCauseNm,
        descripcionExternalCause: externalCause.externalCauseDesc,
        estatusExternalCause: externalCause.estatus ? "activo" : "inactivo",
      });
    } else {
      setIsEditing(false); // Establecer como modo creación cuando se abre el modal para crear uno nuevo
      // Limpia los campos si es necesario
      setFormulario({
        nombreExternalCause: "",
        descripcionExternalCause: "",
        estatusExternalCause: "activo",
      });
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedExternalCauseLocal(null); // Limpia el ExternalCause seleccionado
    setIsEditing(false); // Resetear el modo edición cuando se cierra el modal
    setModalOpen(false);
  };

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await medicGeekApi.get(
          `/externalCauses?search=${searchTerm}`,
          {
            params: {
              $or: [
                { causeNm: { $regex: searchTerm, $options: "i" } },
                { causeDesc: { $regex: searchTerm, $options: "i" } },
              ],
            },
          }
        );
        const sortedExternalCauses = response.data.sort(
          (a, b) => a.causeId - b.causeId
        );
        setExternalCause(sortedExternalCauses);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [searchTerm]);

  const pageCount = Math.ceil(externalCause.length / externalCausePerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedExternalCauses = externalCause
    .filter((externalCause) => {
      // Filtrar externalCauses basados en el término de búsqueda
      return (
        externalCause.causeNm
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        externalCause.causeDesc.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .slice(
      pageNumber * externalCausePerPage,
      (pageNumber + 1) * externalCausePerPage
    );

  const updateExternalCauseForm = () => {
    medicGeekApi
      .get("/externalCauses")
      .then((response) => {
        setExternalCause(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const guardarNuevoExternalCause = () => {
    const nuevoExternalCause = {
      causeNm: document.getElementById("nombreExternalCause").value,
      causeDesc: document.getElementById("descripcionExternalCause").value,
      estatus:
        document.getElementById("estatusExternalCause").value === "activo"
          ? "Activo"
          : "Inactivo",
    };
    medicGeekApi
      .post("/externalCauses", nuevoExternalCause)
      .then((response) => {
        showSuccessAlert();
        closeModal();
        updateExternalCauseForm();
      })
      .catch((error) => {
        console.error("Error al crear el ExternalCause", error);
      });
  };

  const editarExternalCause = () => {
    if (selectedExternalCauseLocal && selectedExternalCauseLocal.causeId) {
      const { causeId } = selectedExternalCauseLocal;
      const nombreExternalCause = document.getElementById(
        "nombreExternalCause"
      ).value;
      const descripcionExternalCause = document.getElementById(
        "descripcionExternalCause"
      ).value;
      const estatusExternalCause =
        document.getElementById("estatusExternalCause").value === "activo"
          ? "Activo"
          : "Inactivo";

      const updatedExternalCause = {
        causeId, // Asegurarse de enviar el ID
        causeNm: nombreExternalCause,
        causeDesc: descripcionExternalCause,
        estatus: estatusExternalCause,
      };

      medicGeekApi
        .patch(`/externalCauses/${causeId}`, updatedExternalCause)
        .then((response) => {
          showSuccessEditAlert();
          closeModal();
          updateExternalCauseForm();
        })
        .catch((error) => {
          console.error("Error al actualizar el ExternalCause", error);
        });
    } else {
      // Manejo de error o mensaje de que no se puede actualizar
      showErrorAlert();
      console.error("No se puede actualizar: ExternalCause no válido.");
    }
  };

  return (
    <div>
      <div>
        <div className="d-flex flex-column flex-md-row justify-content-between miDivConGradiente px-3 rounded-bottom">
          <div className="d-flex align-items-center text-white mb-2 mb-md-0">
            <h2>Causa Externa</h2>
          </div>
          <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0">
            <form className="d-flex mb-2 mb-md-0" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Buscar"
                aria-label="Buscar"
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </form>
            <button
              type="button"
              className="btn btn-danger ms-2"
              title="Crear Nueva Causa Externa"
              onClick={() => openModal(null)}
            >
              <i className="bi bi-file-plus"></i> Nueva Causa Externa
            </button>
          </div>
        </div>
        
        <div className="mb-2 table-responsive">
          <div className="table d-none d-md-table " style={{ height: "45vh", padding: "0rem 1rem" }}>
            <table className="table table-striped table-hover mt-5">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre Causa Externa</th>
                  <th>Descripción del Caso Externo</th>
                  <th>Estatus</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {displayedExternalCauses.map((externalCause) => (
                  <tr key={externalCause.causeId}>
                    <td>{externalCause.causeId}</td>
                    <td>{externalCause.causeNm}</td>
                    <td
                      style={{ width: "30%"}}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={externalCause.causeDesc}
                    >
                      {externalCause.causeDesc?.length > 20
                        ? `${externalCause.causeDesc.slice(0, 30)}...`
                        : externalCause.causeDesc}
                    </td>
                    <td>{externalCause.estatus}</td>
                    <td>
                      <div>
                        <button
                          type="button"
                          className="p-1 border-0"
                          title="Editar"
                          onClick={() => openModal(externalCause)}
                        >
                          <i className="bi bi-pencil-square"></i>
                        </button>
                        <button
                          type="button"
                          className="p-1 border-0"
                          title="Eliminar"
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Listado en modo card para móviles */}
          <div className="d-md-none">
            {displayedExternalCauses.map((externalCause) => (
              <div key={externalCause.causeId} className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">ID: {externalCause.causeId}</h5>
                  <h6 className="card-subtitle mb-2 text-muted">{externalCause.causeNm}</h6>
                  <p className="card-text">{externalCause.causeDesc}</p>
                  <p className="card-text">
                    <strong>Estatus:</strong> {externalCause.estatus}
                  </p>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary me-2"
                      onClick={() => openModal(externalCause)}
                    >
                      Editar
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        {/* Paginación */}
        {!showForm && (
          <div className="d-flex justify-content-end" style={{ padding: "0rem 1rem" }}>
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                
                {/* Botón de página anterior */}
                <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                  <span
                    className="page-link"
                    aria-label="Previous"
                    onClick={() => handlePageChange({ selected: pageNumber - 1 })}
                    style={{ cursor: pageNumber === 0 ? "not-allowed" : "pointer" }}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </span>
                </li>

                {Array.from(
                  { length: Math.min(pageCount, 10) },
                  (_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        pageNumber === index ? " active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="page-link"
                        onClick={() => handlePageChange({ selected: index })}
                      >
                        {index + 1}
                      </span>
                    </li>
                  )
                )}

                {/* Botón de siguiente página */}
                <li className={`page-item ${pageNumber === pageCount - 1 ? "disabled" : ""}`}>
                  <span
                    className="page-link"
                    aria-label="Next"
                    onClick={() => handlePageChange({ selected: pageNumber + 1 })}
                    style={{ cursor: pageNumber === pageCount - 1 ? "not-allowed" : "pointer" }}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </span>
                </li>
                
              </ul>
            </nav>
          </div>
        )}
        
        {/* Formulario de edición/creación */}
        {currentForm === "externalCauseForm" && selectedExternalCauseLocal && (
          <div className="main-content"></div>
        )}
        
        <Modal isOpen={modalOpen} toggle={closeModal}>
          <ModalHeader toggle={closeModal}>
            {isEditing ? "Editar Causa Externa" : "Crear Nueva Causa Externa"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="nombreExternalCause">Nombre Causa Externa</Label>
                <Input
                  type="text"
                  id="nombreExternalCause"
                  value={formulario.nombreExternalCause}
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      nombreExternalCause: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="descripcionExternalCause">Descripción</Label>
                <Input
                  type="text"
                  id="descripcionExternalCause"
                  value={formulario.descripcionExternalCause}
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      descripcionExternalCause: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="estatusExternalCause">Estado</Label>
                <Input
                  type="select"
                  id="estatusExternalCause"
                  value={formulario.estatusExternalCause}
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      estatusExternalCause: e.target.value,
                    })
                  }
                >
                  <option value="activo">Activo</option>
                  <option value="inactivo">Inactivo</option>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="button-cancel text-secondary"
              onClick={closeModal}
            >
              Cancelar
            </Button>
            <Button
              className="button-save text-primary"
              onClick={
                isEditing ? editarExternalCause : guardarNuevoExternalCause
              }
            >
              {isEditing ? "Guardar Edición" : "Guardar"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};
export default ExternalCauseForm;
