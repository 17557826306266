import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import medicGeekApi from "../../../config/axiosConfig";

const CategoryList = ({ currentForm }) => {
  const [cie10s, setCie10s] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const cie10sPerPage = 15;
  const [selectedCie10Local, setSelectedCie10Local] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedPages, setDisplayedPages] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Estado para mostrar el esqueleto
  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const fetchData = async () => {
    try {
      // Simulación de carga con un tiempo de espera de 1 segundo
      setTimeout(async () => {
        const response = await medicGeekApi.get(`/cie10s?search=${searchTerm}`);
        const sortedCie10 = response.data.sort((a, b) => a.code - b.code);

        setCie10s(sortedCie10);
        setIsLoading(false); // Indicar que la carga ha terminado
      }, 1000); // 1 segundo simulando una carga real
    } catch (error) {
      console.log(error);
    }
  };

  const pageCount = Math.ceil(cie10s?.length / cie10sPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
    const currentPageGroup = Math.floor(selected / 10);

    if (selected % 10 === 0) {
      // Calcula el número de la primera página del siguiente grupo de 10
      const nextPageGroup = (currentPageGroup + 1) * 10;
      const maxPage = Math.min(pageCount, nextPageGroup);

      const newDisplayedPages = Array.from(
        { length: Math.min(pageCount, maxPage) },
        (_, index) => index + nextPageGroup * 10
      );
      setDisplayedPages(newDisplayedPages);
    }
  };

  const displayedCie10 = cie10s
    .filter((cie10) => {
      return (
        cie10.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cie10.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .slice(pageNumber * cie10sPerPage, (pageNumber + 1) * cie10sPerPage);

  return (
    <div>
      <div>
        <div
          className="d-flex justify-content-between miDivConGradiente px-3 rounded-bottom"
          style={{
            height: "5rem",
          }}
        >
          <div className="d-flex align-items-center text-white fw-bold text-uppercase">
            <h2>cie-10</h2>
          </div>
        </div>
        <div
          className="mb-2"
          style={{
            height: "60vh",
            padding: "0rem 1rem",
          }}
        >
          <div
            className="d-flex justify-content-right align-items-center w-100 px-3 py-3 miDivConGradiente mt-3 rounded-2"
            style={{
              height: "5rem",
            }}
          >
            <form className="d-flex w-100" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Buscar"
                aria-label="Buscar"
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </form>
          </div>

          <table className="table table-striped table-hover mt-2 text-start">
            <thead>
              <tr>
                <th>ID</th>
                <th>Descripcion</th>
              </tr>
            </thead>
            <tbody>
              {isLoading
                ? Array.from({ length: 10 }).map((_, index) => (
                    <tr key={index}>
                      <td style={{ width: "10%" }}>
                        <Skeleton />
                        <div
                          class="rounded-pill"
                          style={{
                            width: "100%",
                            height: "1rem",
                            padding: "0",
                            margin: "0",
                            backgroundColor: "gray",
                          }}
                        ></div>
                      </td>
                      <td style={{ width: "90%" }}>
                        <Skeleton />
                        <div
                          class="rounded-pill"
                          style={{
                            width: "100%",
                            height: "1rem",
                            padding: "0",
                            margin: "0",
                            backgroundColor: "gray",
                          }}
                        ></div>
                      </td>
                    </tr>
                  ))
                : displayedCie10.map((cie10s) => (
                    <tr key={cie10s.code}>
                      <td style={{ width: "10%" }}>{cie10s.code}</td>
                      <td style={{ width: "90%" }}>{cie10s.description}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {/* Paginación */}
          <div className="d-flex justify-content-center">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className={`page-item`} style={{ cursor: "pointer" }}>
                  <span
                    className="page-link"
                    onClick={() => handlePageChange({ selected: 0 })}
                  >
                    Inicio
                  </span>
                </li>
                <li className={`page-item`} style={{ cursor: "pointer" }}>
                  <span
                    className="page-link"
                    style={{
                      height: "2.4rem",
                      width: "7rem",
                    }}
                    onClick={() =>
                      handlePageChange({
                        selected: Math.max(0, pageNumber - 10),
                      })
                    }
                  >
                    Anterior 10
                  </span>
                </li>
                {Array.from({ length: Math.min(pageCount, 10) }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      pageNumber === index ? " active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="page-link"
                      onClick={() => handlePageChange({ selected: index })}
                    >
                      {index + 1}
                    </span>
                  </li>
                ))}
                <li className={`page-item`} style={{ cursor: "pointer" }}>
                  <span
                    className="page-link"
                    style={{
                      height: "2.4rem",
                      width: "7.5rem",
                    }}
                    onClick={() =>
                      handlePageChange({
                        selected: Math.min(pageCount - 1, pageNumber + 10),
                      })
                    }
                  >
                    Siguiente 10
                  </span>
                </li>
                <li className={`page-item`} style={{ cursor: "pointer" }}>
                  <span
                    className="page-link"
                    onClick={() =>
                      handlePageChange({ selected: pageCount - 1 })
                    }
                  >
                    Final
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CategoryList;
