// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar{
  width: 1rem;
  
}
::-webkit-scrollbar-track {
  background-color: #f5efef;
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb {
  background-color: #073572;
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #0f58b8;
}

.main-content {
  overflow: hidden; /* Oculta cualquier contenido que se desborde */
}`, "",{"version":3,"sources":["webpack://./src/styles/scrollbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;;AAEb;AACA;EACE,yBAAyB;EACzB,oBAAoB;AACtB;AACA;EACE,yBAAyB;EACzB,oBAAoB;AACtB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB,EAAE,+CAA+C;AACnE","sourcesContent":["::-webkit-scrollbar{\n  width: 1rem;\n  \n}\n::-webkit-scrollbar-track {\n  background-color: #f5efef;\n  border-radius: 100vw;\n}\n::-webkit-scrollbar-thumb {\n  background-color: #073572;\n  border-radius: 100vw;\n}\n::-webkit-scrollbar-thumb:hover {\n  background-color: #0f58b8;\n}\n\n.main-content {\n  overflow: hidden; /* Oculta cualquier contenido que se desborde */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
