// RestoreForm.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Importa el hook useNavigate
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import imagenTransparente from "../../assets/images/3-transparente.png"; // Asegúrate de ajustar la ruta según tu estructura de carpetas
import { useAuth } from "../../AuthContext"; // Asegúrate de ajustar la ruta de importación según sea necesario
import medicGeekApi from "../../config/axiosConfig";
import { showErrorAlert, showSuccessAlert } from "./../../components/alerts";

const RestoreForm = () => {
  const { setIsAuthenticated } = useAuth(); // Obtén setIsAuthenticated del contexto de autenticación
  const [formData, setFormData] = useState({
    user: "",
    userpass: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await medicGeekApi.get(`/profile/${formData.user}`);
      console.log(response.data);

      // Verifica la respuesta para determinar si el usuario existe
      if (response.data.exists) {
        // Usuario existe
        console.log("El usuario existe");
        // Mostrar mensaje o realizar acciones adicionales
        showSuccessAlert("Usuario encontrado en el sistema.");
      } else {
        // Usuario no existe
        console.log("El usuario no existe");
        // Mostrar mensaje o realizar acciones adicionales
        showErrorAlert("Usuario no encontrado en el sistema.");
      }
    } catch (error) {
      // Manejo de errores
      console.error("Error al consultar el perfil:", error);
      // Mostrar mensaje de error o realizar acciones adicionales
      showErrorAlert(
        "Error al consultar el perfil. Por favor, inténtelo de nuevo más tarde."
      );
    }
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    navigate("/#/login"); // Navega al formulario de restauración
  };

  return (
    <div
      className="row shadow-sm p-3 mb-5 bg-body-tertiary rounded"
      style={{ width: "100%", height: "50rem" }}
    >
      <div className="d-flex" style={{ width: "100%", height: "100%" }}>
        <div
          className="shadow bg-white-tertiary rounded d-flex align-items-center justify-content-center"
          style={{ height: "100%", width: "60%" }}
        >
          <img
            src={imagenTransparente}
            className="card-img-top"
            alt="Imagen de logo"
            style={{
              height: "20rem",
              width: "40rem",
            }}
          ></img>
        </div>

        <div
          className="d-flex flex-column justify-content-around px-3"
          style={{ width: "40%" }}
        >
          <div className="d-flex align-items-start flex-column justify-content-center">
            <div
              className="d-flex justify-content-center"
              style={{ width: "100%", height: "5rem" }}
            >
              <label className="fs-4 py-3">Recuperar contraseña</label>
            </div>

            <div
              className="text-start fw-bold d-flex flex-column"
              style={{
                width: "100%",
                padding: "1rem 1rem",
              }}
            >
              <Col md={12}>
                <FormGroup floating>
                  <Input
                    style={{ height: "6rem" }}
                    type="text"
                    className="form-control border border-0 bg-body-tertiary"
                    id="user"
                    value={formData.user}
                    onChange={handleChange}
                    placeholder="Escriba su usuario"
                  />
                  <Label htmlFor="pcteNom">
                    <i className="bi bi-person-fill-lock fs-3 pe-1"></i>
                    Usuario/correo electrónico
                  </Label>
                </FormGroup>
              </Col>
            </div>
            <div
              className="d-flex justify-content-around px-5"
              style={{ width: "100%", height: "5rem" }}
            >
              <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Button
                  style={{ width: "100%" }}
                  className="btn button-login m-2"
                  type="submit"
                >
                  Recuperar
                </Button>
              </Form>
            </div>
            <div
              className="d-flex justify-content-center py-5 text-center "
              style={{ width: "100%" }}
            >
              <label className="" style={{ width: "100%" }}>
                Si no recuerdas tu usuario ni tu correo por favor contácta a
                soporte{" "}
              </label>
            </div>
            <div
              className="text-center d-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <a href="!#" onClick={handleLoginClick}>
                Regresar al Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestoreForm;
