import { jwtDecode } from "jwt-decode"; // Importa con destructuración
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import imagenCompanyGeek from "../assets/images/geek.png";
import imagenCompany from "../assets/images/MedicGeek-fondo1.png";
import medicGeekApi from "../config/axiosConfig";
import "./../styles/Sidemenubar.css";

const Sidebar = ({ isCollapsed }) => {
  const [menuData, setMenuData] = useState([]);
  const [menuStates, setMenuStates] = useState({});

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found");

        const decoded = jwtDecode(token); // Decodifica el token para obtener el rol del usuario
        const userRole = decoded.rol; // Asume que el token contiene el rol del usuario

        const response = await medicGeekApi.get(`/menu`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setMenuData(response.data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();
  }, []);

  const toggleMenu = (menuName) => {
    setMenuStates({
      ...menuStates,
      [menuName]: !menuStates[menuName],
    });
  };

  const renderMenuItems = (items) => {
    return items.map((item, index) => (
      <li key={index} className="nav-item text-primary my-2 py-2 py-sm-0">
        {item.subItems ? (
          <>
            <a
              href="#submenu"
              className="nav-link text-primary text-center text-sm-start pe-2"
              onClick={() => toggleMenu(item.title)}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <i className={`bi bi-${item.icon} bg-red fs-4`}></i>
                  <span className="ms-2 d-none d-sm-inline menu-text">
                    {item.title}
                  </span>
                </div>
                <div>
                  <i
                    className={`bi bi-arrow-${
                      menuStates[item.title] ? "up" : "down"
                    }-short ms-0 ms-sm-2`}
                  ></i>
                </div>
              </div>
            </a>

            <ul
              className={`nav collapse ms-2 flex-column align-content-stretch align-items-start flex-column ${
                menuStates[item.title] ? "show" : ""
              }`}
              id="submenu"
              data-bs-parent="#parentM"
            >
              {renderMenuItems(item.subItems)}
            </ul>
          </>
        ) : (
          <NavLink
            to={item.link}
            className="nav-link text-primary text-center text-sm-start"
            activeClassName="activeLink"
          >
            <i className={`bi bi-${item.icon} me-2 fs-4`}></i>
            <span className="d-none d-sm-inline menu-text">{item.title}</span>
          </NavLink>
        )}
      </li>
    ));
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="container-fluid p-0 shadow-lg rounded bg-white">
        <div className="" style={{ height: "100vh" }}>
          <div className="col-auto col-xs-2 d-flex flex-column">
            <div
              className="d-flex justify-content-around align-items-center my-3"
              style={{ height: "6rem" }}
            >
              <img
                src={isCollapsed ? imagenCompanyGeek : imagenCompany}
                className="card-img-top px-3 py-2"
                alt="Imagen de logo"
                style={{
                  height: "auto",
                  width: "100%",
                }}
              />
            </div>

            <div className="mt-2 d-flex sidebarPosition">
              <ul
                className="nav nav-pills flex-column mt-2 mt-sm-0 px-2"
                id="parentM"
                style={{ width: "100%" }}
              >
                {renderMenuItems(menuData)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
