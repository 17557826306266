import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import medicGeekApi from "../../config/axiosConfig";
import ConsultaForm from "./../Consulta/ConsultaForm";
import PacienteForm from "./PacienteForm";

const PacientesList = ({
  setCurrentForm,
  currentForm,
  setSelectedPaciente,
}) => {
  const [pacientes, setPacientes] = useState([]);
  const [ultimaConsulta, setUltimaConsulta] = useState(0);

  const [showForm, setShowForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const pacientesPerPage = 10;
  const [selectedPacienteLocal, setSelectedPacienteLocal] = useState(null);
  const [lastPcteId, setLastPcteId] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { newPcteId } = useParams();
  const [selected, setSelected] = useState(false);

  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await medicGeekApi.get(
          `/patients?search=${searchTerm}`,
          {
            params: {
              $or: [
                { pcteNom: { $regex: searchTerm, $options: "i" } },
                { pcteNom2: { $regex: searchTerm, $options: "i" } },
                { pcteApe1: { $regex: searchTerm, $options: "i" } },
                { pcteApe2: { $regex: searchTerm, $options: "i" } },
              ],
            },
          }
        );
        const sortedPacientes = response.data.sort(
          (a, b) => a.pcteId - b.pcteId
        );
        setPacientes(sortedPacientes);

        // Obtener el último pcteId
        const lastPcteId =
          sortedPacientes.length > 0
            ? sortedPacientes[sortedPacientes.length - 1].pcteId
            : 0;
        setLastPcteId(lastPcteId);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [searchTerm]);

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const handleNewPacienteClick = () => {
    const newPcteId = parseInt(lastPcteId, 10) + 1;
    return <Navigate to={`/patients/new/${newPcteId}`} />;
  };

  const pageCount = Math.ceil(pacientes.length / pacientesPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const sortedPacientes = [...pacientes].sort((a, b) => {
    if (sortConfig.key === null) return 0; // No ordenar si no se ha seleccionado una columna
  
    let aValue = a[sortConfig.key];
    let bValue = b[sortConfig.key];
  
    // Convertir a números si ambos valores son numéricos
    if (!isNaN(aValue) && !isNaN(bValue)) {
      aValue = Number(aValue);
      bValue = Number(bValue);
    }
  
    if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });
  
  
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const displayedPacientes = sortedPacientes
  .filter((paciente) => {
    const searchWords = searchTerm.toLowerCase().split(' ');
    return searchWords.some(
      (word) =>
        paciente.pcteNom.toLowerCase().includes(word) ||
        paciente.pcteNom2.toLowerCase().includes(word) ||
        paciente.pcteApe1.toLowerCase().includes(word) ||
        paciente.pcteApe2.toLowerCase().includes(word)
    );
  })
  .slice(pageNumber * pacientesPerPage, (pageNumber + 1) * pacientesPerPage);

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const updatePacientesList = () => {
    medicGeekApi
      .get("/patients")
      .then((response) => {
        setPacientes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cargarUltimaConsulta = async () => {
    try {
      const response = await medicGeekApi.get("/consults");
      const consultas = response.data;

      console.log(consultas);

      const ultimoConsultId =
        consultas.length > 0 ? consultas[consultas.length - 1].consultId : 0;
      const nuevaConsult = parseInt(ultimoConsultId, 10) + 1;

      console.log("Nueva Consulta es:", nuevaConsult);

      setUltimaConsulta(nuevaConsult);

      // Devolver nuevaConsult para que pueda ser usado después de la resolución de la promesa
      return nuevaConsult;
    } catch (error) {
      console.log(error);
      throw error; // Lanzar el error para que pueda ser capturado en la función que llama
    }
  };

  const handleOpenConsultaForm = async (paciente) => {
    try {
      setSelectedPaciente(paciente);
      setCurrentForm("consultaForm");
    } catch (error) {
      console.error("Error al cargar la última consulta:", error);
    }
  };

  const handleOpenHistoryForm = async (paciente) => {
    try {
      setSelectedPaciente(paciente);

      setCurrentForm("consultaForm");
      setSelected(true);

      // campos a enviar a consultas

      navigate(`/patient/${paciente?.pcteId}/patient-history`);
    } catch (error) {
      console.error("Error al cargar la última consulta:", error);
    }
  };

  // Para pantallas pequeñas, cambia la tabla por tarjetas
  return (
    <div>
      {showForm ? (
        <PacienteForm
          onCloseForm={handleCloseForm}
          pacienteToEdit={selectedPacienteLocal}
          updatePacientesList={updatePacientesList}
          setSelectedPaciente={setSelectedPacienteLocal}
        />
      ) : (
        <div>

          <div
            className="d-flex flex-column flex-md-row justify-content-between miDivConGradiente px-3 rounded-bottom height-responsive"
          >
            <div className="d-flex align-items-center text-white mb-2 mb-md-0">
              <h2>Pacientes</h2>
            </div>

            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0">
              <form className="d-flex mb-2 mb-md-0" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Buscar"
                  aria-label="Buscar"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </form>
              <Link to={`/patients/new/${newPcteId}`}>
                <button
                  type="button"
                  className="btn btn-danger"
                  title="Crear Nuevo Paciente"
                  onClick={handleNewPacienteClick}
                >
                  <i className="bi bi-person-add"></i> Nuevo Paciente
                </button>
              </Link>
            </div>
          </div>

          {/* Mostrar tabla en pantallas grandes */}
          <div className="d-none d-md-block mb-2">
            <table className="table table-striped table-hover mt-5">
              <thead style={{ cursor: "pointer" }}>
                <tr>
                  <th onClick={() => handleSort('pcteId')}>Expediente</th>
                  <th onClick={() => handleSort('pcteNom')}>Nombre</th>
                  <th onClick={() => handleSort('pcteEd')}>Fecha de Nacimiento</th>
                  <th onClick={() => handleSort('pcteSx')}>Género</th>
                  <th>Dirección</th>
                  <th onClick={() => handleSort('pcteNumDoc')}>Número de Documento</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {displayedPacientes.map((paciente) => (
                  <tr key={paciente.pcteId}>
                    <td style={{ width: "12%", textAlign: "start" }}>
                      Exp-{paciente.pcteId.toString().padStart(6, "0")}
                    </td>
                    <td style={{ width: "20%" }}>
                      {paciente.pcteNom} {paciente.pcteNom2} {paciente.pcteApe1}
                    </td>
                    <td style={{ width: "15%" }}>
                      {paciente.pcteEd}
                    </td>
                    <td style={{ width: "8%" }}>
                      {paciente.pcteSx}
                    </td>
                    <td
                      style={{ width: "30%", textAlign: "left" }}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={paciente.pcteDir} >
                      {paciente.pcteDir?.length > 20
                        ? `${paciente.pcteDir.slice(0, 35)}...`
                        : paciente.pcteDir}
                    </td>

                    <td style={{ width: "15%" }}>
                      {paciente.pcteNumDoc}
                    </td>
                    <td style={{ width: "20%" }}>
                      <div>
                        <Link
                          to={`/patients/${paciente.pcteId}/encounters/${ultimaConsulta}`}
                        >
                          <button
                            type="button"
                            className="p-1 border-0"
                            title="Nueva Consulta"
                            id="hoverIcon"
                            onClick={() => handleOpenConsultaForm(paciente)}
                          >
                            <i className="bi bi-file-plus-fill"></i>
                          </button>
                        </Link>

                        <Link to={`/patients/edit/${paciente.pcteId}`}>
                          <button
                            type="button"
                            className="p-1 border-0 "
                            title="Editar"
                            id="hoverIcon"
                          >
                            <i className="bi bi-pencil-square"></i>
                          </button>
                        </Link>

                        <button
                          type="button"
                          className="p-1 border-0"
                          title="Ver Historial Clinico"
                          id="hoverIcon"
                          onClick={() => handleOpenHistoryForm(paciente)}
                        >
                          <i className="bi bi-folder2-open"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="pagination__content">
              {/* Paginación */}
              {!showForm && (
                <div className="d-flex justify-content-center">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                      <li className={`page-item`} style={{ cursor: "pointer" }}>
                        <span
                          className="page-link"
                          onClick={() => handlePageChange({ selected: 0 })}
                        >
                          Inicio
                        </span>
                      </li>
                      <li className={`page-item`} style={{ cursor: "pointer" }}>
                        <span
                          className="page-link"
                          style={{
                            height: "2.4rem",
                            width: "7rem",
                          }}
                          onClick={() =>
                            handlePageChange({
                              selected: Math.max(0, pageNumber - 10),
                            })
                          }
                        >
                          Anterior 10
                        </span>
                      </li>
                      {Array.from(
                        { length: Math.min(pageCount, 10) },
                        (_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              pageNumber === index ? " active" : ""
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              className="page-link"
                              onClick={() =>
                                handlePageChange({ selected: index })
                              }
                            >
                              {index + 1}
                            </span>
                          </li>
                        )
                      )}
                      <li className={`page-item`} style={{ cursor: "pointer" }}>
                        <span
                          className="page-link"
                          style={{
                            height: "2.4rem",
                            width: "7.5rem",
                          }}
                          onClick={() =>
                            handlePageChange({
                              selected: Math.min(
                                pageCount - 1,
                                pageNumber + 10
                              ),
                            })
                          }
                        >
                          Siguiente 10
                        </span>
                      </li>
                      <li className={`page-item`} style={{ cursor: "pointer" }}>
                        <span
                          className="page-link"
                          onClick={() =>
                            handlePageChange({ selected: pageCount - 1 })
                          }
                        >
                          Final
                        </span>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>

          {/* Mostrar tarjetas en vista móvil */}
          <div className="d-md-none mb-2">
            <div className="row">
              {displayedPacientes.map((paciente) => (
                <div className="col-12 mb-3" key={paciente.pcteId}>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Exp-{paciente.pcteId.toString().padStart(6, "0")}
                      </h5>
                      <p className="card-text">
                        <strong>Nombre:</strong> {paciente.pcteNom} {paciente.pcteNom2} {paciente.pcteApe1}
                      </p>
                      <p className="card-text">
                        <strong>Fecha de Nacimiento:</strong> {paciente.pcteEd}
                      </p>
                      <p className="card-text">
                        <strong>Género:</strong> {paciente.pcteSx}
                      </p>
                      <p className="card-text">
                        <strong>Dirección:</strong> {paciente.pcteDir?.length > 20
                          ? `${paciente.pcteDir.slice(0, 35)}...`
                          : paciente.pcteDir}
                      </p>
                      <p className="card-text">
                        <strong>Número de Documento:</strong> {paciente.pcteNumDoc}
                      </p>
                      <div className="d-flex justify-content-around">
                        <Link
                          to={`/patients/${paciente.pcteId}/encounters/${ultimaConsulta}`}
                        >
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            title="Nueva Consulta"
                            onClick={() => handleOpenConsultaForm(paciente)}
                          >
                            <i className="bi bi-file-plus-fill"></i>
                          </button>
                        </Link>
                        <Link to={`/patients/edit/${paciente.pcteId}`}>
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            title="Editar"
                          >
                            <i className="bi bi-pencil-square"></i>
                          </button>
                        </Link>
                        <button
                          type="button"
                          className="btn btn-outline-info"
                          title="Ver Historial Clinico"
                          onClick={() => handleOpenHistoryForm(paciente)}
                        >
                          <i className="bi bi-folder2-open"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="pagination__content">
              {/* Paginación en vista móvil */}
              {!showForm && (
                <div className="d-flex justify-content-center">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                      <li className={`page-item`} style={{ cursor: "pointer" }}>
                        <span
                          className="page-link"
                          onClick={() => handlePageChange({ selected: 0 })}
                        >
                          Inicio
                        </span>
                      </li>
                      <li className={`page-item`} style={{ cursor: "pointer" }}>
                        <span
                          className="page-link"
                          style={{
                            height: "2.4rem",
                            width: "7rem",
                          }}
                          onClick={() =>
                            handlePageChange({
                              selected: Math.max(0, pageNumber - 10),
                            })
                          }
                        >
                          Anterior 10
                        </span>
                      </li>
                      {Array.from(
                        { length: Math.min(pageCount, 10) },
                        (_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              pageNumber === index ? " active" : ""
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              className="page-link"
                              onClick={() =>
                                handlePageChange({ selected: index })
                              }
                            >
                              {index + 1}
                            </span>
                          </li>
                        )
                      )}
                      <li className={`page-item`} style={{ cursor: "pointer" }}>
                        <span
                          className="page-link"
                          style={{
                            height: "2.4rem",
                            width: "7.5rem",
                          }}
                          onClick={() =>
                            handlePageChange({
                              selected: Math.min(
                                pageCount - 1,
                                pageNumber + 10
                              ),
                            })
                          }
                        >
                          Siguiente 10
                        </span>
                      </li>
                      <li className={`page-item`} style={{ cursor: "pointer" }}>
                        <span
                          className="page-link"
                          onClick={() =>
                            handlePageChange({ selected: pageCount - 1 })
                          }
                        >
                          Final
                        </span>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>

          {currentForm === "consultaForm" && selectedPacienteLocal && (
            <div className="main-content">
              <ConsultaForm
                onCloseForm={handleCloseForm}
                paciente={selectedPacienteLocal}
                setCurrentForm={setCurrentForm}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
  
};

export default PacientesList;
