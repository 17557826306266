import axios from "axios";

// Crear una instancia de Axios
const medicGeekApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BACK,
  headers: {
    "Content-Type": "application/json",
  },
});

export default medicGeekApi;
