import Swal from 'sweetalert2';

export const showConfirmationAlert = (title, text) => {
  return Swal.fire({
    title: 'Advertencia',
    text,
    icon: 'warning',
    showDenyButton: true,
    denyButtonText: 'No',
    confirmButtonText: 'Sí',
    confirmButtonColor: '#000000',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
  });
};

export const showSuccessAlert = (message, text) => {
  return Swal.fire({
    position: "center",
    icon: "success",
    title: "Guardado con Éxito",
    showConfirmButton: false,
    timer: 1500,
    text
  });
};

export const showSuccessEditAlert = (message, text) => {
  return Swal.fire({
    position: "center",
    icon: "success",
    title: "Editado con Éxito",
    showConfirmButton: false,
    timer: 1500,
    text
  });
};

export const showDeleteAlert = (message, text) => {
  return Swal.fire({
    position: "center",
    icon: "success",
    title: "Eliminado con Éxito",
    showConfirmButton: false,
    timer: 1500,
    text
  });
};

export const showLoginsAlert = (message, text) => {
  return Swal.fire({
    position: "center",
    icon: "success",
    title: "Bienvenido a MedicGeek",
    showConfirmButton: false,
    timer: 1500,
    text
  });
};

export const showErrorAlert = (message) => {
  return Swal.fire('Error', message, 'error');
};

export const showInfoAlert = (title, text) => {
  return Swal.fire({
    position: "center",
    title: 'No se han detectado cambios.',
    text,
    timer: 1500,
    icon: 'warning',
    confirmButtonColor: '#000000',
    cancelButtonText: 'Cancelar',
  });
};


