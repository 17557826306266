import React, { useEffect, useState } from "react";
import "../styles/global.css";
import "../styles/ventanaFlotante.css";
// import { Line, Bar } from 'react-chartjs-2';
// import { Chart, LineElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement } from 'chart.js';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import ConsultaForm from "../Forms/Consulta/ConsultaForm";

import medicGeekApi from "../config/axiosConfig";
import MedicineSVG from "./../assets/svg/undraw_medicine_b-1-ol.svg";

// Chart.register(LineElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement);
ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

const MainContent = ({ setSelectedPaciente, setCurrentForm, currentForm }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const { profileId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [formData, setFormData] = useState({});
  const [paciente, setPaciente] = useState([]);
  const [cita, setCita] = useState([]);

  const [showForm, setShowForm] = useState(false);

  const [nuevosPacientes, setNuevosPacientesRegistrados] = useState(0);
  const [
    porcentajePacienteConDenominador,
    setPorcentajePacienteConDenominador,
  ] = useState(0);
  const [porcentajeCitaConDenominador, setPorcentajeCitaConDenominador] =
    useState(0);

  const [nuevosCitas, setNuevosCitasRegistrados] = useState(0);
  const [citasMesActual, setCitasMesActual] = useState(0);

  const [nuevasConsultas, setNuevosConsultasRegistradas] = useState(0);
  const [porcentajeSemanal, setPorcentajeCambioMesConsultas] = useState(0);
  const [porcentajeCambioSemanaConsultas, setPorcentajeCambioSemanaConsultas] =
    useState(0);

  const [consultas, setConsultas] = useState([]);
  const [filtroMes, setFiltroMes] = useState(false);
  const consultasPerPage = 10;
  const [pageNumber, setPageNumber] = useState(0);

  // Chard 1
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [selectedYear, setSelectedYear] = useState(""); // Estado para el año seleccionado
  // chard 2
  const [polarAreaData, setPolarAreaData] = useState(null);
  const [polarAreaOptions, setPolarAreaOptions] = useState(null);

  const [calendarData, setCalendarData] = useState([]);
  const [citasHoy, setCitasHoy] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [diaSemana, setDiaSemana] = useState("");
  const [formattedDateTime, setFormattedDateTime] = useState("");
  const [today, setToday] = useState("");

  // Enviar paciente a consulta
  const [pacienteConsulta, setPacienteConsulta] = useState(null);
  const [nombrePacienteSeleccionado, setNombrePacienteSeleccionado] =
    useState("");

  const [selectedFormulario, setSelectedFormulario] = useState("");
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();
  const [formulario, setFormulario] = useState({
    categoriaNm: "",
    horaFormateada: "",
    fechaCita: "",
    nombrePaciente: "",
    nombreDelPaciente: "",
    direccionPaciente: "",
    contactoPaciente: "",
    emailPaciente: "",
    notaCita: "",
    estatusCalendar: "",
    pacienteId: "",
  });

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [selectedConsulta, setSelectedConsulta] = useState(null);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    // Obtener la fecha actual
    const today = new Date();

    // Calcular la fecha del primer día del último mes
    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth.setDate(1);

    // Calcular la fecha del primer día de la semana actual (lunes)
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - ((today.getDay() + 6) % 7)); // Asegura que el primer día sea un lunes

    // Calcular la fecha del primer día de la semana pasada (lunes)
    const lastWeek = new Date(firstDayOfWeek);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const fetchData = async () => {
      try {
        if (profileId) {
          // Si hay un profileId, se está editando un perfil existente
          const response = await medicGeekApi.get(`/profile/${profileId}`);
          const data = response.data;
          console.log("Data received:", data);

          // Actualizar el estado con los datos del perfil
          setProfileData(data);
        } else {
          // Si no hay un profileId, se está creando un nuevo perfil
          const responseProfile = await medicGeekApi.get("/profile");
          const dataProfile = responseProfile.data;
          // console.log(dataProfile);

          if (Array.isArray(dataProfile) && dataProfile.length > 0) {
            // Obtener el primer perfil del arreglo dataProfile
            const firstProfile = dataProfile[0];
            // Actualizar el estado con los datos del primer perfil
            setProfileData(firstProfile);
          } else {
            // Si no hay perfiles existentes, inicializar el formulario con datos predeterminados
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    if (!hasLoaded) {
      medicGeekApi
        .get("/patients")
        .then((responsePatients) => {
          const allPatients = responsePatients.data;

          // Filtrar pacientes registrados en la semana actual
          const pacientesSemanaActual = allPatients.filter((paciente) => {
            const fechaRegistro = new Date(paciente.pcteFechaReg);
            return fechaRegistro >= firstDayOfWeek && fechaRegistro <= today;
          });

          // Filtrar pacientes registrados en la semana pasada
          const pacientesSemanaPasada = allPatients.filter((paciente) => {
            const fechaRegistro = new Date(paciente.pcteFechaReg);
            return fechaRegistro >= lastWeek && fechaRegistro < firstDayOfWeek;
          });

          // Calcular el porcentaje de cambio en la cantidad de pacientes
          const porcentajeCambio =
            ((pacientesSemanaActual?.length - pacientesSemanaPasada?.length) /
              pacientesSemanaPasada?.length) *
            100;

          // Formatear el porcentaje de cambio para mostrarlo con signo
          const porcentajeFormateado =
            porcentajeCambio > 0 ? (
              <span className="positive-percentage">
                <i class="bi bi-arrow-up mr-3"></i> +
                {porcentajeCambio.toFixed(2)}%
              </span>
            ) : (
              <span className="negative-percentage">
                <i class="bi bi-arrow-down mr-3"></i>{" "}
                {porcentajeCambio.toFixed(2)}%
              </span>
            );

          setNuevosPacientesRegistrados(allPatients?.length);
          setPorcentajePacienteConDenominador(porcentajeFormateado);
        })
        .catch((errorConsults) => {
          console.log(errorConsults);
        });
    }
    // Pacientes

    if (!hasLoaded) {
      medicGeekApi
        .get("/consults")
        .then((responseConsults) => {
          const allConsults = responseConsults.data;

          // Realizar una solicitud adicional para obtener información de pacientes
          medicGeekApi
            .get("/patients")
            .then((responsePatients) => {
              const patientsData = responsePatients.data;

              // Asignar la información de pacientes a las consultas utilizando el campo pcteId
              const consultasConPacientes = allConsults.map((consulta) => {
                const paciente = patientsData.find(
                  (paciente) => paciente.pcteId === consulta.pcteId
                );
                return {
                  ...consulta,
                  pcteNom: paciente.pcteNom,
                  pcteNom2: paciente.pcteNom2,
                  pcteApe1: paciente.pcteApe1,
                  pcteEd: paciente.pcteEd,
                  pcteDir: paciente.pcteDir,
                };
              });

              const sortedConsults = consultasConPacientes.sort(
                (a, b) => b.consultId - a.consultId
              );

              // Filtrar consultas registradas en el último mes
              const consultasMes = consultasConPacientes.filter((consulta) => {
                const fechaConsulta = new Date(consulta.consultFec);
                return fechaConsulta >= lastMonth && fechaConsulta <= today;
              });

              // Filtrar consultas registradas en la última semana
              const consultasSemana = consultasConPacientes.filter(
                (consulta) => {
                  const fechaConsulta = new Date(consulta.consultFec);
                  return fechaConsulta >= lastWeek && fechaConsulta <= today;
                }
              );

              // Función para calcular el porcentaje de cambio
              const calculatePercentageChange = (total, subset) => {
                const change = ((subset - total) / total) * 100;
                return change > 0
                  ? `+${change.toFixed(2)}%`
                  : `${change.toFixed(2)}%`;
              };

              // Calcular el porcentaje de cambio en el último mes para consultas
              const porcentajeCambioMesConsultas = calculatePercentageChange(
                allConsults?.length,
                consultasMes?.length
              );

              // Calcular el porcentaje de cambio en la última semana para consultas
              const porcentajeCambioSemanaConsultas = calculatePercentageChange(
                allConsults?.length,
                consultasSemana?.length
              );

              // Establecer los valores en los estados correspondientes
              setHasLoaded(true);
              setNuevosConsultasRegistradas(allConsults?.length);

              // Formatear los porcentajes de cambio con colores según el signo
              const porcentajeFormateadoMesConsultas =
                porcentajeCambioMesConsultas > 0 ? (
                  <span className="positive-percentage">
                    <i class="bi bi-arrow-up mr-3"></i> +
                    {porcentajeCambioMesConsultas}
                  </span>
                ) : (
                  <span className="negative-percentage">
                    <i class="bi bi-arrow-down mr-3"></i>{" "}
                    {porcentajeCambioMesConsultas}
                  </span>
                );

              const porcentajeFormateadoSemanaConsultas =
                porcentajeCambioSemanaConsultas > 0 ? (
                  <span className="positive-percentage">
                    <i class="bi bi-arrow-up mr-3"></i> +
                    {porcentajeCambioSemanaConsultas}
                  </span>
                ) : (
                  <span className="negative-percentage">
                    <i class="bi bi-arrow-down mr-3"></i>{" "}
                    {porcentajeCambioSemanaConsultas}
                  </span>
                );

              setPorcentajeCambioMesConsultas(porcentajeFormateadoMesConsultas);
              setPorcentajeCambioSemanaConsultas(
                porcentajeFormateadoSemanaConsultas
              );
              setConsultas(sortedConsults);
              setHasLoaded(true);
              const chartData = generateChartData(sortedConsults);
              setChartData(chartData);
            })
            .catch((errorPatients) => {
              console.log(errorPatients);
            });
        })
        .catch((errorPatients) => {
          console.log(errorPatients);
        });
    }
    // Consultas

    if (!hasLoaded) {
      medicGeekApi
        .get("/calendars/detail")
        .then((calendarResponse) => {
          const calendarData = calendarResponse.data;
          setCalendarData(calendarData);

          const formatDate = (dateTimeStr) => {
            if (typeof dateTimeStr !== "string") {
              return { formattedDate: "", formattedHour: "" }; // Devolver objetos vacíos si no es una cadena válida
            }

            const dateTimeParts = dateTimeStr.split(" ");
            if (dateTimeParts.length !== 3) {
              return { formattedDate: "", formattedHour: "" }; // Devolver objetos vacíos si no tiene el formato esperado
            }

            const dateParts = dateTimeParts[0].split("/");
            const timeParts = dateTimeParts[1].split(":");
            const period = dateTimeParts[2];

            if (dateParts.length !== 3 || timeParts.length !== 2) {
              return { formattedDate: "", formattedHour: "" }; // Devolver objetos vacíos si no tiene el formato esperado
            }

            const hour = parseInt(timeParts[0], 10);
            const minute = parseInt(timeParts[1], 10);

            if (isNaN(hour) || isNaN(minute)) {
              return { formattedDate: "", formattedHour: "" }; // Devolver objetos vacíos si no tiene el formato esperado
            }

            const citaDate = new Date(
              parseInt(dateParts[2], 10),
              parseInt(dateParts[1], 10) - 1,
              parseInt(dateParts[0], 10),
              period === "PM" ? hour + 12 : hour, // Convertir a formato de 24 horas si es PM
              minute
            );

            const formattedDate = `${dateParts[0].padStart(
              2,
              "0"
            )}/${dateParts[1].padStart(2, "0")}/${dateParts[2]}`;
            const formattedHour = citaDate.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });

            return { formattedDate, formattedHour };
          };

          const today = new Date();
          const formattedToday = today.toLocaleDateString("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          const citasHoy = calendarData.filter((paciente) => {
            const { formattedDate } = formatDate(paciente.fechaCita);
            return formattedDate === formattedToday;
          });

          const citaStr = "12/03/2024 10:30 AM"; // Ejemplo de cadena con fecha y hora
          const { formattedDate, formattedHour } = formatDate(citaStr);

          setCitasHoy(citasHoy);
          setFormattedDateTime(formattedDateTime);
          setFormattedDate(formattedDate);
          setToday(formattedToday);
          setDiaSemana(diaSemana);
          setPacienteConsulta(paciente);

          // Obtener datos de las categorías
          medicGeekApi
            .get("/categorys/detail")
            .then((categoriasRes) => {
              const categorias = categoriasRes.data.reduce((acc, cat) => {
                acc[cat.categoryId] = {
                  name: cat.categoryNm,
                  colorId: cat.colorId,
                };
                return acc;
              }, {});
              // Calcular la cantidad de citas del mes actual

              // Comparar las cantidades y hacer algo con esa comparación
              // const comparacion = compararCitas(citasMesActual, citasMesPasado);

              // Si necesitas hacer algo con la comparación, hazlo aquí
              const categoriasAgrupadas = calendarData.reduce((acc, cita) => {
                const categoryId = cita.categoryId;
                const categoryName = categorias[categoryId]?.name; // Verificar si categoryName es undefined

                if (categoryName) {
                  acc[categoryName] = (acc[categoryName] || 0) + 1;
                }
                return acc;
              }, {});

              // Fetch de paletaColors
              medicGeekApi
                .get("/paletaColors")
                .then((paletaColorResponse) => {
                  const paletaColorData = paletaColorResponse.data;
                  const paletaColorMap = {};
                  paletaColorData.forEach((paletaColor) => {
                    paletaColorMap[paletaColor.colorId] = paletaColor.colorRgb;
                  });

                  const categoriasAgrupadas = calendarData.reduce(
                    (acc, cita) => {
                      const categoryId = cita.categoryId;
                      const categoryName = categorias[categoryId].name;

                      if (!acc[categoryName]) {
                        acc[categoryName] = 1;
                      } else {
                        acc[categoryName]++;
                      }

                      return acc;
                    },
                    {}
                  );

                  const colors = Object.keys(categorias).map((categoryId) => {
                    const colorId = categorias[categoryId].colorId;
                    return paletaColorMap[colorId] || "rgb(255, 0, 0)"; // Color por defecto
                  });

                  const polarAreaData = {
                    labels: Object.keys(categoriasAgrupadas),
                    datasets: [
                      {
                        data: Object.values(categoriasAgrupadas),
                        backgroundColor: colors,
                      },
                    ],
                  };
                  setPolarAreaData(polarAreaData);
                })
                .catch((error) => {
                  console.error(
                    "Error al obtener colores de la paleta:",
                    error
                  );
                });
            })
            .catch((errorCategories) => {
              console.log(errorCategories);
            });
        })
        .catch((errorConsults) => {
          console.log(errorConsults);
        });
    }
    // Estadisticas Citas

    if (!hasLoaded) {
      medicGeekApi
        .get("/calendars")
        .then((calendarResponse) => {
          const allCitas = calendarResponse.data;

          // Filtrar pacientes registrados en la semana actual
          const citasSemanaActual = allCitas.filter((cita) => {
            const fechaRegistro = new Date(cita.fechaCitaReg);
            return fechaRegistro >= firstDayOfWeek && fechaRegistro <= today;
          });

          // Filtrar pacientes registrados en la semana pasada
          const citaSemanaPasada = allCitas.filter((cita) => {
            const fechaRegistro = new Date(cita.fechaCitaReg);
            return fechaRegistro >= lastWeek && fechaRegistro < firstDayOfWeek;
          });

          // Calcular el porcentaje de cambio en la cantidad de pacientes
          const porcentajeCambio =
            ((citasSemanaActual?.length - citaSemanaPasada?.length) /
              citaSemanaPasada?.length) *
            100;

          // Formatear el porcentaje de cambio para mostrarlo con signo
          const porcentajeFormateado =
            porcentajeCambio > 0 ? (
              <span className="positive-percentage">
                <i class="bi bi-arrow-up mr-3"></i> +
                {porcentajeCambio.toFixed(2)}%
              </span>
            ) : (
              <span className="negative-percentage">
                <i class="bi bi-arrow-down mr-3"></i>{" "}
                {porcentajeCambio.toFixed(2)}%
              </span>
            );

          setNuevosCitasRegistrados(allCitas?.length);
          setPorcentajeCitaConDenominador(porcentajeFormateado);
        })
        .catch((errorConsults) => {
          console.log(errorConsults);
        });
    }
    // Calendar

    fetchData();
  }, [hasLoaded, consultas, profileId]);

  const handleShowModal = (consulta) => {
    setSelectedConsulta(consulta);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedConsulta(null);
  };

  // Chard 1
  const generateChartData = (sortedConsults) => {
    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const consultasPorMes = {};
    sortedConsults.forEach((consulta) => {
      const parts = consulta.consultFec.split("/"); // Suponiendo que la fecha está en formato dd/mm/yyyy
      const dia = parseInt(parts[0]);
      const mesNumerico = parseInt(parts[1]);
      const año = parseInt(parts[2]);

      if (isNaN(dia) || isNaN(mesNumerico) || isNaN(año)) {
        console.error(`Error en el formato de fecha: ${consulta.consultFec}`);
        return; // Salir de la iteración actual si la fecha no está en el formato correcto
      }

      const fechaConsulta = new Date(año, mesNumerico - 1, dia); // Crear objeto de fecha en formato yyyy-mm-dd
      const mes = monthNames[fechaConsulta.getMonth()];

      const fechaFormateada = `${dia.toString().padStart(2, "0")}/${mesNumerico
        .toString()
        .padStart(2, "0")}/${año}`;

      // console.log(fechaFormateada);

      if (consultasPorMes[mes]) {
        consultasPorMes[mes]++;
      } else {
        consultasPorMes[mes] = 1;
      }
    });

    const labels = Object.keys(consultasPorMes);
    const data = Object.values(consultasPorMes);

    const datasetConsults = {
      label: "Consultas por Mes",
      data,
      backgroundColor: ["rgb(63, 128, 234)"], // Color de fondo de las barras
      borderColor: ["rgb(17, 113, 239)"], // Color del borde de las barras
      borderWidth: 1, // Ancho del borde
      borderRadius: 8,
    };

    const chartOptions = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      height: 500,
      aspectRatio: 2,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Bar Chart",
        },
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 800,
        },
      },
      animation: true, // Desactiva las animaciones
    };

    return {
      labels,
      datasets: [datasetConsults],
    };
  };
  // Termina el grafico

  const displayedConsults = consultas.slice(
    pageNumber * consultasPerPage,
    (pageNumber + 1) * consultasPerPage
  );

  citasHoy.sort((a, b) => {
    const horaA = new Date(a.fechaCita).getHours();
    const horaB = new Date(b.fechaCita).getHours();
    return horaA - horaB;
  });

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleEnviarConsultaClick = async (paciente) => {
    try {
      // console.log("Evento seleccionado:", paciente);

      // Accede al ID del paciente dentro del objeto paciente.patient
      const pacienteId = paciente.patient?.pcteId;
      const { patient, estatus, calendarId, category } = paciente;
      const { categoryId } = category;
      const consultaCita = { ...patient, estatus, calendarId, categoryId };

      // console.log(consultaCita)

      setSelectedPaciente(consultaCita);
      setCurrentForm("consultaForm");
      setSelected(true);

      // Redirige al usuario al URL correcto con el ID del paciente
      navigate(`/patients/${pacienteId}/encounters/0`);
    } catch (error) {
      console.error("Error al cargar la última consulta:", error);
    }
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div style={{ paddingBottom: "5rem" }}>
          <div
            className="miDivConGradiente h-100"
            style={{
              minHeight: "20rem",
            }}
          >
            <div className="p-2 mx-4 ">
              <div className="d-flex justify-content-start pt-2 pb-4">
                {/* <h1 className='fs-5 text-white'>Bienvenido <spam class='fs-bold'>{profileData.docNom}</spam>!</h1> */}
              </div>
              <div className="row">
                <div class="col-lg-6 col-xl-3">
                  <div
                    class="card-stats mb-4 mb-xl-0 card text-start"
                    style={{
                      backgroundColor: "#E0EAFC",
                    }}
                  >
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col">
                          <h5 class="mb-0" style={{ color: "#3F80EA" }}>
                            {" "}
                            Bienvenido de nuevo!
                          </h5>
                          {/* <span class="h5 font-weight-bold mb-0" style={{color:'#3F80EA'}}>{profileData.docNom}!</span> */}
                          <p class="mt-2 mb-0 text-muted text-sm">
                            <span class="mr-2">
                              <i class="fa fa-arrow-up"></i> MedicGeek Dashboard
                            </span>
                          </p>
                        </div>
                        <div
                          class="col"
                          style={{
                            width: "100px",
                            height: "80px",
                          }}
                        >
                          <img
                            src={MedicineSVG}
                            alt="Medicine"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-xl-3">
                  <div class="card-stats mb-4 mb-xl-0 card text-start">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col">
                          <h5 class="text-uppercase text-muted mb-0 card-title">
                            Pacientes
                          </h5>
                          <span
                            class="h5 font-weight-bold mb-0"
                            style={{ color: "green" }}
                          >
                            {nuevosPacientes}
                          </span>
                        </div>
                        <div class="col-auto col">
                          <div
                            class="icon icon-shape text-white rounded-circle shadow d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: "#fd0061",
                              width: "60px",
                              height: "60px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-user-plus"
                              width="40"
                              height="40"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#ffffff"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                              <path d="M16 19h6" />
                              <path d="M19 16v6" />
                              <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <p class="mt-3 mb-0 text-muted text-sm">
                        <span class="text-success mr-2">
                          <i class="fa fa-arrow-up"></i>
                          {porcentajePacienteConDenominador}
                        </span>
                        <span class="text-nowrap"> Desde la semana pasada</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-xl-3">
                  <div class="card-stats mb-4 mb-xl-0 card text-start">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col">
                          <h5 class="text-uppercase text-muted mb-0 card-title">
                            {" "}
                            Consultas
                          </h5>
                          <span
                            class="h5 font-weight-bold mb-0"
                            style={{ color: "green" }}
                          >
                            {nuevasConsultas}
                          </span>
                        </div>
                        <div class="col-auto col">
                          <div
                            class="icon icon-shape text-white rounded-circle shadow d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: "#ff4500",
                              width: "60px",
                              height: "60px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-clipboard-data"
                              width="40"
                              height="40"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#ffffff"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                              <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                              <path d="M9 17v-4" />
                              <path d="M12 17v-1" />
                              <path d="M15 17v-2" />
                              <path d="M12 17v-1" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <p class="mt-3 mb-0 text-muted text-sm">
                        <span class="text-success mr-2">
                          <i class="fa fa-arrow-up"></i>
                          {porcentajeCambioSemanaConsultas}
                        </span>
                        <span class="text-nowrap"> Desde la semana pasada</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-xl-3">
                  <div class="card-stats mb-4 mb-xl-0 card text-start">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col">
                          <h5 class="text-uppercase text-muted mb-0 card-title">
                            {" "}
                            Citas
                          </h5>
                          <span
                            class="h5 font-weight-bold mb-0"
                            style={{ color: "green" }}
                          >
                            {nuevosCitas}
                          </span>
                        </div>
                        <div class="col-auto col">
                          <div
                            class="icon icon-shape text-white rounded-circle shadow d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: "#00abfb",
                              width: "60px",
                              height: "60px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-chart-arrows-vertical"
                              width="40"
                              height="40"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#ffffff"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M18 21v-14" />
                              <path d="M9 15l3 -3l3 3" />
                              <path d="M15 10l3 -3l3 3" />
                              <path d="M3 21l18 0" />
                              <path d="M12 21l0 -9" />
                              <path d="M3 6l3 -3l3 3" />
                              <path d="M6 21v-18" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <p class="mt-3 mb-0 text-muted text-sm">
                        <span class="text-success mr-2">
                          <i class="fa fa-arrow-up"></i>
                          {porcentajeCitaConDenominador}
                        </span>
                        <span class="text-nowrap"> Desde la semana pasada</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Cards */}

          <div
            className="d-flex gap-3 justify-content-center"
            style={{
              height: "25rem",
              padding: "0 2rem",
            }}
          >
            <div
              className="bg-white rounded-2 contenedor-chart2 d-flex justify-content-start"
              style={{
                position: "relative",
                top: "-6rem",
                height: "30rem",
                width: "40%",
              }}
            >
              <div className="fs-5 text-secondary py-3">
                <i class="bi bi-calendar-range px-2"></i>
                <label>
                  Citas Pendientes
                  {/* <span className='fw-bold text-decoration-underline ps-2'>{fechaLarga}</span> */}
                </label>
              </div>
              {/* Cita hoy fecha */}
              <div className="text-end w-100 pe-3 pb-2">
                <label className="fw-bold text-decoration-underline">
                  Leyenda
                </label>
                <div className="d-flex justify-content-end gap-2">
                  <div>
                    <label className="bg-success border-0 rounded px-1 text-white">
                      Activa
                    </label>
                  </div>
                  <div>
                    <label className="bg-primary border-0 rounded px-1 text-white">
                      Completada
                    </label>
                  </div>
                  <div>
                    <label className="bg-danger border-0 rounded px-1 text-white">
                      Cancelada
                    </label>
                  </div>
                </div>
              </div>
              {/* Legenda */}
              <div
                className="text-start w-100 p-2"
                style={{ maxHeight: "100%", overflowY: "auto" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th>Información</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {citasHoy.map((paciente) => (
                      <tr
                        key={paciente.id}
                        className={
                          paciente.estatus === "Completada"
                            ? "table-secondary no-hover"
                            : paciente.estatus === "Cancelada"
                            ? "table-danger no-hover"
                            : ""
                        }
                        style={{
                          opacity:
                            paciente.estatus === "Cancelada" ||
                            paciente.estatus === "Completada"
                              ? 0.5
                              : 1,
                        }}
                      >
                        <td className="table-light" style={{ width: "15%" }}>
                          <div className="d-flex flex-column">
                            <div className="text-decoration-underline">
                              {paciente.patient.pcteNom}{" "}
                              {paciente.patient.pcteNom2}{" "}
                              {paciente.patient.pcteApe1}
                            </div>
                            <div className="">{paciente.category.categoryNm}</div>
                            <div className="fw-bold">
                              {paciente.fechaCita.split(" ")[1]}{" "}
                              {paciente.fechaCita.split(" ")[2]}{" "}
                              {/* Muestra la hora y el AM/PM */}
                            </div>
                          </div>
                        </td>
                        <td
                          className="text-end cursor-hover table-light"
                          style={{ width: "10%" }}
                        >
                          <div className="rounded text-center py-2 ">
                            <button
                              style={{ width: "12rem", height: "3rem" }}
                              type="button"
                              className={`text-center pr-1 m-0 border py-2 rounded fw-normal  ${
                                paciente.estatus === "Completada"
                                  ? "bg-primary text-light"
                                  : paciente.estatus === "Cancelada"
                                  ? "bg-danger text-light"
                                  : "button-send bg-success text-white"
                              }`}
                              title={
                                paciente.estatus === "Completada"
                                  ? "Cita Completada"
                                  : paciente.estatus === "Cancelada"
                                  ? "Cita Cancelada"
                                  : "Enviar a Consulta"
                              }
                              id="hoverIcon"
                              disabled={
                                paciente.estatus === "Cancelada" ||
                                paciente.estatus === "Completada"
                              }
                              onClick={() => handleEnviarConsultaClick(paciente)}
                            >
                              {paciente.estatus === "Completada" ? (
                                <i className="bi bi-check-circle-fill text-opacity pe-2"></i>
                              ) : paciente.estatus === "Cancelada" ? (
                                <i className="bi bi-x-circle-fill text-opacity pe-2"></i>
                              ) : (
                                <i className="bi bi-arrow-right-circle-fill text-opacity pe-2"></i>
                              )}
                              {paciente.estatus === "Completada"
                                ? "Cita Completada"
                                : paciente.estatus === "Cancelada"
                                ? "Cita Cancelada"
                                : "Enviar a Consulta"}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {citasHoy.length === 0 && (
                      <tr>
                        <td colSpan="4">No hay citas disponibles para hoy</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Citas 2*/}

            <div
              className="bg-white rounded-2"
              style={{
                position: "relative",
                top: "-6rem",
                height: "30rem",
                width: "60%",
              }}
            >
              <div
                className="d-flex justify-content-center"
                style={{
                  height: "30rem",
                }}
              >
                {chartData && Object.keys(chartData).length > 0 && (
                  <Bar data={chartData} options={chartOptions} />
                )}
              </div>
            </div>
            {/* Chard 1*/}
          </div>
          {/* Citas & Chards */}

          <div
            className="bg-white rounded-2 "
            style={{
              height: "38rem",
              margin: "0 2rem",
            }}
          >
            <div
              style={{
                textAlign: "left",
              }}
            >
              <label className="fs-5 py-3 px-3 text-uppercase fw-bold">
                Listado de Ultimas Consultas
              </label>
            </div>
            <div className="text-start px-1">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre del Paciente</th>
                    <th>Dirección</th>
                    <th>Fecha de Consulta</th>
                    <th>Motivo de Consulta</th>
                    <th>Acción </th>
                  </tr>
                </thead>
                <tbody>
                  {displayedConsults.map((consulta) => (
                    <tr key={consulta.consultId}>
                      <td style={{ width: "5%" }}>{consulta.consultId}</td>
                      <td style={{ width: "20%", textAlign: "left" }}>
                        {consulta.pcteNom} {consulta.pcteNom2} {consulta.pcteApe1}
                      </td>
                      <td
                        style={{ width: "25%", textAlign: "left" }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={consulta.pcteDir}
                      >
                        {consulta.pcteDir?.length > 40
                          ? `${consulta.pcteDir.slice(0, 25)}...`
                          : consulta.pcteDir}
                      </td>
                      <td style={{ width: "20%" }}>{consulta.consultFec}</td>
                      <td
                        style={{ width: "30%", textAlign: "left" }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={consulta.consultMot}
                      >
                        {consulta.consultMot?.length > 30
                          ? `${consulta.consultMot.slice(0, 35)}...`
                          : consulta.consultMot}
                      </td>
                      <td style={{ width: "5%" }}>
                        <div className="text-center">
                          <button
                            type="button"
                            className="p-1 border-0 bg-opacity "
                            title="Ver detalle de la consulta"
                            id="hoverIcon"
                          >
                            <i class="bi bi-folder2-open"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Lista de ultimas Consultas */}
        </div>
        {currentForm === "consultaForm" && selected && paciente && formulario && (
          <div className="main-content">
            <ConsultaForm
              onCloseForm={handleCloseForm}
              paciente={paciente}
              formulario={formulario}
              setCurrentForm={setCurrentForm}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainContent;
