import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  showErrorAlert,
  showSuccessAlert,
  showSuccessEditAlert,
} from "../../../components/alerts";
import medicGeekApi from "../../../config/axiosConfig";

const SegurosList = ({ currentForm }) => {
  const [seguros, setSeguros] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const segurosPerPage = 10;
  const [selectedSeguroLocal, setSelectedSeguroLocal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [formulario, setFormulario] = useState({
    nombreSeguro: "",
    descripcionSeguro: "",
    estatusSeguro: "activo",
  });

  const openModal = (seguro) => {
    setSelectedSeguroLocal(seguro); // Configura el seguro seleccionado para la edición

    if (seguro) {
      setIsEditing(true); // Establecer como modo edición cuando se abre el modal
      setFormulario({
        nombreSeguro: seguro.seguroNm,
        descripcionSeguro: seguro.seguroDesc,
        estatusSeguro: seguro.estatus ? "activo" : "inactivo",
      });
    } else {
      setIsEditing(false); // Establecer como modo creación cuando se abre el modal para crear uno nuevo
      // Limpia los campos si es necesario
      setFormulario({
        nombreSeguro: "",
        descripcionSeguro: "",
        estatusSeguro: "activo",
      });
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedSeguroLocal(null); // Limpia el seguro seleccionado
    setIsEditing(false); // Resetear el modo edición cuando se cierra el modal
    setModalOpen(false);
  };

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await medicGeekApi.get(
          `/seguros?search=${searchTerm}`,
          {
            params: {
              $or: [
                { seguroNm: { $regex: searchTerm, $options: "i" } },
                { seguroDesc: { $regex: searchTerm, $options: "i" } },
              ],
            },
          }
        );
        const sortedSeguros = response.data.sort(
          (a, b) => a.seguroId - b.seguroId
        );
        setSeguros(sortedSeguros);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [searchTerm]);

  const pageCount = Math.ceil(seguros.length / segurosPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedSeguros = seguros
    .filter((seguro) => {
      // Filtrar seguros basados en el término de búsqueda
      return (
        seguro.seguroNm.toLowerCase().includes(searchTerm.toLowerCase()) ||
        seguro.seguroDesc.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .slice(pageNumber * segurosPerPage, (pageNumber + 1) * segurosPerPage);

  const updateSegurosList = () => {
    medicGeekApi
      .get("/seguros")
      .then((response) => {
        setSeguros(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const guardarNuevoSeguro = () => {
    const nuevoSeguro = {
      seguroNm: document.getElementById("nombreSeguro").value,
      seguroDesc: document.getElementById("descripcionSeguro").value,
      estatus:
        document.getElementById("estatusSeguro").value === "activo"
          ? "Activo"
          : "Inactivo",
    };
    medicGeekApi
      .post("/seguros", nuevoSeguro)
      .then((response) => {
        showSuccessAlert();
        closeModal();
        updateSegurosList();
      })
      .catch((error) => {
        console.error("Error al crear el seguro", error);
      });
  };

  const editarSeguro = () => {
    if (selectedSeguroLocal && selectedSeguroLocal.seguroId) {
      const { seguroId } = selectedSeguroLocal;
      const nombreSeguro = document.getElementById("nombreSeguro").value;
      const descripcionSeguro =
        document.getElementById("descripcionSeguro").value;
      const estatusSeguro =
        document.getElementById("estatusSeguro").value === "activo"
          ? "Activo"
          : "Inactivo";

      const updatedSeguro = {
        seguroId, // Asegurarse de enviar el ID
        seguroNm: nombreSeguro,
        seguroDesc: descripcionSeguro,
        estatus: estatusSeguro,
      };

      medicGeekApi
        .patch(`/seguros/${seguroId}`, updatedSeguro)
        .then((response) => {
          showSuccessEditAlert();
          closeModal();
          updateSegurosList();
        })
        .catch((error) => {
          console.error("Error al actualizar el seguro", error);
        });
    } else {
      // Manejo de error o mensaje de que no se puede actualizar
      showErrorAlert("No se puede actualizar: seguro no válido.");
    }
  };

  return (
    <div>
       <div
          className="d-flex flex-column flex-md-row justify-content-between miDivConGradiente px-3 rounded-bottom height-responsive"
        >
          <div className="d-flex align-items-center text-white mb-2 mb-md-0">
            <h2>Seguros</h2>
          </div>

          <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0">
          <form className="d-flex mb-2 mb-md-0" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Buscar"
              aria-label="Buscar"
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </form>
          <button
            type="button"
            className="btn btn-danger ms-2"
            title="Crear Nuevo Seguro"
            onClick={() => openModal(null)}
          >
            <i className="bi bi-file-plus"></i> Nuevo Seguro
          </button>
        </div>
      </div>
  
      {/* Mostrar tabla en pantallas grandes */}
      <div className="d-none d-md-block mb-2" style={{ height: "45vh", padding: "0rem 1rem" }}>
        <table className="table table-striped table-hover mt-5">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre del Seguro</th>
              <th>Descripción del Seguro</th>
              <th>Estatus</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {displayedSeguros.map((seguro) => (
              <tr key={seguro.seguroId}>
                <td>{seguro.seguroId}</td>
                <td>{seguro.seguroNm}</td>
                <td
                  style={{ width: "30%"}}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={seguro.seguroDesc}
                >
                  {seguro.seguroDesc?.length > 20
                    ? `${seguro.seguroDesc.slice(0, 30)}...`
                    : seguro.seguroDesc}
                </td>
                <td>{seguro.estatus}</td>
                <td>
                  <div className="d-flex">
                    <button
                      type="button"
                      className="p-1 border-0"
                      title="Editar"
                      id="hoverIcon"
                      onClick={() => openModal(seguro)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                    <button
                      type="button"
                      className="p-1 border-0"
                      title="Eliminar"
                      id="hoverIcon"
                      // onClick={() => handleDelete(seguro.seguroId)}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
      {/* Mostrar tarjetas en vista móvil */}
      <div className="d-md-none mb-2" style={{ padding: "0rem 1rem" }}>
        <div className="row">
          {displayedSeguros.map((seguro) => (
            <div className="col-12 mb-3" key={seguro.seguroId}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">ID-{seguro.seguroId}</h5>
                  <p className="card-text">
                    <strong>Nombre del Seguro:</strong> {seguro.seguroNm}
                  </p>
                  <p className="card-text">
                    <strong>Descripción:</strong> {seguro.seguroDesc}
                  </p>
                  <p className="card-text">
                    <strong>Estatus:</strong> {seguro.estatus}
                  </p>
                  <div className="d-flex justify-content-around">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      title="Editar"
                      onClick={() => openModal(seguro)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      title="Eliminar"
                      // onClick={() => handleDelete(seguro.seguroId)}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

       {/* Paginación */}
       {!showForm && (
        <div className="d-flex justify-content-end" style={{ padding: "0rem 1rem" }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              
              {/* Botón de página anterior */}
              <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                <span
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageChange({ selected: pageNumber - 1 })}
                  style={{ cursor: pageNumber === 0 ? "not-allowed" : "pointer" }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </span>
              </li>

              {Array.from(
                { length: Math.min(pageCount, 10) },
                (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      pageNumber === index ? " active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="page-link"
                      onClick={() => handlePageChange({ selected: index })}
                    >
                      {index + 1}
                    </span>
                  </li>
                )
              )}

              {/* Botón de siguiente página */}
              <li className={`page-item ${pageNumber === pageCount - 1 ? "disabled" : ""}`}>
                <span
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageChange({ selected: pageNumber + 1 })}
                  style={{ cursor: pageNumber === pageCount - 1 ? "not-allowed" : "pointer" }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </span>
              </li>
              
            </ul>
          </nav>
        </div>
      )}

      {currentForm === "seguroForm" && selectedSeguroLocal && (
        <div className="main-content"></div>
      )}
      
      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          {isEditing ? "Editar Seguro" : "Crear Nuevo Seguro"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="nombreSeguro">Nombre del Seguro</Label>
              <Input
                type="text"
                id="nombreSeguro"
                value={formulario.nombreSeguro}
                onChange={(e) =>
                  setFormulario({
                    ...formulario,
                    nombreSeguro: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="descripcionSeguro">Descripción</Label>
              <Input
                type="text"
                id="descripcionSeguro"
                value={formulario.descripcionSeguro}
                onChange={(e) =>
                  setFormulario({
                    ...formulario,
                    descripcionSeguro: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="estatusSeguro">Estado</Label>
              <Input
                type="select"
                id="estatusSeguro"
                value={formulario.estatusSeguro}
                onChange={(e) =>
                  setFormulario({
                    ...formulario,
                    estatusSeguro: e.target.value,
                  })
                }
              >
                <option value="activo">Activo</option>
                <option value="inactivo">Inactivo</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="button-cancel text-secondary" onClick={closeModal} style={{ height: "3rem" }}>
            Cancelar
          </Button>
          <Button className="button-save text-primary" onClick={isEditing ? editarSeguro : guardarNuevoSeguro} style={{ height: "3rem" }}>
            {isEditing ? "Guardar Edición" : "Guardar"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
  

};
export default SegurosList;
