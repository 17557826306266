import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { showErrorAlert, showSuccessAlert } from "../../../components/alerts";
import medicGeekApi from "../../../config/axiosConfig";

const LabTestManagement = () => {
  const [tests, setTests] = useState([]);
  const [showForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const testsPerPage = 10;
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTest, setCurrentTest] = useState(null);
  const [formData, setFormData] = useState({ name: "", status: false });
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // Estado para la configuración de ordenación

  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await medicGeekApi.get("/labtestparam", {
          params: {
            search: searchTerm,
          },
        });

        // Ordenar los datos según la configuración de ordenación
        let sortedTests = [...response.data];
        if (sortConfig.key) {
          sortedTests.sort((a, b) => {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];
            if (aValue < bValue) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
          });
        }

        setTests(sortedTests);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [searchTerm, sortConfig]);


  const handleShowModal = (test = null) => {
    if (test) {
      setIsEditing(true);
      setCurrentTest(test);
      setFormData({ name: test.AnalisisNm || "", status: test.estatus === "Activo" });
    } else {
      setIsEditing(false);
      setCurrentTest(null);
      setFormData({ name: "", status: false });
    }
    setShowModal(true);
  };

  const handleupdate = () => {
    medicGeekApi
      .get("/labtestparam")
      .then((response) => {
        setTests(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearchTermChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const pageCount = Math.ceil(tests.length / testsPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSave = () => {
    if (!formData.name.trim()) {
      // Si el input está vacío, muestra una alerta de error
      showErrorAlert("El nombre del análisis no puede estar vacío");
      return;
    }
  
    const nuevoClassification = {
      AnalisisNm: formData.name,
      estatus: "Activo",
    };
  
    medicGeekApi
      .post("/labtestparam", nuevoClassification)
      .then((response) => {
        showSuccessAlert();
        setShowModal(false);
        // Recargar la lista de análisis
        handleupdate();
        // Limpiar el input después de añadir
        setFormData({ name: "", status: true });
      })
      .catch((error) => {
        console.error("Error al crear el análisis", error);
        showErrorAlert();
      });
  };

  const handleEdit = (id) => {
    const updatedClassification = {
      AnalisisNm: formData.name,
      estatus: formData.status ? "Activo" : "Inactivo",
    };

    medicGeekApi
      .patch(`/labtestparam/${id}`, updatedClassification)
      .then((response) => {
        showSuccessAlert();
        setShowModal(false);
        // Recargar la lista de análisis
        handleupdate();
      })
      .catch((error) => {
        console.error("Error al editar el análisis", error);
        showErrorAlert();
      });
  };

  const handleDelete = (id) => {
    medicGeekApi
      .delete(`/labtestparam/${id}`)
      .then(() => {
        setTests(tests.filter((test) => test.labTestParamId !== id));
      })
      .catch((error) => console.error("Error deleting data:", error));
  };

  const handleStatusToggle = (id, currentStatus) => {
    const updatedStatus = currentStatus === "Activo" ? "Inactivo" : "Activo";

    medicGeekApi
      .patch(`/labtestparam/${id}`, { estatus: updatedStatus })
      .then(() => {
        setTests(
          tests.map((test) =>
            test.labTestParamId === id
              ? { ...test, estatus: updatedStatus }
              : test
          )
        );
      })
      .catch((error) => {
        console.error("Error al cambiar el estatus:", error);
      });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      const direction = (prevConfig.key === key && prevConfig.direction === 'asc') ? 'desc' : 'asc';
      return { key, direction };
    });
  };

  const filteredTests = tests.filter((test) =>
    (test.AnalisisNm || "").toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedTests = filteredTests.slice(
    pageNumber * testsPerPage,
    (pageNumber + 1) * testsPerPage
  );

  return (
    <div>
        <div className="d-flex flex-column flex-md-row justify-content-between miDivConGradiente px-3 rounded-bottom">
            <div className="d-flex align-items-center text-white mb-2 mb-md-0">
                <h2>Mantenimiento Prueba de Laboratorio</h2>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-md-0">
                <form className="d-flex" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Buscar"
                  aria-label="Buscar"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
                </form>
            </div>
        </div>

        <div className="mb-2 mt-4" style={{ height: "45vh", padding: "0rem 1rem" }}>
            <div className="d-flex justify-content-right align-items-center w-100 px-3 py-3  mt-3 rounded-2" style={{
              height: "4rem",
              background: "linear-gradient(87deg,#11cdef,#1171ef)"
            }}>
                <div className="input-group rounded-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre del Análisis"
                    id="nombreAnalisis"
                    value={formData.name}
                    onChange={(e) => {
                      setFormData({ ...formData, name: e.target.value });
                      setErrorMessage(null); // Limpiar el mensaje de error al escribir
                    }}
                  />

                    <Button
                        className="btn btn-danger ms-2"
                        title="Crear Nueva prueba"
                        onClick={() => handleSave()}
                    >
                    <i className="bi bi-file-plus"></i>
                        Añadir Análisis
                    </Button>
                </div>
            </div>

            <table className="table table-striped table-hover mt-5">
              <thead>
                <tr>
                  <th onClick={() => handleSort("labTestParamId")}>
                    ID
                    {sortConfig.key === "labTestParamId" ? (
                      sortConfig.direction === "asc" ? " 🔼" : " 🔽"
                    ) : null}
                  </th>
                  <th onClick={() => handleSort("AnalisisNm")}>
                    Nombre del Análisis
                    {sortConfig.key === "AnalisisNm" ? (
                      sortConfig.direction === "asc" ? " 🔼" : " 🔽"
                    ) : null}
                  </th>
                  <th onClick={() => handleSort("estatus")}>
                    Estado
                    {sortConfig.key === "estatus" ? (
                      sortConfig.direction === "asc" ? " 🔼" : " 🔽"
                    ) : null}
                  </th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {paginatedTests.map((test) => (
                  <tr key={test.labTestParamId}>
                    <td>{test.labTestParamId}</td>
                    <td>{test.AnalisisNm || "N/A"}</td>
                    <td style={{ width: "5%" }}>
                      <FormGroup switch>
                        <Input
                          id={`switch-${test.labTestParamId}`}
                          type="checkbox"
                          checked={test.estatus === "Activo"}
                          onChange={() => handleStatusToggle(test.labTestParamId, test.estatus)}
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <Button
                        className="btn btn-warning btn-sm me-2"
                        onClick={() => handleShowModal(test)}
                      >
                        Editar
                      </Button>
                      <Button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(test.labTestParamId)}
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                )
              )}
              </tbody>
            </table>

            {/* Paginación */}
          {!showForm && (
            <div className="d-flex justify-content-end">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  
                  {/* Botón de página anterior */}
                  <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                    <span
                      className="page-link"
                      aria-label="Previous"
                      onClick={() => handlePageChange({ selected: pageNumber - 1 })}
                      style={{ cursor: pageNumber === 0 ? "not-allowed" : "pointer" }}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </span>
                  </li>

                  {Array.from(
                    { length: Math.min(pageCount, 10) },
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          pageNumber === index ? " active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="page-link"
                          onClick={() => handlePageChange({ selected: index })}
                        >
                          {index + 1}
                        </span>
                      </li>
                    )
                  )}

                  {/* Botón de siguiente página */}
                  <li className={`page-item ${pageNumber === pageCount - 1 ? "disabled" : ""}`}>
                    <span
                      className="page-link"
                      aria-label="Next"
                      onClick={() => handlePageChange({ selected: pageNumber + 1 })}
                      style={{ cursor: pageNumber === pageCount - 1 ? "not-allowed" : "pointer" }}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </span>
                  </li>
                  
                </ul>
              </nav>
            </div>
          )}
        </div>

      {/* Modal para Editar o Añadir */}
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {isEditing ? "Editar Análisis" : "Añadir Análisis"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="labTestParamId">ID</Label>
              <Input
                id="labTestParamId"
                type="text"
                value={currentTest ? currentTest.labTestParamId : ""}
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Label for="nombreAnalisis">Nombre del Análisis</Label>
              <Input
                id="nombreAnalisis"
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </FormGroup>
            <FormGroup switch>
              <Label for="estatusAnalisis" check>Estado</Label>
              <Input
                id="estatusAnalisis"
                type="checkbox"
                checked={formData.status}
                onChange={(e) =>
                  setFormData({ ...formData, status: e.target.checked })
                }
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={isEditing ? () => handleEdit(currentTest.labTestParamId) : handleSave}
          >
            {isEditing ? "Guardar Cambios" : "Añadir Análisis"}
          </Button>{" "}
          <Button color="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LabTestManagement;
